/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { ComboBox } from '@fluentui/react/lib/ComboBox';
import expandImage from '../../../assets/images/ic_expand.png';
import collapseImage from '../../../assets/images/ic_collapse.png';
import * as scorecardReduxActions from '../../../redux/actions/scorecardActions';
import * as cadenceActions from '../../../redux/actions/myActionsActions';
import './CorrectiveActionPlan.css';
import CorrectiveActionPlanHistory from '../../myactions/correctiveActionPlanHistory/CorrectiveActionPlanHistory';

class CorrectiveActionPlan extends Component {
  state = {
    isExpand: false,
    isCloseCapCheck: false,
    showCorrectiveActionPlan: false,
    isPastCAPToggleOn: false,
    currentCadence: null,
    bobCAP: false
  };

  constructor() {
    super();
    this.onExpandCorrectionPlan = this.onExpandCorrectionPlan.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  static getDerivedStateFromProps(props) {
    const { bobScorecardDetails, supplierScorecardDetails } = props;
    return {
      currentCadence: bobScorecardDetails || supplierScorecardDetails,
      bobCAP: !!bobScorecardDetails
    };
  }

  async componentDidMount() {
    const { loadFutureCadence, metricsScorecardDetails } = this.props;
    const { currentCadence, bobCAP, isCloseCapCheck } = this.state;
    bobCAP ?
      await loadFutureCadence(
        0,
        0,
        currentCadence.bob.id,
        currentCadence.cadence.id
      )
      : await loadFutureCadence(
        currentCadence.supplier.id,
        0,
        0,
        currentCadence.cadence.id
      );

    this.setState({
      isExpand: !!(metricsScorecardDetails && metricsScorecardDetails.cap),
      isCloseCapCheck:
        metricsScorecardDetails && metricsScorecardDetails.cap
          ? metricsScorecardDetails.isCompleted
          : isCloseCapCheck
    });
  }

  onChangeDueDate = (event, item) => {
    const
      {
        updateCapDetails, metricsScorecardDetails
      } = this.props;
    const { currentCadence } = this.state;
    if (item !== undefined) {
      updateCapDetails('dueDate', item.text);
      // this.setState({ selectedDuePeriod: item.text });
    }
    if (event !== undefined) {
      updateCapDetails('dueDate', item.text);
      if (!metricsScorecardDetails.cap) {
        updateCapDetails('openDate', currentCadence.cadence.name);
      }
    }
  };

  onChangeCloseDate = event => {
    const
      {
        updateCapDetails, metricsScorecardDetails
      } = this.props;
    const { currentCadence } = this.state;
    if (event !== undefined) {
      updateCapDetails('completedDate', currentCadence.cadence.name);
      if (!metricsScorecardDetails.cap) {
        updateCapDetails('openDate', currentCadence.cadence.name);
      }
    }
  };

  onChangeCapDescription = item => {
    const
      {
        updateCapDetails, metricsScorecardDetails
      } = this.props;
    const { currentCadence } = this.state;
    const keyCode = item.which;
    if (item.target.textContent.length > 0) {
      if (!metricsScorecardDetails.cap) {
        updateCapDetails('openDate', currentCadence.cadence.name);
      }
      updateCapDetails('description', item.target.textContent);
      if (keyCode !== 9) {
        document.getElementById('cap-text').focus();
      }
    }
  };

  onExpandCorrectionPlan = () => {
    const { isExpand } = this.state;
    this.setState({
      isExpand: !isExpand
    });
  };

  onClickCAPHistory = () => {
    const { showCorrectiveActionPlan } = this.state;
    this.setState({
      showCorrectiveActionPlan: !showCorrectiveActionPlan,
      isPastCAPToggleOn: true
    });
  }

  onChangeCloseCap = (item, checked) => {
    const { updateCapDetails } = this.props;
    const { currentCadence } = this.state;
    if (item !== undefined) {
      this.setState({
        isCloseCapCheck: checked
      });
    }

    if (checked) {
      updateCapDetails('completedDate', currentCadence.cadence.name);
      updateCapDetails('isCompleted', true);
    } else {
      updateCapDetails('completedDate', null);
      updateCapDetails('isCompleted', false);
    }
  };

  handleKeyPress = event => {
    event.preventDefault();
    const { isExpand } = this.state;
    this.setState({
      isExpand: !isExpand
    });
  };

  render() {
    const {
      isExpand, showCorrectiveActionPlan, isPastCAPToggleOn,
      isCloseCapCheck, currentCadence
    } = this.state;

    const comboBoxStyle = {
      container: {
        display: 'flex'
      },
      label: {
        paddingRight: '10px'
      }
    };

    const {
      metricsScorecardDetails,
      futureCadences
    } = this.props;

    return (
      <div className="row-cap ">
        <span id="corrPlan" className="capWidth">
          Corrective Action Plan{' '}
        </span>
        <input
          type="image"
          id="expandImg"
          src={isExpand ? collapseImage : expandImage}
          onClick={this.onExpandCorrectionPlan.bind(this)}
          onKeyPress={this.handleKeyPress.bind(this)}
          alt="Add Corrective ActionPlan"
          title={`Corrective ActionPlan ${ isExpand ? 'collapse' : 'expand'} button`}
          tabIndex="0"
        />
        <Toggle
          id="pastPlan"
          className="pastcomments"
          defaultChecked={false}
          inlineLabel
          ariaLabel="Past CAPs"
          label="Past CAPs"
          onChange={this.onClickCAPHistory.bind(this)}
        />
        {showCorrectiveActionPlan ? (
          <>
            <CorrectiveActionPlanHistory
              isPastCAPToggleOn={isPastCAPToggleOn}
            />
          </>
        ) : null}
        {isExpand ? (
          <>
            <TextField
              className="textField mt-3"
              id="cap-text"
              onChange={this.onChangeCapDescription.bind(this)}
              value={
                metricsScorecardDetails.cap
                  ? metricsScorecardDetails.cap.description
                  : ''
              }
              ariaLabel="cap description"
              validateOnLoad={false}
              multiline
              rows={7}
              style={{ lineHeight: '22px' }}
              disabled={metricsScorecardDetails.isReadonly || isCloseCapCheck}
            />
            <div className="cap-actions">
              <div className="cap-date-action">
                <ComboBox
                  placeholder="Select a Period..."
                  ariaLabel="Due Period"
                  label="Due Period"
                  iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                  useComboBoxAsMenuWidth
                  selectedKey={futureCadences[0].cadenceId}
                  disabled={metricsScorecardDetails.isReadonly || isCloseCapCheck}
                  allowFreeInput
                  autoComplete="on"
                  options={futureCadences
                    ? futureCadences.map(data => ({
                      key: data.cadenceId,
                      text: data.cadenceName
                    }))
                    : ''
                  }
                  styles={comboBoxStyle}
                  id="dueperiodid"
                  onSelectDate={this.onChangeDueDate}
                  onChange={this.onChangeDueDate}
                />
              </div>
              <div className="close-cap-fields">
                <div className="close-cap-actions">

                  <Checkbox
                    onChange={this.onChangeCloseCap.bind(this)}
                    disabled={metricsScorecardDetails.isReadonly}
                    checked={isCloseCapCheck}
                    value={isCloseCapCheck}
                    title="close Cap"
                    ariaLabel="closeCap"
                  />
                  <span id="closeCap" className="textLabel">
                    Close CAP
                  </span>
                </div>
                <div className="close-cap-action">

                  <label className=" text12 cap-date-label">
                    <span className="cap-label">Close Period</span>
                    <TextField
                      className="marCaps dateWidth"
                      ariaLabel="Close Period"
                      value={!isCloseCapCheck
                        ?  ''
                        : metricsScorecardDetails.cap
                          && metricsScorecardDetails.cap.completedDate
                          ? metricsScorecardDetails.cap.completedDate
                          : currentCadence.cadence.name}
                      disabled={!isCloseCapCheck
                        || metricsScorecardDetails.isReadonly}
                      onSelectDate={this.onChangeCloseDate}
                      onChange={this.onChangeCloseDate}
                    />
                  </label>
                </div>
              </div>
            </div>
          </>
        ) : (
            <>
              <div className="hline" />
            </>
          )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  metricsScorecardDetails:
    state?.myActionsScorecard?.bobScorecardCategoryDetails ||
    state?.myActionsScorecard?.supplierScorecardCategoryDetails,
  futureCadences:
    state?.myActions?.futureCadence
});

const mapDispatchToProps = {
  updateCapDetails: scorecardReduxActions?.updateCapDetails,
  loadFutureCadence: cadenceActions?.loadFutureCadence
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorrectiveActionPlan);

CorrectiveActionPlan.defaultProps = {
  metricsScorecardDetails: null,
  updateCapDetails: PropTypes.func,
  loadFutureCadence: PropTypes.func,
  bobScorecardDetails: PropTypes.object,
  supplierScorecardDetails: PropTypes.object,
  futureCadences: PropTypes.object
};
CorrectiveActionPlan.propTypes = {
  metricsScorecardDetails: PropTypes.object,
  updateCapDetails: PropTypes.func,
  loadFutureCadence: PropTypes.func,
  bobScorecardDetails: PropTypes.object,
  supplierScorecardDetails: PropTypes.object,
  futureCadences: PropTypes.object
};
