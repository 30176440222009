/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { connect } from 'react-redux';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import PropTypes from 'prop-types';
import './categoryComment.css';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { CategoryCommentMessage } from '../../../../common/consts/validationMessages';
import CommentHistory from '../../commentHistory/CommentHistory';
import * as ScorecardReduxActions from '../../../../redux/actions/scorecardActions';
import LineGraph from '../../../common/LineGraphControl/LineGraphControl';
import ErrorDiv from '../../../hoc/errordiv';

export class CategoryComments extends React.Component {
  state = {
    showCommentHistory: false,
    isCommentImportant: true,
    isPastCommentToggleOn: false,
    isCategoryHistoryToggleOn: false,
    isCategoryHistoryLoadSuccessfull: true,
    isCommentsAdded: false,
  };

  componentDidMount() {
    const { categoryComments } = this.props;
    if (categoryComments !== null && categoryComments !== '') {
      this.setState({
        isCommentImportant: false
      });
    }
  }

  async onHistoryToggle(ev, checked) {
    this.setState({
      isCategoryHistoryToggleOn: checked,
      isCategoryHistoryLoadSuccessfull: true
    });
    const {
      categoryId,
      isBobScorecardCategory,
      bobScorecardCategoryHistory,
      supplierScorecardCategoryHistory,
      loadSupplierScorecardCategoryHistory,
      loadBobScorecardCategoryHistory
    } = this.props;
    if (checked) {
      if (isBobScorecardCategory) {
        const history = bobScorecardCategoryHistory.find(
          h => h.categoryId === categoryId
        );
        if (!history) {
          try {
            await loadBobScorecardCategoryHistory(categoryId);
            document.getElementById('history-toggle').focus();
          } catch (err) {
            this.setState({ isCategoryHistoryLoadSuccessfull: false });
          }
        }
      } else {
        const history = supplierScorecardCategoryHistory.find(
          h => h.categoryId === categoryId
        );
        if (!history) {
          try {
            await loadSupplierScorecardCategoryHistory(categoryId);
            document.getElementById('history-toggle').focus();
          } catch (err) {
            this.setState({ isCategoryHistoryLoadSuccessfull: false });
          }
        }
      }
    }
  }

  onClickCommentHistory = () => {
    const { showCommentHistory } = this.state;
    this.setState({
      showCommentHistory: !showCommentHistory,
      isPastCommentToggleOn: true
    });
  };

  // eslint-disable-next-line class-methods-use-this
  getHistoryPlotPoints(categoryHistory) {
    const plotPoints = categoryHistory && categoryHistory.map(history => (history === null ? '' : ({
      x: history.cadence.name,
      y: history.score
    })));

    return plotPoints;
  }

  async updateScorecardCategoryComments(item) {
    const { updateCategoryCommentsDispatch } = this.props;
    const keyCode = item.which;
    if (item.target.textContent.length > 0) {
      this.setState({
        isCommentImportant: false,
        isCommentsAdded: true
      });
    } else {
      this.setState({
        isCommentImportant: true
      });
    }
    await updateCategoryCommentsDispatch(item.target.textContent);
    if (keyCode !== 9) {
      document.getElementById('comments-text').focus();
    }
  }

  renderHistoryPanel() {
    const {
      isCategoryHistoryToggleOn,
      isCategoryHistoryLoadSuccessfull
    } = this.state;
    const {
      categoryId,
      categoryName,
      isBobScorecardCategory,
      bobScorecardCategoryHistory,
      supplierScorecardCategoryHistory
    } = this.props;

    if (isCategoryHistoryToggleOn) {
      if (!isCategoryHistoryLoadSuccessfull) {
        return (
          <ErrorDiv
            error="Error Loading History. Please toggle the history again to reload."
            hideRetry
          />
        );
      }
      let history;
      if (isBobScorecardCategory) {
        history = bobScorecardCategoryHistory.find(
          h => h.categoryId === categoryId
        );
      } else {
        history = supplierScorecardCategoryHistory.find(
          h => h.categoryId === categoryId
        );
      }
      if (history) {
        return (
          <LineGraph
            plotPoints={this.getHistoryPlotPoints(history.categoryHistory)}
            header={categoryName}
            label="Score"
          />
        );
      }
      return <Spinner size={SpinnerSize.large} label="Loading History..." />;
    }
    return <></>;
  }

  render() {
    const {
      categoryComments,
      isEditable,
      categoryName,
      scorecardDetails,
      showHistoryDetails,
      loggedInUser
        } = this.props;
    const {
      showCommentHistory,
      isCommentImportant,
      isPastCommentToggleOn,
      isCommentsAdded
    } = this.state;
    const summaryCount = scorecardDetails.bobCategorySummary ? 1 : 0;
    const metricCount = scorecardDetails.metrics
      ? scorecardDetails.metrics.length
      : 0;
   let comments = `[${loggedInUser.name } ${new Date().toLocaleDateString() }]\n`;
   if (categoryComments !== null) {
     comments = isCommentsAdded
     ? categoryComments
     : `${categoryComments} \n${comments}`;
   }
   return (
      <div className="category-comments">
        <div id="CategoryComments" className="commentcontainer clearfix">
          <label htmlFor="comments-text">Comments (Required)
          {isCommentImportant && <span className="important">!</span>}
          </label>
          <div className="toggleDiv">
            {showHistoryDetails ?
            <Toggle
              className="pastcomments"
              defaultChecked={false}
              inlineLabel
              AriaLabel="Past Comments"
              label="Past Comments"
              onChange={this.onClickCommentHistory.bind(this)}
            />
            : null}

            {summaryCount + metricCount > 1 && showHistoryDetails && (
              <Toggle
                className="historyToggle"
                defaultChecked={false}
                inlineLabel
                AriaLabel={`${categoryName} History `}
                label={`${categoryName} History`}
                onChange={this.onHistoryToggle.bind(this)}
                id="history-toggle"
              />
            )}
          </div>
        </div>
        {this.renderHistoryPanel()}
        {showCommentHistory ? (
          <>
            <CommentHistory isPastCommentToggleOn={isPastCommentToggleOn} />
          </>
        ) : null}
        <TextField
          className="category-commentbox"
          aria-label="Please enter comments"
          value={comments}
          autoAdjustHeight
          multiline
          rows={8}
          resizable
          onChange={this.updateScorecardCategoryComments.bind(this)}
          disabled={!isEditable}
          id="comments-text"
        />
        <span className="remark">{CategoryCommentMessage.commentRemark}</span>
      </div>
    );
  }
}
CategoryComments.propTypes = {
  updateCategoryCommentsDispatch: PropTypes.func.isRequired,
  categoryComments: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  loadSupplierScorecardCategoryHistory: PropTypes.func.isRequired,
  loadBobScorecardCategoryHistory: PropTypes.func.isRequired,
  bobScorecardCategoryHistory: PropTypes.array.isRequired,
  supplierScorecardCategoryHistory: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
  categoryName: PropTypes.string.isRequired,
  isBobScorecardCategory: PropTypes.bool.isRequired,
  scorecardDetails: PropTypes.object.isRequired,
  showHistoryDetails: PropTypes.bool.isRequired,
  loggedInUser: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  updateCategoryCommentsDispatch:
    ScorecardReduxActions.updateScorecardCategoryComments,
  loadSupplierScorecardCategoryHistory:
    ScorecardReduxActions.loadSupplierScorecardCategoryHistory,
  loadBobScorecardCategoryHistory:
    ScorecardReduxActions.loadBobScorecardCategoryHistory
};

const mapStateToProps = state => ({
  bobScorecardCategoryHistory: state.myActionsScorecard.bobScorecardCategoryHistory === null ||
                               state.myActionsScorecard.bobScorecardCategoryHistory.length === 0
                               ? [] :
                               state.myActionsScorecard.bobScorecardCategoryHistory,
  supplierScorecardCategoryHistory:
                      state.myActionsScorecard.supplierScorecardCategoryHistory === null ||
                      state.myActionsScorecard.supplierScorecardCategoryHistory.length === 0
                      ? [] : state.myActionsScorecard.supplierScorecardCategoryHistory,
  loggedInUser: state.loggedInUser
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryComments);
