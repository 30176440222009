import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isNullOrUndefined } from 'util';
import './subMenus.css';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import * as reportsAction from '../../redux/actions/reportActions';
import * as resourceAction from '../../redux/actions/resourceActions';
import * as signinActions from '../../redux/actions/userLoginActions';

export class SubMenu extends Component {
  state = {
    displayMenu: false
  };

  constructor() {
    super();
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleLinkKeyPress = this.handleLinkKeyPress.bind(this);
    this.signOutUser = this.signOutUser.bind(this);
  }

  componentDidMount() {
    this.setState({ displayMenu: false });
    const {
 getReports, getResource, reports, resource
} = this.props;

    if (isNullOrUndefined(reports)) {
      getReports();
    }

    if (isNullOrUndefined(resource)) {
      getResource();
    }
  }

  handleKeyPress = event => {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  };

  handleLinkKeyPress = (event, item) => {
    event.preventDefault();
    if (item !== undefined) {
      window.open(item.link, '_blank');
    }
  };

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }

  signOutUser() {
    const { signOutUser } = this.props;
    signOutUser();
  }

  render() {
    const {
      tabName,
      isReports,
      reports,
      resource,
      isUserProfile,
      alias,
      userName
    } = this.props;
    const { displayMenu } = this.state;

    let data = [];
    data = isReports === true ? reports : resource;

    const memuOptions = [];
    if (data) {
      data.map(option => (option.isExternal ?
        memuOptions.push({
          key: `container_${option.id}`,
          text: option.title,
          id: option.id,
          className: 'rec',
          tabIndex: '-1',
          onKeyPress: this.handleLinkKeyPress,
          onClick: () => window.open(option.link, '_blank')
        })
         :
         memuOptions.push({
          key: option.id,
          text: option.title,
          id: option.id,
          className: 'rec',
          activeClassName: 'nav-active active',
          tabIndex: '-1',
          to: option.link
        })
      ));
    }

    return (
      <div className="dropdown">
        {isUserProfile ? (
          <>
            <button
              type="button"
              className="rounded-circle profile-image"
              onKeyPress={this.handleKeyPress}
              onClick={this.showDropdownMenu}
            >
              {tabName}
            </button>
            {displayMenu === true ? (
              <div className="ull popup-width">
                <div className="user-detail">
                  <div className="row">
                    <div className="col-lg-12 high-constrast">
                      <NavLink
                        className="float-right fs13 signout"
                        onClick={this.signOutUser}
                        to="/"
                      >
                        Sign out
                      </NavLink>
                    </div>
                  </div>
                  <div className="row mt10">
                    <div className="col-lg-4 high-constrast">
                      <div className="rounded-circle profile-image initials">
                        <span className="">{tabName}</span>
                      </div>
                    </div>
                    <div className="col-lg-8 pl-0 pt10 high-constrast">
                      <span className="fs18 font-weight-600">
                        {userName.substring(0, userName.indexOf('('))}
                      </span>
                      <br />
                      <span className="fs13 text-muted">{alias}</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <>
          <CommandBarButton
            className="nav-link textsize14 navItemsReport"
            text={tabName}
            menuProps={{
              shouldFocusOnMount: true,
              className: isReports === true ? 'menuReports' : 'menuResource',
              items: memuOptions
            }}
          />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reports: state.reports,
    resource: state.resource
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getReports: bindActionCreators(reportsAction.getReports, dispatch),
    getResource: bindActionCreators(resourceAction.getResource, dispatch),
    signOutUser: signinActions.signOutUser
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);

SubMenu.defaultProps = {
  reports: null,
  resource: null,
  isUserProfile: false,
  alias: null,
  userName: null
};

SubMenu.propTypes = {
  tabName: PropTypes.string.isRequired,
  isReports: PropTypes.bool.isRequired,
  getReports: PropTypes.func.isRequired,
  reports: PropTypes.array,
  getResource: PropTypes.func.isRequired,
  resource: PropTypes.array,
  isUserProfile: PropTypes.bool,
  alias: PropTypes.string,
  userName: PropTypes.string,
  signOutUser: PropTypes.func.isRequired
};
