/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import '../SupplierOnboardingPage.css';
import {
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
} from '@fluentui/react/lib/DetailsList';
import { Checkbox } from '@fluentui/react';
import { SelectionMode } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
// import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { IconButton } from '@fluentui/react/lib/Button';
import BOBGrid from '../onboardedBOBGrid/OnboardedBOBGrid';
import addOnboardSupplier from '../../../assets/images/ic_add.png';
import ManageOnboardedSupplierPopup from '../manageOnboardedSupplier/ManageOnboardedSupplier';
import DeleteDialogPopUp from '../../common/DeleteDialog';
import * as supplierOnboardModel from '../../models/model/SupplierOnboardingModel';
import * as BOBActions from '../../../redux/actions/onboardedBOBActions';
import withErrorHandling from '../../hoc/withErrorHandling';
import * as suppliersActions from '../../../redux/actions/onboardedSuppliersActions';

class OnboardedSuppliersGrid extends Component {
  static buildColumns() {
    return [
      {
        type: 'button',
        key: '1',
        name: 'View Bob List',
        isIconOnly: true,
        ariaLabel: 'Column header for expanding bob list',
        fieldName: '',
        tabIndex: '0',
        iconClassName: 'expandicon',
        minWidth: 9,
        maxWidth: 9,
        isSortedDescending: false,
        isResizable: false,
      },
      {
        key: '2',
        name: 'SUPPLIER NAME',
        fieldName: 'supplierName',
        iconClassName: 'sortAsc',
        tabIndex: '0',
        minWidth: 50,
        maxWidth: 300,
        ariaLabel: '',
        isResizable: false,
        isSortedDescending: false,
        isMultiline: true,
      },
      {
        key: '3',
        name: 'TRAILING 12-MONTH PO AMOUNT (USD)',
        fieldName: 'uvpAnnualSpend',
        iconClassName: 'sortAsc',
        tabIndex: '0',
        ariaLabel: '',
        minWidth: 50,
        maxWidth: 170,
        isResizable: false,
        isSortedDescending: false,
      },
      {
        key: '4',
        name: 'LEADS',
        fieldName: 'leads',
        iconClassName: 'sortAsc',
        tabIndex: '0',
        ariaLabel: '',
        minWidth: 50,
        maxWidth: 250,
        isResizable: false,
        isSortedDescending: false,
      },
      {
        key: '5',
        name: 'INDUSTRY',
        fieldName: 'domainsByIds',
        iconClassName: 'sortAsc',
        ariaLabel: '',
        tabIndex: '0',
        isMultiline: true,
        minWidth: 50,
        maxWidth: 250,
        isResizable: false,
        isSortedDescending: false,
      },
      {
        key: '6',
        name: 'DEFAULT CADENCE',
        fieldName: 'cadence',
        iconClassName: 'sortAsc',
        tabIndex: '0',
        ariaLabel: '',
        minWidth: 50,
        maxWidth: 150,
        isResizable: false,
        isSortedDescending: false,
      },
      {
        key: '7',
        name: 'SUPPLIER TYPE',
        fieldName: 'supplierType',
        iconClassName: 'sortAsc',
        ariaLabel: '',
        tabIndex: '0',
        minWidth: 50,
        maxWidth: 120,
        isResizable: false,
        isSortedDescending: false,
      },
      {
        key: '8',
        name: 'PROCUREMENT TYPE',
        iconClassName: 'sortAsc',
        fieldName: 'procurementType',
        tabIndex: '0',
        minWidth: 50,
        ariaLabel: '',
        maxWidth: 120,
        isResizable: false,
        isSortedDescending: false
      },
      {
        key: '9',
        name: 'UNMANAGED PERFORMANCE SPEND (USD)',
        iconClassName: 'sortAsc',
        fieldName: 'unmanagedPerformanceSpend',
        tabIndex: '0',
        ariaLabel: '',
        minWidth: 20,
        maxWidth: 160,
        isResizable: false,
        isSortedDescending: false,
      },
      {
        type: 'button',
        key: '10',
        name: 'Add Supplier',
        ariaLabel: 'Add Supplier Button, click to add new supplier',
        isIconOnly: true,
        fieldName: 'Add supplier Button',
        iconClassName: 'addImg ml50',
        tabIndex: '0',
        minWidth: 20,
        maxWidth: 100,
        onColumnClick: this.onColumnClick,
        isSortedDescending: false,
        isResizable: false,
        src: addOnboardSupplier,
      },
    ];
  }

  constructor(props) {
    super(props);
    this.onRenderRow = this.onRenderRow.bind(this);
    this.onRenderItemColumn = this.onRenderItemColumn.bind(this);
    this.onColumnClick = this.onColumnClick.bind(this);
    this.onEditOnboardedSupplier = this.onEditOnboardedSupplier.bind(this);
    this.onDeleteSupplier = this.onDeleteSupplier.bind(this);
    this.onExpandSupplierGrid = this.onExpandSupplierGrid.bind(this);
    this.onCollapseSupplierGrid = this.onCollapseSupplierGrid.bind(this);
    this.state = {
      isSupplierClick: false,
      selectedSupplierId: 0,
      columns: OnboardedSuppliersGrid.buildColumns(),
      isManageOnboardedPopupVisible: false,
      scorecardSupplierId: 0,
      onboardSupplier: new supplierOnboardModel.OnboardedSupplierModel(),
      supplierlead1: new supplierOnboardModel.LeadModel(),
      supplierlead2: new supplierOnboardModel.LeadModel(),
      hideDialog: true,
      deleteIndex: null,
      hasAccess: false,
      isLoading: false,
      filterChecked: false
    };
  }

  componentDidMount() {
    const { loggedInUser, resetMessageBar } = this.props;
    const { hasAccess, columns } = this.state;
    if (
      (loggedInUser.isAdmin || loggedInUser.isSupplierLead) &&
      !loggedInUser.isReadOnly
    ) {
      this.setState({
        hasAccess: true,
      });
    }
    if (!hasAccess) {
      this.setState({
        columns: columns.map((col) => {
          const currentCol = col;
          if (col.key === '10') {
            currentCol.iconClassName = 'addImg ml50 fadeOut';
          }
          return currentCol;
        })
      });
    }
    resetMessageBar();
  }

  onCollapseSupplierGrid() {
    this.setState({
      isSupplierClick: false,
      selectedSupplierId: 0,
      isLoading: false,
    });
  }

  async onExpandSupplierGrid(item) {
    const index = item.currentTarget.id.split(':')[1];
    const { onError, loadSupplierBOBList, onboardedSuppliers } = this.props;
    const selectedSupplier = onboardedSuppliers.filter(
      (supplierBOB, currIndex) => currIndex.toString() === index
    );

    this.setState({
      isSupplierClick: true,
      selectedSupplierId: selectedSupplier[0].supplierId,
      isLoading: true
    });

    try {
      await loadSupplierBOBList(
        selectedSupplier[0].scorecardSupplierId,
        selectedSupplier[0].procurementTypeId
      );
      this.setState({
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
      });
      onError("Unable to fetch onboarded BOB's.");
    }
  }

  onRenderItemColumn(item, index, column) {
    const { isSupplierClick, selectedSupplierId } = this.state;
    if (column.key === '1') {
      return isSupplierClick && selectedSupplierId === item.supplierId ? (
        <IconButton
          iconProps={{ iconName: 'Blocked2' }}
          title="Onboard Supplier button"
          tabIndex="0"
          aria-expanded="true"
          id={`expand:${index}`}
          onClick={this.onCollapseSupplierGrid.bind(item)}
        />
      ) : item.supplierType !== 'Monitor' ? (
        <IconButton
          iconProps={{ iconName: 'AddTo' }}
          title="Onboard Supplier button"
          tabIndex="0"
          aria-expanded="false"
          id={`expand:${index}`}
          onClick={this.onExpandSupplierGrid.bind(item)}
        />
      ) : null;
    }
    if (column.key === '2') {
      return <span title={item.supplierName}>{item.supplierName}</span>;
    }
    if (column.key === '3') {
      return (
        <span
          title={`$${item.uvpAnnualSpend.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })}`}
        >
          $
          {item.uvpAnnualSpend.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })}
        </span>
      );
    }
    if (column.key === '4') {
      return (
        <>
          {item.lead1DisplayName !== null && item.lead2DisplayName !== null ? (
            <span
              title={`${item.lead1DisplayName} (${item.lead1Alias}) \n ${item.lead2DisplayName} (${item.lead2Alias})`}
            >
              {item.lead1DisplayName} ({item.lead1Alias}) <br />
              {item.lead2DisplayName} ({item.lead2Alias})
            </span>
          ) : (
            <span title={`${item.lead1Alias} \n${item.lead2Alias}`}>
              {item.lead1Alias} <br /> {item.lead2Alias}
            </span>
          )}
        </>
      );
    }
    if (column.key === '5') {
      return (
        <span title={item.domainsByIds.replace(/@@/gi, ',')}>
          {item.domainsByIds.replace(/@@/gi, ', ')}
        </span>
      );
    }
    if (column.key === '6') {
      return <span title={item.cadence}>{item.cadence}</span>;
    }
    if (column.key === '7') {
      return <span title={item.supplierType}>{item.supplierType}</span>;
    }
    if (column.key === '8') {
      return <span title={item.procurementType}>{item.procurementType}</span>;
    }
    if (column.key === '9') {
      return (
        <span
          title={`$${item.unmanagedPerformanceSpend.toLocaleString(
            navigator.language,
            {
              minimumFractionDigits: 2,
            }
          )}`}
        >
          $
          {item.unmanagedPerformanceSpend.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })}
        </span>
      );
    }
    if (column.key === '10') {
      return (
        <>
          <IconButton
            iconProps={{ iconName: 'edit' }}
            title="Edit"
            tabIndex="0"
            id={`edit:${index}`}
            ariaLabel="edit row"
            onBlur={this.onBlurBOBGrid.bind(this)}
            onClick={this.onEditOnboardedSupplier.bind(this)}
          />
          {/* <span>
            <Icon
              iconName="Delete"
              title="Delete"
              role="presentation"
              id={index}
              alt="delete row"
              className={!hasAccess ? 'deleteIcon disabled' : 'deleteIcon'}
              onClick={this.showDialog.bind(this)}
            />
          </span> */}
        </>
      );
    }
    return item[column.fieldName];
  }

  onRenderRow(props) {
    const { isSupplierClick, selectedSupplierId, filterChecked } = this.state;
    const {
            loggedInUser, resetMessageBar, supplierBOBList
          } = this.props;
    return (
      <>
        <DetailsRow {...props} />
        {isSupplierClick && props.item.supplierId === selectedSupplierId ? (
          <div role="row">
            <div role="gridcell">
            <BOBGrid
              BOBList={filterChecked ? supplierBOBList : this.filterActiveBobs()}
              scorecardSupplierId={props.item.scorecardSupplierId}
              supplierProcurementType={props.item.procurementTypeId}
              cadence={props.item.cadence}
              uvpAnnualSpend={props.item.uvpAnnualSpend}
              loggedInUser={loggedInUser}
              onboardSupplier={props.item}
              resetMessageBar={resetMessageBar}
            />
            </div>
          </div>
        ) : null}
      </>
    );
  }

  onColumnClick(event, column) {
    const { isManageOnboardedPopupVisible, hasAccess } = this.state;
    if (column.key === '10' && hasAccess) {
      this.setState({
        onboardSupplier: new supplierOnboardModel.OnboardedSupplierModel(),
        isManageOnboardedPopupVisible: !isManageOnboardedPopupVisible,
        scorecardSupplierId: 0,
      });
      return;
    }
    if (column.key !== '1' && column.key !== '10') {
      const { columns } = this.state;
      const { onboardedSuppliers, updateOnboardedSuppliersAction } = this.props;
      const flag = !column.isSortedDescending;
      const newlySortedOnboardedSuppliers = [...onboardedSuppliers].sort(
        (firstRow, secondRow) => {
          let returnVal;
          if (flag) {
            if (column.fieldName === 'uvpAnnualSpend') {
              returnVal =
                firstRow[column.fieldName] - secondRow[column.fieldName];
            } else if (
              firstRow[column.fieldName] < secondRow[column.fieldName]
            ) {
              returnVal = 1;
            } else if (
              firstRow[column.fieldName] > secondRow[column.fieldName]
            ) {
              returnVal = -1;
            } else {
              returnVal = 0;
            }
          } else if (column.fieldName === 'uvpAnnualSpend') {
            returnVal =
              secondRow[column.fieldName] - firstRow[column.fieldName];
          } else if (firstRow[column.fieldName] > secondRow[column.fieldName]) {
            returnVal = 1;
          } else if (firstRow[column.fieldName] < secondRow[column.fieldName]) {
            returnVal = -1;
          } else {
            returnVal = 0;
          }
          return returnVal;
        }
      );

      updateOnboardedSuppliersAction([...newlySortedOnboardedSuppliers]);
      this.setState({
        columns:
          columns &&
          columns.map((col) => {
            const currentCol = col;
            if (col.key === column.key) {
              currentCol.isSortedDescending = flag;
              currentCol.iconClassName = flag ? 'sortAsc' : 'sortDesc';
              const arialabel = `button ${ column.name}`;
              currentCol.ariaLabel = flag ? `sorted descending ${ arialabel}` : `sorted ascending ${ arialabel}`;
            }
            return currentCol;
          }),
      });
    }
  }

  onCloseModal = () => {
    const { isManageOnboardedPopupVisible } = this.state;
    this.setState({
      onboardSupplier: new supplierOnboardModel.OnboardedSupplierModel(),
      isManageOnboardedPopupVisible: !isManageOnboardedPopupVisible,
      scorecardSupplierId: 0,
    });
  };

  onEditOnboardedSupplier = (data) => {
    this.setState({
      isLoading: true,
    });
    const { onboardedSuppliers } = this.props;
    const index = data.currentTarget.id.split(':')[1];
    const editonbordedsupplierdata = onboardedSuppliers[index];
    this.manageEditOnboardedSupplierdetails(editonbordedsupplierdata);
  };

  onDeleteSupplier = () => {
    const { deleteIndex } = this.state;
    const { deleteSupplier, onboardedSuppliers } = this.props;
    const onboardedSupplierId =
      onboardedSuppliers[deleteIndex].scorecardSupplierId;
    deleteSupplier(deleteIndex, onboardedSupplierId);
    this.setState({
      hideDialog: true,
      deleteIndex: null,
    });
  };

  manageEditOnboardedSupplierdetails = async (editonbordedsupplierdata) => {
    const { isManageOnboardedPopupVisible } = this.state;
    const lead1AliasName = editonbordedsupplierdata.lead1Alias;
    const lead2AliasName = editonbordedsupplierdata.lead2Alias;

    const lead1AliasId = `${editonbordedsupplierdata.lead1DisplayName} (${lead1AliasName})`;
    const lead2AliasId = `${editonbordedsupplierdata.lead2DisplayName} (${lead2AliasName})`;

    const domains =
      editonbordedsupplierdata.domainsByIds === ''
        ? []
        : editonbordedsupplierdata.domainsByIds.split('@@');
    const contractData = editonbordedsupplierdata.contracts;
    const uvpAnnualSpend = `$${editonbordedsupplierdata.uvpAnnualSpend}`;
    this.setState({
      onboardSupplier: new supplierOnboardModel.OnboardedSupplierModel(
        editonbordedsupplierdata.supplierId,
        editonbordedsupplierdata.supplierName,
        uvpAnnualSpend,
        lead1AliasId,
        editonbordedsupplierdata.lead1DisplayName
          ? editonbordedsupplierdata.lead1DisplayName
          : null,
        lead2AliasId,
        editonbordedsupplierdata.lead2DisplayName
          ? editonbordedsupplierdata.lead2DisplayName
          : null,
        {
          text: editonbordedsupplierdata.cadence,
          key: editonbordedsupplierdata.cadenceId,
        },
        {
          text: editonbordedsupplierdata.supplierType,
          key: editonbordedsupplierdata.supplierTypeId,
        },
        {
          text: editonbordedsupplierdata.procurementType,
          key: editonbordedsupplierdata.procurementTypeId
        },
        domains,
        contractData
      ),
      scorecardSupplierId: editonbordedsupplierdata.scorecardSupplierId,
      isManageOnboardedPopupVisible: !isManageOnboardedPopupVisible,
      isLoading: false,
      supplierlead1: new supplierOnboardModel.LeadModel(
        lead1AliasName,
        editonbordedsupplierdata.lead1Alias.split('@')[0]
      ),
      supplierlead2: new supplierOnboardModel.LeadModel(
        lead2AliasName,
        editonbordedsupplierdata.lead2Alias.split('@')[0]
      ),
      unmanagedPerformanceSpend:
        editonbordedsupplierdata.unmanagedPerformanceSpend,
    });
  };

  showDialog = (data) => {
    this.setState({
      hideDialog: false,
      deleteIndex: data.currentTarget.id,
    });
  };

  closeDialog = () => {
    this.setState({ hideDialog: true });
  };

  onBlurBOBGrid = () => {
    const constid = document.querySelector("[data-item-key='11']") && document.querySelector("[data-item-key='11']").children[0]
      .firstChild.id;
      if (constid) document.getElementById(constid).focus();
  };

  filterActiveBobs = () => {
    const { supplierBOBList = [] } = this.props;
    const filteredBobs = supplierBOBList?.filter(bob => bob.isActive === 1);
    return filteredBobs;
  }

  handleCheckBox = (e) => {
    this.setState({ filterChecked: e?.target?.checked });
  }

  render() {
    const {
      suppliers,
      domains,
      supplierTypes,
      procurementTypes,
      onboardedSuppliers
    } = this.props;
    const { cadences, leads, loggedInUser } = this.props;

    const {
      columns,
      isManageOnboardedPopupVisible,
      scorecardSupplierId,
      onboardSupplier,
      supplierlead1,
      supplierlead2,
      hideDialog,
      unmanagedPerformanceSpend,
      isLoading,
      filterChecked
    } = this.state;
    return (
      <div className="supplierParentContainer">
        <div className="supplierContainerHeader">
          <h2 className="supplierOnboardingContainer">
            <b>Supplier Onboarding</b>
          </h2>
          <Checkbox className="showBobsCheckBox" checked={filterChecked} label="Show All bobs" onChange={this.handleCheckBox} />
        </div>
        {isLoading ? <Spinner className="spinner" aria-busy="true" /> : null}
        <br />
        <DetailsList
          items={[...onboardedSuppliers]}
          columns={columns}
          setKey="set"
          compact="false"
          layoutMode={DetailsListLayoutMode.fixedColumns}
          isHeaderVisible="true"
          onRenderRow={this.onRenderRow}
          selectionMode={SelectionMode.none}
          className="SupplierDefaultListCss"
          onRenderItemColumn={this.onRenderItemColumn}
          onColumnHeaderClick={this.onColumnClick}
          loggedInUser={loggedInUser}
          onShouldVirtualize={() => false}
        />
        {isManageOnboardedPopupVisible ? (
          <>
            <ManageOnboardedSupplierPopup
              suppliers={suppliers}
              domains={domains}
              cadences={cadences}
              supplierTypes={supplierTypes}
              procurementTypes={procurementTypes}
              leads={leads}
              onCloseModal={this.onCloseModal}
              onboardSupplier={onboardSupplier}
              scorecardSupplierId={scorecardSupplierId}
              loggedInUser={loggedInUser}
              supplierlead1={supplierlead1}
              supplierlead2={supplierlead2}
              unmanagedPerformanceSpend={unmanagedPerformanceSpend}
            />
          </>
        ) : null}
        {!hideDialog ? (
          <>
            <DeleteDialogPopUp
              hideDialog={hideDialog}
              onCancel={this.closeDialog}
              dialogButtonHandler={this.onDeleteSupplier}
            />
          </>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    supplierBOBList:
      state.boblist === null ||
      state.boblist === undefined ||
      state.boblist.length === 0
        ? []
        : state.boblist,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSupplierBOBList: bindActionCreators(
      BOBActions.getSupplierBOBs,
      dispatch
    ),
    deleteSupplier: bindActionCreators(
      suppliersActions.deleteOnboardedSupplier,
      dispatch
    ),
    updateOnboardedSuppliersAction: bindActionCreators(
      suppliersActions.sortedOnboardSuppliers,
      dispatch
    ),
  };
}

export default withErrorHandling(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OnboardedSuppliersGrid)
);

OnboardedSuppliersGrid.propTypes = {
  onboardedSuppliers: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
  domains: PropTypes.array.isRequired,
  cadences: PropTypes.array.isRequired,
  supplierTypes: PropTypes.array.isRequired,
  procurementTypes: PropTypes.array.isRequired,
  leads: PropTypes.array.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  loadSupplierBOBList: PropTypes.func.isRequired,
  supplierBOBList: PropTypes.array.isRequired,
  deleteSupplier: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  updateOnboardedSuppliersAction: PropTypes.func.isRequired,
  resetMessageBar: PropTypes.func.isRequired,
};
