/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@fluentui/react/lib/TextField';
import { Text } from '@fluentui/react/lib/Text';
import { CommandBarButton } from '@fluentui/react/lib/Button';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { Announced } from '@fluentui/react/lib/Announced';
import {
  CheckboxVisibility,
  DetailsList,
  DetailsListLayoutMode,
  Selection
} from '@fluentui/react/lib/DetailsList';
import { getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';

const theme = getTheme();
const headerDividerClass = 'DetailsListAdvancedExample-divider';
const classNames = mergeStyleSets({
  commandBarText: {
    padding: '12px'
  },
  commandBarWrapper: {
    display: 'flex',
    padding: '16px 0px',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  headerDivider: {
    display: 'inline-block',
    height: '10%'
  },
  headerDividerBar: [
    {
      display: 'none',
      background: theme.palette.themePrimary,
      position: 'absolute',
      top: 16,
      bottom: 0,
      width: '1px',
      zIndex: 5
    },
    headerDividerClass
  ],
  root: {
    selectors: {
      [`.${headerDividerClass}:hover + .${headerDividerClass}`]: {
        display: 'inline'
      }
    }
  }
});
export default class ContractDetailsList extends React.Component {
  constructor(props) {
    super(props);
    this._allItems = props.contracts || [];
    this._selection = new Selection({
      onSelectionChanged: this._onItemsSelectionChanged
    });
    this._selection.setItems(this._allItems, false);
    this.state = {
      items: this._allItems,
      selectionCount: 0,
      checkboxVisibility: CheckboxVisibility.always,
      contractId: '',
      columns: this._buildColumns(),
      isHeaderVisible: true,
    };
  }

  static getDerivedStateFromProps(props) {
    const { clearTable } = props;
    if (clearTable) {
      return { items: [] };
    }
    return null;
  }

  render() {
    const {
      items,
      announcedMessage,
      selectionCount,
      contractId,
      columns,
      checkboxVisibility
    } = this.state;
    return (
      <div className={classNames.root}>
        <div className="col-md-6 mt15">
          <div className="col-md-12">
            <TextField label="Enter contract id" onChange={this._onContractIdTextChange} value={contractId} placeholder="Enter Contract ID" />
          </div>
        </div>

        <div className={`${classNames.commandBarWrapper} col-md-12`}>
          <CommandBarButton
            iconProps={{ iconName: 'Add' }}
            text="Add Contract"
            key="addRow"
            onClick={this._onAddRow}
          />
          {items.length > 0 && <div> <Text
            className={classNames.commandBarText}
          >{`${selectionCount} selected`}
                                     </Text>
          <CommandBarButton
            iconProps={{ iconName: 'Delete' }}
            text="Delete Contract"
            key="deleteRow"
            onClick={this._onDeleteRow}
          />
                               </div>
          }
        </div>
        <Announced message={`${selectionCount} selected`} />

        {announcedMessage ? (
          <Announced title={announcedMessage} message={announcedMessage} />
        ) : (
          undefined
        )}


        <div className="details-table">
          {items.length > 0 &&
          <MarqueeSelection selection={this._selection}>
            <DetailsList
              items={items}
              setKey="items"
              layoutMode={DetailsListLayoutMode.fixedColumns}
              selection={this._selection}
              checkboxVisibility={checkboxVisibility}
              selectionPreservedOnEmptyClick
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              onItemInvoked={this._onItemInvoked}
              onRenderItemColumn={this.onRenderItemColumn}
              fixedColumns={0}
              columns={columns}
            />
          </MarqueeSelection>}
        </div>
      </div>
    );
  }

  _onRenderDivider = (columnProps, defaultRenderer) => {
    const { columnIndex } = columnProps;
    return (
      <React.Fragment key={`divider-wrapper-${columnIndex}`}>
        <span className={classNames.headerDivider}>
          {defaultRenderer(columnProps)}
        </span>
        <span className={classNames.headerDividerBar} />
      </React.Fragment>
    );
  }

  _onToggleHeaderVisible = () => {
    const { isHeaderVisible } = this.state;
    this.setState({ isHeaderVisible: !isHeaderVisible });
  }

  // _onCheckboxVisibilityChanged = (ev, menuItem) => {
  //   this.setState({ checkboxVisibility: menuItem.data });
  // }

  _onItemInvoked = (item, index) => {
    // eslint-disable-next-line no-console
    console.log('Item invoked', item, index);
  }

  _onContractIdTextChange = (e) => {
    const { toggleClearContractTable } = this.props;
    this.setState({ contractId: e.target.value });
    toggleClearContractTable(false);
  }

  _parsedate = (dateString) => {
    const dateTimeArray = dateString?.split(' ');
    const tempDate = [dateTimeArray[0]?.split('-')?.reverse()?.join('-')];
    tempDate.push(dateTimeArray[1]);
    return new Date(tempDate?.join(' '));
  }

  _isExpired = (expirationDate) => {
    if (expirationDate) {
      const expDate = this._parsedate(expirationDate);
      const currDate = new Date();
      return expDate < currDate;
      } return false;
  }

  _isValidContractDetails = (contractData, cId) => {
    const { setErrorMessage } = this.props;
    if (contractData?.validationResult?.isValid) {
      if (contractData?.data !== null && contractData?.data !== undefined) {
        return true;
      }
      setErrorMessage(`Contract id ${cId} is invalid`);
      return false;
    }
    setErrorMessage(`Contract id ${cId} is invalid`);
    return false;
  }

  _onAddRow = async () => {
    const { contractId: cId, items } = this.state;
    const {
        addNewContractId,
        addContractHttp,
        contractIds,
        setErrorMessage
      } = this.props;
    if (contractIds.includes(cId)) {
      setErrorMessage(`contract id ${cId} is already added`);
      return;
    }
    if (cId === '') return;

    try {
      const contractData = await addContractHttp(cId);
      if (!this._isValidContractDetails(contractData, cId)) return;
      this.setState({
        items: [...items, contractData.data],
        contractId: '',
      });
      addNewContractId(cId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }

  _buildColumns() {
    return [
      {
        key: 'masterAgreementContractID',
        fieldName: 'masterAgreementContractID',
        name: 'Master Agreement ContractID',
        tabIndex: '0',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isMultiline: false
      },
      {
        key: 'contractTitle',
        fieldName: 'contractTitle',
        name: 'Contract Title',
        tabIndex: '0',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
        isMultiline: false
      },
      {
        key: 'supplierID',
        fieldName: 'supplierID',
        name: 'Supplier ID',
        tabIndex: '0',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isMultiline: false
      },
      {
        key: 'supplierName',
        fieldName: 'supplierName',
        name: 'Supplier Name',
        tabIndex: '0',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isMultiline: false
      },
      {
        key: 'companyCode',
        fieldName: 'companyCode',
        name: 'Company Code',
        tabIndex: '0',
        isMultiline: false,
        minWidth: 100,
        maxWidth: 150,
        isResizable: true
      },
      {
        key: 'effectiveDate',
        fieldName: 'effectiveDate',
        name: 'Effective Date',
        tabIndex: '0',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        isMultiline: false
      },
      {
        key: 'expirationDate',
        fieldName: 'expirationDate',
        name: 'Expiration Date',
        tabIndex: '0',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isMultiline: false
      }
    ];
  }

  onRenderItemColumn(item, index, column) {
    const col = column.fieldName;
    return <span title={item[col]}>{item[col]}</span>;
  }

  _onDeleteRow = () => {
    const { items } = this.state;
    const { removeContractId } = this.props;
    const selectedItems = [];
    items.filter(
      // eslint-disable-next-line array-callback-return
      (item, index) => {
        if (this._selection.isIndexSelected(index)) {
          selectedItems.push(item.masterAgreementContractID);
        }
      }
    );
    if (this._selection.getSelectedCount() > 0) {
      this.setState(previousState => ({
          items: previousState.items.filter(
            (item, index) => !this._selection.isIndexSelected(index)
          )
        }));
    }
    removeContractId(selectedItems);
  }

  _onItemsSelectionChanged = () => {
    this.setState({
      selectionCount: this._selection.getSelectedCount()
    });
  }
}

ContractDetailsList.propTypes = {
  clearTable: PropTypes.bool.isRequired,
  contractIds: PropTypes.array.isRequired,
  contracts: PropTypes.array.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  toggleClearContractTable: PropTypes.func.isRequired,
  removeContractId: PropTypes.func.isRequired,
  addNewContractId: PropTypes.func.isRequired,
  addContractHttp: PropTypes.func.isRequired
};
