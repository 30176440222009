import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withErrorHandling from '../../hoc/withErrorHandling';
import './SupplierScorecardHeatmap.css';
import * as scorecardReduxActions from '../../../redux/actions/scorecardActions';

export class SupplierScorecardHeatmap extends Component {
  componentDidMount() {
    const {
      loadSupplierScorecardCategoryDetails,
      selectedScorecardCategory,
      supplierScorecardCategoryDetails
    } = this.props;

    if (supplierScorecardCategoryDetails === null) {
      loadSupplierScorecardCategoryDetails(selectedScorecardCategory);
    }
  }

  isNullOrUndefined = variable => {
    if (
      variable === null ||
      variable === '' ||
      variable === undefined ||
      variable.length > 0
    ) {
      return true;
    }
    return false;
  };

  getColor = score => {
    switch (Math.floor(Number(score))) {
      case 5:
        return 'bgcolor5';
      case 4:
        return 'bgcolor4';
      case 3:
        return 'bgcolor3';
      case 2:
        return 'bgcolor2';
      case 1:
        return 'bgcolor1';
      case 0:
        return 'bgcolor0';
      case null:
        return 'bgcolorundefined';
      default:
        return 'bgcolordefault';
    }
  };

  getAriaLabel = score => {
    switch (Math.floor(Number(score))) {
      case 5:
        return '';
      case 4:
        return '';
      case 3:
        return '';
      case 2:
        return '';
      case 1:
        return '';
      case 0:
        return 'newly added BOB';
      case null:
        return 'not Applicable';
      default:
        return 'Quarterly Book of Business';
    }
  };

  //  eslint-disable-next-line class-methods-use-this
  render() {
    const { supplierScorecardCategoryDetails } = this.props;

    return (
      <div className="">
        <h6 className="heatmap">Heatmap</h6>
        <div className="col-md-12 col-lg-12 col-sm-12 heatmap-selector pl-0">
          <div className="wrapper">
          <span className="legend"> Legend </span>
          <span className="dash"> - </span>
          <span className="quaterlyBOB"> Quarterly Book of Business</span>
          <span className="na"> N/A</span>
          <span className="notApplicable"> Not Applicable</span>
          <div className="squareBoundary"><span className="square" /></div>
          <span className="newlyaddedBOB"> Newly added BOB</span>
          </div>
        </div>
        <table className="mt-4 mb-3 col-md-12 col-lg-12 col-sm-12 bookOfBussinessTable">
          <thead className="">
            <tr>
            <th className="bobheader">Book of Business</th>
            <th className="weight">Weight</th>
            {supplierScorecardCategoryDetails.heatMapData.cadences &&
              supplierScorecardCategoryDetails.heatMapData.cadences.map(
                (data, key) => (
                  <th
                    className={
                      supplierScorecardCategoryDetails.heatMapData.cadences
                        .length -
                        1 ===
                      key
                        ? 'cadenceCurrentPeriodHeader ml-4'
                        : 'cadenceheader ml-4'
                    }
                  >
                    {supplierScorecardCategoryDetails.heatMapData.cadences
                      .length -
                      1 ===
                    key
                      ? <> {data.name} <br /> (Current Period) </>
                      : data.name}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {supplierScorecardCategoryDetails.heatMapData.bobs &&
              supplierScorecardCategoryDetails.heatMapData.bobs.map(
                (bob, key) => (
                  //  eslint-disable-next-line
                  <tr key={key}>
                    <td
                      style={{
                        // display: '-webkit-box',
                        '-webkit-line-clamp': '2',
                        '-webkit-box-orient': 'vertical'
                      }}
                      title={bob.name}
                      className="boblist"
                    >
                      {bob.name}
                    </td>
                    <td className="weightlist">
                      {`${Number(
                        Object.values(
                          supplierScorecardCategoryDetails.heatMapData
                            .itemHistoryDic[bob.id]
                        )
                          .map(x => x.weight)
                          .slice(-1)
                      ).toFixed(1)}%`}
                    </td>
                    {supplierScorecardCategoryDetails.heatMapData.itemHistoryDic[
                      bob.id
                    ].map(data => (
                      <td className={`scorelist ${this.getColor(data.score)}`}>
                        {data.score}
                        <span className="sr-only">{this.getAriaLabel(data.score)}</span>
                      </td>
                    ))}
                  </tr>
                )
              )}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedScorecardCategory:
      state.myActionsScorecard.selectedScorecardCategory,
    supplierScorecardCategoryDetails:
      state.myActionsScorecard.supplierScorecardCategoryDetails
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSupplierScorecardCategoryDetails: bindActionCreators(
      scorecardReduxActions.loadSupplierScorecardCategoryDetails,
      dispatch
    )
  };
}

export default withErrorHandling(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SupplierScorecardHeatmap)
);

SupplierScorecardHeatmap.propTypes = {
  selectedScorecardCategory: PropTypes.number.isRequired,
  loadSupplierScorecardCategoryDetails: PropTypes.func.isRequired,
  supplierScorecardCategoryDetails: PropTypes.object.isRequired
};
