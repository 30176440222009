/* eslint-disable no-param-reassign */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import PropTypes from 'prop-types';
// import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { PrimaryButton, Spinner } from 'office-ui-fabric-react';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { FocusTrapZone } from 'office-ui-fabric-react/lib/FocusTrapZone';
import './MetricConfiguration.css';
import {
 ComboBox, MessageBar, Modal, ActionButton, Stack
} from '@fluentui/react';
import PerformanceMetricModal from '../performanceMetric/PerformanceMetric';
import ConfirmDialog from '../confirmDialog/ConfirmDialog';
import withErrorHandling from '../../hoc/withErrorHandling';
import loadMetricConditions from '../../../redux/actions/metricConditionsActions';
import {
  addNewMetric,
  editMetric,
  saveMetric,
  deleteMetric,
  undoMetric,
} from '../../../redux/actions/performanceMetricActions';
import {
  retrieveMetrics,
  manageMetrics,
  changeMetricCategory,
} from '../../../redux/actions/metricConfigurationActions';
import DefaultMetric from '../defaultMetric/DefaultMetric';
import { PoliteAnnouncement } from '../../../redux/actions/announcementActions';

export class MetricConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.onClickAddMetric = this.onClickAddMetric.bind(this);
    this.onClickEditPerfMetric = this.onClickEditPerfMetric.bind(this);
    this.onClickSavePerfMetric = this.onClickSavePerfMetric.bind(this);
    this.onClickDeletePerfMetric = this.onClickDeletePerfMetric.bind(this);
    this.proceedWithCloseModal = this.proceedWithCloseModal.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.checkIfUnsavedMetricsExist =
      this.checkIfUnsavedMetricsExist.bind(this);
    this.checkWeightAllocation = this.checkWeightAllocation.bind(this);
    this.onClickUndoMetric = this.onClickUndoMetric.bind(this);
    this.enableSubmit = this.enableSubmit.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.onChangeCategory = this.onChangeCategory.bind(this);
    this.closeDialogAndDiscardCategoryMetricChanges =
      this.closeDialogAndDiscardCategoryMetricChanges.bind(this);
  }

  state = {
    errorMessage: '',
    metricWeight: '',
    subMetricWeight: [],
    showModal: false,
    showDialog: false,
    isErrorInWeightCalculation: false,
    isErrorInSubMetricWeightCalculation: false,
    isUnsavedMetricExist: false,
    shouldEnableSubmit: false,
    showDialogForCategoryChange: false,
    currentSelectedOptionInCategory: {},
    newSelectedOptionInCategory: {},
    savecategoryDetailsLoading: false,
    metricDeletedSuccessFully: false,
  };

  async componentDidMount() {
    const {
      showModal,
      getMetricConditions,
      loadPerfMetrics,
      selectedBOBId,
      onLoadData,
      procurementTypeId,
    } = this.props;
    await getMetricConditions();
    await loadPerfMetrics(selectedBOBId, procurementTypeId);
    this.setState({
      showModal: showModal,
    });
    onLoadData();
  }

  onClickAddMetric() {
    const { addNewMetricInfoAction, procurementTypeId } = this.props;
    addNewMetricInfoAction(procurementTypeId);
    this.setState({
      isErrorInWeightCalculation: false,
      isErrorInSubMetricWeightCalculation: false,
      isUnsavedMetricExist: false,
      shouldEnableSubmit: true,
    });

    setTimeout(() => {
    const parentMetricContainer = document.getElementsByClassName('parentMetricCon');
    const parentMetricElement = parentMetricContainer && parentMetricContainer.length > 0
    && parentMetricContainer[parentMetricContainer.length - 1];
    const descTextElements = parentMetricElement.getElementsByClassName('mDescTextArea');

    const textAreaEle = descTextElements && descTextElements.length > 0 && descTextElements[0].getElementsByClassName('ms-TextField-field');

    if (textAreaEle && textAreaEle.length > 0) textAreaEle[0].focus();
    }, 1000);
  }

  onChangeCategory(selectedOption) {
    const { changeMetricCategoryAction } = this.props;
    if (selectedOption !== undefined || selectedOption !== null) {
      this.setState({ currentSelectedOptionInCategory: selectedOption });
      changeMetricCategoryAction(selectedOption.value);
    }
  }

  onClickEditPerfMetric(metricInfoIdToBeEdit) {
    const { editMetricInfoAction } = this.props;
    editMetricInfoAction(metricInfoIdToBeEdit);
    this.setState({
      isErrorInWeightCalculation: false,
      isErrorInSubMetricWeightCalculation: false,
    });
  }

  onClickUndoMetric(metricInfoIdToBeUndo) {
    const { undoMetricInfoAction } = this.props;
    undoMetricInfoAction(metricInfoIdToBeUndo);
  }

  onClickSavePerfMetric(metricInfoToBeSave) {
    const { saveMetricInfoAction } = this.props;
    metricInfoToBeSave.metric = {
      ...metricInfoToBeSave.metric,
      isUpdated: !metricInfoToBeSave.metric.isNew,
    };
    metricInfoToBeSave = {
      ...metricInfoToBeSave,
      hasSave: true,
      isEditing: false,
    };

    saveMetricInfoAction(metricInfoToBeSave);
    this.setState({
      isUnsavedMetricExist: false,
      shouldEnableSubmit: true,
    });
  }

  onClickDeletePerfMetric(metricInfoToBeDelete) {
    const { deleteMetricInfoAction } = this.props;
    metricInfoToBeDelete.metric = {
      ...metricInfoToBeDelete.metric,
      isDeleted: true,
      isUpdated: false,
    };
    metricInfoToBeDelete = {
      ...metricInfoToBeDelete,
      isEditing: false,
      hasSave: true,
    };
    deleteMetricInfoAction(metricInfoToBeDelete);
    this.setState({
      shouldEnableSubmit: true,
      isErrorInWeightCalculation: false,
      isErrorInSubMetricWeightCalculation: false,
      isUnsavedMetricExist: false,
      metricDeletedSuccessFully: true,
    });
    setTimeout(() => {
      this.setState({ metricDeletedSuccessFully: false });
    }, 3000);
  }

  async onClickSubmit() {
    const {
      updateConfiguredMetrics,
      selectedBOBId,
      metricCategoryId,
      metricsInfo,
      politeAnnouncement,
      procurementTypeId,
    } = this.props;

    const areAllMetricsDeleted = metricsInfo.every(
      (metricInfo) => metricInfo.metric.isDeleted === true
    );
    if (
      this.checkIfUnsavedMetricsExist() &&
      this.checkWeightAllocation() &&
      (procurementTypeId === 1 ||
        (procurementTypeId === 2 && !areAllMetricsDeleted))
    ) {
      this.setState({
        savecategoryDetailsLoading: true,
      });
      try {
        politeAnnouncement('Saving data');
        await updateConfiguredMetrics(
          metricsInfo,
          selectedBOBId,
          metricCategoryId
        );
        this.setState({
          shouldEnableSubmit: false,
          savecategoryDetailsLoading: false,
        });
      } catch {
        this.setState({
          savecategoryDetailsLoading: false,
        });
      }
    }
  }

  changeCategory(event, selectedOption) {
    this.setState({ newSelectedOptionInCategory: selectedOption });
    if (this.checkIfUnsavedMetricsExist()) {
      this.onChangeCategory(selectedOption);
    } else {
      this.setState({ showDialogForCategoryChange: true });
    }
  }

  closeDialogAndDiscardCategoryMetricChanges() {
    const { newSelectedOptionInCategory } = this.state;
    this.setState({ showDialogForCategoryChange: false, showModal: true });
    this.onChangeCategory(newSelectedOptionInCategory);
  }

  closeModal() {
    if (this.checkIfUnsavedMetricsExist()) {
      this.proceedWithCloseModal();
    } else {
      this.setState({ showDialog: true });
    }
  }

  proceedWithCloseModal() {
    const { onCloseModal } = this.props;
    this.setState({ showDialog: false, showModal: false });
    onCloseModal();
  }

  closeDialog() {
    this.setState({
      showDialog: false,
      showDialogForCategoryChange: false,
      showModal: true,
    });
  }

  checkIfUnsavedMetricsExist() {
    const { metricsInfo } = this.props;
    const isAnyUnsavedMetrics = metricsInfo.some(
      (eachMetricInfo) => eachMetricInfo.isEditing === true
    );

    const isAnyUnsavedSubMetrics = metricsInfo.some(
      (eachMetricInfo) =>
        eachMetricInfo.metric.subMetrics != null &&
        eachMetricInfo.metric.subMetrics.some((filt) => filt.isEditing === true)
    );

    const AreAllMetricsSaved = !(isAnyUnsavedMetrics || isAnyUnsavedSubMetrics);

    this.setState({ isUnsavedMetricExist: !AreAllMetricsSaved });
    return AreAllMetricsSaved;
  }

  checkWeightAllocation() {
    const { metricsInfo } = this.props;
    const notDeletedMetrics = metricsInfo.filter(
      (eachMetricInfo) => eachMetricInfo.metric.isDeleted === false
    );

    if (notDeletedMetrics.length === 0) {
      return true;
    }

    let weightedParentMetricSum = 0;
    let weightedChildMetricSum = 0;
    let MetricId = 0;
    notDeletedMetrics.forEach((eachMetricInfo) => {
      weightedParentMetricSum += parseFloat(eachMetricInfo.metric.metricWeight);
    });

    const isSumParentWtsEqual =
      Math.round(weightedParentMetricSum * 100) / 100 === 100;

    this.setState({
      metricWeight: weightedParentMetricSum,
    });
    const totalWeightPerMetric = [];
    notDeletedMetrics.forEach((eachMetricInfo) => {
      const notDeletedSubMetrics = eachMetricInfo.metric.subMetrics.filter(
        (eachSubMetricInfo) => eachSubMetricInfo.metric.isDeleted === false
      );
      if (
        Array.isArray(notDeletedSubMetrics) &&
        notDeletedSubMetrics.length > 0
      ) {
        weightedChildMetricSum = 0;
        notDeletedSubMetrics.forEach((eachSubMetricInfo) => {
          MetricId = eachMetricInfo.metric.metricId;
          weightedChildMetricSum += parseFloat(
            eachSubMetricInfo.metric.metricWeight
          );
        });
        totalWeightPerMetric.push({
          id: MetricId,
          aggregateSubMetricWeight: weightedChildMetricSum,
          //  eslint-disable-next-line
          subMetricWeight: `Sum of all the sub-metrics weight needs to be 100%, where as current allocated weight for sub-metrics is ${weightedChildMetricSum}`,
        });
      }
    });

    const isNoErrorInWeightCalculation = isSumParentWtsEqual;

    const isNoErrorInSubMetricWeightCalculation = totalWeightPerMetric.every(
      (each) => each.aggregateSubMetricWeight === 100
    );

    this.setState({
      isErrorInWeightCalculation: !isNoErrorInWeightCalculation,
      isErrorInSubMetricWeightCalculation:
        !isNoErrorInSubMetricWeightCalculation,
      subMetricWeight: totalWeightPerMetric,
    });

    return isNoErrorInWeightCalculation;
  }

  enableSubmit() {
    this.setState({
      shouldEnableSubmit: true,
      isErrorInWeightCalculation: false,
      isErrorInSubMetricWeightCalculation: false,
      isUnsavedMetricExist: false,
    });
  }

  render() {
    const {
      showModal,
      showDialog,
      isErrorInWeightCalculation,
      isErrorInSubMetricWeightCalculation,
      isUnsavedMetricExist,
      shouldEnableSubmit,
      metricWeight,
      subMetricWeight,
      showDialogForCategoryChange,
      currentSelectedOptionInCategory,
      savecategoryDetailsLoading,
      metricDeletedSuccessFully,
    } = this.state;

    let { errorMessage } = this.state;
    const {
      metricsInfo,
      hasConfiguredMetrics,
      wasDefaultMetric,
      manageMetricsResponse,
      shouldRenderModal,
      hasReadOnlyAccess,
      metricsByCategory,
      procurementTypeId,
    } = this.props;
    const areAllMetricsDeleted = metricsInfo.every(
      (metricInfo) => metricInfo.metric.isDeleted === true
    );
    if (metricWeight !== 100) {
      errorMessage = `Sum of all the metrics weight needs to be 100%,  
    where as current allocated weight for metrics is ${metricWeight}`;
    }
    const categoriesList =
      metricsByCategory && metricsByCategory.length > 0
        ? metricsByCategory.map((metric) => ({
            key: metric.metricCategoryId,
            text: metric.metricCategory,
          }))
        : [];

    const comboBoxStyles = {
      container: {
        display: 'flex',
        label: { fontSize: '14px !important', margin: '0 10px' },
      },
      optionsContainerWrapper: { maxHeight: '350px' },
      root: {
        '::after': {
          borderRadius: '4px',
        },
      },
    };
    const iconButtonStyles = {
      icon: {
        color: '#333333 !important'
      },
      rootHovered: {
        color: 'black',
      },
    };
    const iconProps = {
      iconName: 'Cancel',
    };

    return (
      <>
        {shouldRenderModal && (
          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            isOpen={showModal}
            onDismiss={this.closeModal}
            animation={false}
            dialogClassName="ModalContainer modal-metric-configuration"
          >
            <FocusTrapZone>
              <Stack enableScopedSelectors className="modal-header">
                <Stack horizontal style={{ width: '100%' }}>
                  <Stack style={{ flexGrow: 1 }} />
              <ActionButton
                styles={iconButtonStyles}
                iconProps={iconProps}
                ariaLabel="Close popup modal"
                onClick={this.closeModal}
              />
                </Stack>
                <div className="modal-title" id="contained-modal-title-vcenter">
                  {wasDefaultMetric && procurementTypeId === 1 ? (
                    <>
                      <DefaultMetric
                        hasConfiguredMetrics={hasConfiguredMetrics}
                        areAllMetricsDeleted={areAllMetricsDeleted}
                        metricsLength={metricsInfo.length}
                        onClickAddMetric={this.onClickAddMetric}
                        hasReadOnlyAccess={hasReadOnlyAccess}
                      />
                      {!areAllMetricsDeleted &&
                      hasConfiguredMetrics &&
                      metricsInfo.length >= 1 ? (
                        <div className="row mtPerf">
                          <ChoiceGroup
                            className="radioButton"
                            selectedKey="perfMetricKey"
                            options={[
                              {
                                key: 'perfMetricKey',
                                text: 'Performance Metrics',
                              },
                            ]}
                          />
                          <PrimaryButton
                            autoFocus
                            allowDisabledFocus="true"
                            disabled={hasReadOnlyAccess}
                            className={
                              hasReadOnlyAccess
                                ? 'addMetric fadeOut'
                                : 'addMetric'
                            }
                            text="Metric"
                            onClick={this.onClickAddMetric}
                            ariaLabel="Add Metric"
                            ariaDescription="Add New Custom Metric for Performance cateogry"
                          />
                        </div>
                      ) : null}
                    </>
                  ) : procurementTypeId === 2 ? (
                    <div className="onlyPerfMetricsContainer">
                      <div className="onlyPerfMetrics">
                        <PrimaryButton
                          autoFocus
                          allowDisabledFocus="true"
                          disabled={hasReadOnlyAccess}
                          className={
                            hasReadOnlyAccess
                              ? 'addMetric fadeOut'
                              : 'addMetric'
                          }
                          text="Metric"
                          onClick={this.onClickAddMetric}
                          ariaLabel="Add Metric"
                          ariaDescription="Add New Custom Metric for Performance cateogry"
                        />
                        <div className="categoryContainer">
                          <div className="categoryLabel">
                            <div className="categoryLabelRow1">
                              <ComboBox
                                // aria-labelledby={`ActionPlanStatus:${actionPlan.actionPlanId}`}
                                aria-label="Metrics Category"
                                label="Metrics Category"
                                defaultValue={categoriesList[0]}
                                value={
                                  Object.keys(currentSelectedOptionInCategory)
                                    .length !== 0
                                    ? currentSelectedOptionInCategory
                                    : categoriesList[0]
                                }
                                placeholder="select metric category"
                                useAriaLabelAsText
                                allowFreeInput
                                useComboBoxAsMenuWidth
                                autoComplete="on"
                                options={categoriesList}
                                iconButtonProps={{
                                  role: 'button',
                                  ariaLabel: 'expand dropdown button',
                                }}
                                onChange={this.changeCategory}
                                styles={comboBoxStyles}
                                comboBoxOptionStyles={{ maxHeight: '100px' }}
                                calloutProps={{ doNotLayer: true }}
                                className="metricCategorySelectDropDown"
                                id="MetricsCategorySelect"
                                // isDisabled={!actionPlan.isApplicable || isReadOnly}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="onlyPerfMetricsContainer">
                      <div className="onlyPerfMetrics">
                        <PrimaryButton
                          autoFocus
                          allowDisabledFocus="true"
                          disabled={hasReadOnlyAccess}
                          className={
                            hasReadOnlyAccess
                              ? 'addMetric fadeOut'
                              : 'addMetric'
                          }
                          text="Metric"
                          onClick={this.onClickAddMetric}
                          ariaLabel="Add Metric"
                          ariaDescription="Add New Custom Metric for Performance cateogry"
                        />
                        <h3>Performance Metrics</h3>
                      </div>
                    </div>
                  )}
                </div>
              </Stack>
              <div className="modal-body">
                <div className="scrollHBar">
                  {hasConfiguredMetrics ? (
                    <>
                      {metricsInfo &&
                        metricsInfo.map((metricInfo) => (
                          <>
                            {metricInfo.metric.isDeleted === false ? (
                              <PerformanceMetricModal
                                key={metricInfo.metricInfoId}
                                onClickSave={this.onClickSavePerfMetric}
                                onClickEdit={this.onClickEditPerfMetric}
                                onClickDelete={this.onClickDeletePerfMetric}
                                onUndoMetric={this.onClickUndoMetric}
                                performanceMetricInfo={metricInfo}
                                enableSubmit={this.enableSubmit}
                                // eslint-disable-next-line max-len
                                isErrorInSubMetricWeightCalculation={
                                  isErrorInSubMetricWeightCalculation
                                }
                                isErrorInWeightCalculation={
                                  isErrorInWeightCalculation
                                }
                                errorMessage={subMetricWeight}
                              />
                            ) : null}
                          </>
                        ))}
                    </>
                  ) : null}
                  <div className="modalFooter">
                    {metricDeletedSuccessFully && (
                      <MessageBar
                        messageBarIconProps={{ iconName: 'Completed' }}
                        className="successMessage"
                        role="alert"
                        delayedRender={false}
                      >
                        Metric is Deleted successfully
                      </MessageBar>
                    )}
                    <div className="errorsInSubmission">
                      {isErrorInWeightCalculation ? (
                        <MessageBar
                          messageBarIconProps={{
                            iconName: isErrorInWeightCalculation ? 'error' : '',
                          }}
                          className="errorMessage"
                          role="alert"
                          delayedRender={false}
                        >
                          {errorMessage}
                        </MessageBar>
                      ) : (
                        ''
                      )}
                      {isUnsavedMetricExist && (
                        <MessageBar
                          messageBarIconProps={{ iconName: 'error' }}
                          className="errorMessage"
                          role="alert"
                          delayedRender={false}
                        >
                          Please save all metrics/submetrics
                        </MessageBar>
                      )}
                      {manageMetricsResponse != null &&
                        (manageMetricsResponse.isValid !== undefined ? (
                          manageMetricsResponse.isValid &&
                          !isErrorInSubMetricWeightCalculation ? (
                            <MessageBar
                              messageBarIconProps={{ iconName: 'Completed' }}
                              className="successMessage"
                              role="alert"
                              delayedRender={false}
                            >
                              Metrics were submitted successfully
                            </MessageBar>
                          ) : (
                            !isErrorInSubMetricWeightCalculation && (
                              <MessageBar
                                messageBarIconProps={{ iconName: 'error' }}
                                className="errorMessage"
                                role="alert"
                                delayedRender={false}
                              >
                                Something went wrong
                              </MessageBar>
                            )
                          )
                        ) : (
                          <MessageBar
                            messageBarIconProps={{ iconName: 'error' }}
                            className="errorMessage"
                            role="alert"
                            delayedRender={false}
                          >
                            {manageMetricsResponse.errormessage}
                          </MessageBar>
                        ))}
                    </div>
                    <div className="submission">
                      {/* <Link href={config.PredefinedMetrics}>
                    Click here to view the default Book of business setup for
                    Relatonship, Value and Risk metrics
                  </Link> */}
                      <PrimaryButton
                        text="Submit"
                        onClick={this.onClickSubmit}
                        disabled={!shouldEnableSubmit}
                        className={
                          hasConfiguredMetrics || areAllMetricsDeleted
                            ? 'showSubmitButton'
                            : 'hideSubmitButton'
                        }
                        ariaLabel="Submit"
                      />
                    </div>
                  </div>
                  {showDialog ? (
                    <ConfirmDialog
                      onClickYes={this.proceedWithCloseModal}
                      onClickNo={this.closeDialog}
                      showDialog={showDialog}
                    />
                  ) : null}
                  {showDialogForCategoryChange ? (
                    <ConfirmDialog
                      onClickYes={
                        this.closeDialogAndDiscardCategoryMetricChanges
                      }
                      onClickNo={this.closeDialog}
                      showDialog={showDialogForCategoryChange}
                    />
                  ) : null}
                  {savecategoryDetailsLoading ? (
                    <Spinner className="spinner" />
                  ) : null}
                </div>
              </div>
            </FocusTrapZone>
          </Modal>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.loggedInUser.alias.split('@')[0],
    metricsInfo:
      state.configuredMetrics.metricsInfo === null ||
      state.configuredMetrics.metricsInfo.length === 0
        ? []
        : state.configuredMetrics.metricsInfo,
    hasConfiguredMetrics:
      (state.configuredMetrics.isDefaultMetric &&
        state.configuredMetrics.metricsInfo.length >= 1) ||
      (!state.configuredMetrics.isDefaultMetric &&
        state.configuredMetrics.metricsInfo.length >= 1),
    wasDefaultMetric: state.configuredMetrics.isDefaultMetric,
    metricCategoryId: state.configuredMetrics.metricCategoryId,
    manageMetricsResponse: state.configuredMetrics.submitResponse,
    shouldRenderModal: state.configuredMetrics.shouldRenderModal,
    hasReadOnlyAccess: state.loggedInUser.isReadOnly,
    // procurementTypeId:
    //    (state.boblist && state.boblist.length > 0)
    //     ? state.boblist[0].procurementTypeId : 0,
    metricsByCategory: state.configuredMetrics.metricsByCategory,
  };
}

const mapDispatchToProps = {
  updateConfiguredMetrics: manageMetrics,
  getMetricConditions: loadMetricConditions,
  loadPerfMetrics: retrieveMetrics,
  addNewMetricInfoAction: addNewMetric,
  editMetricInfoAction: editMetric,
  saveMetricInfoAction: saveMetric,
  deleteMetricInfoAction: deleteMetric,
  undoMetricInfoAction: undoMetric,
  politeAnnouncement: PoliteAnnouncement,
  changeMetricCategoryAction: changeMetricCategory,
};

export default withErrorHandling(
  connect(mapStateToProps, mapDispatchToProps)(MetricConfiguration)
);
MetricConfiguration.defaultProps = {
  politeAnnouncement: null,
};

MetricConfiguration.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  metricsInfo: PropTypes.array.isRequired,
  updateConfiguredMetrics: PropTypes.func.isRequired,
  getMetricConditions: PropTypes.func.isRequired,
  selectedBOBId: PropTypes.number.isRequired,
  metricCategoryId: PropTypes.number.isRequired,
  hasConfiguredMetrics: PropTypes.bool.isRequired,
  loadPerfMetrics: PropTypes.func.isRequired,
  addNewMetricInfoAction: PropTypes.func.isRequired,
  editMetricInfoAction: PropTypes.func.isRequired,
  saveMetricInfoAction: PropTypes.func.isRequired,
  deleteMetricInfoAction: PropTypes.func.isRequired,
  undoMetricInfoAction: PropTypes.func.isRequired,
  wasDefaultMetric: PropTypes.bool.isRequired,
  manageMetricsResponse: PropTypes.object.isRequired,
  shouldRenderModal: PropTypes.bool.isRequired,
  onLoadData: PropTypes.func.isRequired,
  hasReadOnlyAccess: PropTypes.object.isRequired,
  politeAnnouncement: PropTypes.func,
  procurementTypeId: PropTypes.number.isRequired,
  metricsByCategory: PropTypes.array.isRequired,
  changeMetricCategoryAction: PropTypes.func.isRequired,
};
