/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MetricsDetailsPanel from './MetricScorecardDetailsPanel/metricScorecardDetailsPanel';
import ActionPlanContainerPanel from '../../samPortal/ActionPlanContainer';
import './MetricsContainerPanel.css';
import withErrorHandling from '../../hoc/withErrorHandling';
import * as scorecardReduxActions from '../../../redux/actions/scorecardActions';
import * as samPortalReduxActions from '../../../redux/actions/samPortalActions';
import ConfirmDialog from '../../common/confirmDialog/confirmDialog';
import { PoliteAnnouncement } from '../../../redux/actions/announcementActions';
import SupplierType from '../../../common/consts/SupplierType';
import { MetricCategory } from '../../models/consts';

export class MetricsContainerPanel extends Component {
  state = {
    showUnsavedModalPopup: false,
    samShowUnsavedModalPopup: false,
    selectedCategory: null,
    samSelectedCategory: null,
  };

  constructor(props) {
    super(props);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.getCategoryScore = this.getCategoryScore.bind(this);
    this.proceedWithTabChange = this.proceedWithTabChange.bind(this);
    this.metricScorecardDetails = null;
    this.actionPlanContainerRef = null;
    this.saveMetrics = this.saveMetrics.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      selectedBobEntry,
      onError,
      loadBobScorecardDetails,
      selectedSupplierLeadEntry,
      loadSupplierScorecardDetails,
      loadSamSupplierScorecardDetails,
      isSamPortalRequest,
      selectedSamEntry,
    } = this.props;

    if (selectedBobEntry) {
      if (
        (prevProps.selectedBobEntry == null && selectedBobEntry != null) ||
        prevProps.selectedBobEntry.bob.id !== selectedBobEntry.bob.id ||
        prevProps.selectedBobEntry.cadence.id !== selectedBobEntry.cadence.id
      ) {
        onError(null);
        loadBobScorecardDetails(
          selectedBobEntry.bob.bobId || selectedBobEntry.bob.id,
          selectedBobEntry.supplier.id,
          selectedBobEntry.cadence.id
        );
      }
    }

    const selectedLeadEntry = isSamPortalRequest
      ? selectedSamEntry
      : selectedSupplierLeadEntry;
    const prevSelectedLeadEntry = isSamPortalRequest
      ? prevProps.selectedSamEntry
      : prevProps.selectedSupplierLeadEntry;

    if (selectedLeadEntry) {
      if (
        (prevSelectedLeadEntry == null && selectedLeadEntry != null) ||
        prevSelectedLeadEntry.supplier.id !== selectedLeadEntry.supplier.id ||
        prevSelectedLeadEntry.cadence.id !== selectedLeadEntry.cadence.id
      ) {
        onError(null);
        if (!isSamPortalRequest) {
          loadSupplierScorecardDetails(
            selectedLeadEntry.supplier.id,
            selectedLeadEntry.cadence.id
          );
        } else if (selectedSamEntry && selectedSamEntry.supplier?.supplierType
           !== SupplierType.Monitor) {
          loadSamSupplierScorecardDetails(
            selectedLeadEntry.supplier.id,
            selectedLeadEntry.cadence.id
          );
          }
      }
    }
  }

  getSelectedSupplier() {
    const {
      selectedBobEntry,
      selectedSupplierLeadEntry,
      selectedSamEntry,
      isSamPortalRequest,
    } = this.props;
    if (!isSamPortalRequest && selectedBobEntry) {
      return selectedBobEntry.supplier;
    }
    if (!isSamPortalRequest && selectedSupplierLeadEntry) {
      return selectedSupplierLeadEntry.supplier;
    }
    if (selectedSamEntry) {
      return selectedSamEntry.supplier;
    }
    return null;
  }

  getSelectedBob() {
    const { selectedBobEntry, isSamPortalRequest } = this.props;
    if (!isSamPortalRequest && selectedBobEntry) {
      return selectedBobEntry.bob;
    }

    return null;
  }

  getSelectedCadence() {
    const {
      selectedBobEntry,
      selectedSupplierLeadEntry,
      selectedSamEntry,
      isSamPortalRequest,
    } = this.props;
    if (!isSamPortalRequest && selectedBobEntry) {
      return selectedBobEntry.cadence;
    }
    if (!isSamPortalRequest && selectedSupplierLeadEntry) {
      return selectedSupplierLeadEntry.cadence;
    }
    if (selectedSamEntry) {
      return selectedSamEntry.cadence;
    }
    return null;
  }

  getCategories() {
    const {
      selectedBobEntry,
      selectedSupplierLeadEntry,
      bobScorecardDetails,
      supplierScorecardDetails,
      samSupplierScorecardDetails,
      selectedSamEntry,
      isSamPortalRequest,
    } = this.props;
    if (!isSamPortalRequest && selectedBobEntry && bobScorecardDetails) {
      return bobScorecardDetails.categories;
    }
    if (
      !isSamPortalRequest &&
      selectedSupplierLeadEntry &&
      supplierScorecardDetails
    ) {
      return supplierScorecardDetails.categories;
    }
    if (selectedSamEntry && samSupplierScorecardDetails
       && selectedSamEntry.supplier?.supplierType !== SupplierType.Monitor) {
      return samSupplierScorecardDetails.categories;
    }
    return null;
  }

  getCategoryScore(key) {
    const {
      bobScorecardDetails,
      supplierScorecardDetails,
      samSupplierScorecardDetails,
      bobScorecardCategoryDetails,
      supplierScorecardCategoryDetails,
      selectedScorecardCategory,
      isSamPortalRequest,
    } = this.props;
    if (!isSamPortalRequest && bobScorecardDetails) {
      if (bobScorecardCategoryDetails && key === selectedScorecardCategory) {
        if (bobScorecardCategoryDetails.score !== 'N/A') {
          return Math.trunc(bobScorecardCategoryDetails.score * 10) / 10 || 0;
        }
        return 'N/A';
      }
      return bobScorecardDetails.categories.find((item) => item.id === key)
        .score !== 'N/A'
        ? Math.trunc(
            bobScorecardDetails.categories.find((item) => item.id === key)
              .score * 10
          ) / 10 || 0
        : 'N/A';
    }
    if (!isSamPortalRequest && supplierScorecardDetails) {
      if (
        supplierScorecardCategoryDetails &&
        Number(key) === selectedScorecardCategory
      ) {
        if (supplierScorecardCategoryDetails.score !== 'N/A') {
          return (
            Math.trunc(supplierScorecardCategoryDetails.score * 10) / 10 || 0
          );
        }
        return 'N/A';
      }
      return supplierScorecardDetails.categories.find((item) => item.id === key)
        .score !== 'N/A'
        ? Math.trunc(
            supplierScorecardDetails.categories.find((item) => item.id === key)
              .score * 10
          ) / 10 || 0
        : 'N/A';
    }

    if (isSamPortalRequest && samSupplierScorecardDetails) {
      return samSupplierScorecardDetails.categories.find(
        (item) => item.id === key
      ).score !== 'N/A'
        ? Math.trunc(
            samSupplierScorecardDetails.categories.find(
              (item) => item.id === key
            ).score * 10
          ) / 10 || 0
        : 'N/A';
    }
    return 0;
  }

  getOwner() {
    const {
      selectedBobEntry,
      selectedSupplierLeadEntry,
      selectedSamEntry,
      selectedSamSupplier,
      isSamPortalRequest,
    } = this.props;
    if (!isSamPortalRequest && selectedBobEntry) {
      if (selectedBobEntry.bob.ownerNames) {
        return selectedBobEntry.bob.ownerNames.split(',')[0];
      }

      if (selectedBobEntry.bob.ownerName) {
        return selectedBobEntry.bob.ownerName;
      }

      if (selectedBobEntry.bob.bobOwners) {
        return selectedBobEntry.bob.bobOwners[0];
      }
    }
    if (!isSamPortalRequest && selectedSupplierLeadEntry) {
      if (selectedSupplierLeadEntry.supplier.supplierLeadNames) {
        return selectedSupplierLeadEntry.supplier.supplierLeadNames[0];
      }
    }
    if (selectedSamEntry) {
      if (selectedSamEntry.supplier.supplierLeadNames) {
        return selectedSamEntry.supplier.supplierLeadNames[0];
      }
    }
    if (selectedSamSupplier) {
      return selectedSamSupplier.supplierLeadNames
        ? selectedSamSupplier.supplierLeadNames[0]
        : null;
    }
    return null;
  }

  handleTabChange(key) {
    const {
      updateSelectedCategory,
      updateSamSelectedCategory,
      bobScorecardCategoryDetails,
      supplierScorecardCategoryDetails,
      isSamPortalRequest,
      actionPlanDetails,
      politeAnnouncement,
    } = this.props;

    const { supplierMetricActionPlans, bobActionPlans } = actionPlanDetails;

    if (!isSamPortalRequest) {
      if (
        (bobScorecardCategoryDetails && bobScorecardCategoryDetails.isDirty) ||
        (supplierScorecardCategoryDetails &&
          supplierScorecardCategoryDetails.isDirty)
      ) {
        this.setState({
          showUnsavedModalPopup: true,
          selectedCategory: Number(key),
        });
      } else {
        politeAnnouncement('Loading category details');
        updateSelectedCategory(Number(key));
      }
    } else if (
      (bobActionPlans && bobActionPlans.some((plans) => plans.isDirty)) ||
      (supplierMetricActionPlans &&
        supplierMetricActionPlans.some((plans) => plans.isDirty))
    ) {
      this.setState({
        samShowUnsavedModalPopup: true,
        samSelectedCategory: Number(key),
      });
    } else {
      politeAnnouncement('Loading category details');
      updateSamSelectedCategory(Number(key));
    }
  }

  proceedWithTabChange() {
    const {
      updateSelectedCategory,
      updateSamSelectedCategory,
      isSamPortalRequest,
      politeAnnouncement,
    } = this.props;
    const { selectedCategory, samSelectedCategory } = this.state;
    if (!isSamPortalRequest) {
      politeAnnouncement('Loading category details');
      this.setState({ showUnsavedModalPopup: false });
      updateSelectedCategory(selectedCategory);
    } else {
      politeAnnouncement('Loading category details');
      this.setState({ samShowUnsavedModalPopup: false });
      updateSamSelectedCategory(samSelectedCategory);
    }
  }

  saveMetrics() {
    const {
      updateSelectedCategory,
      updateSamSelectedCategory,
      isSamPortalRequest,
    } = this.props;

    const { selectedCategory, samSelectedCategory } = this.state;

    if (!isSamPortalRequest) {
      this.metricScorecardDetails.saveScoreCardDetails();
      this.setState({ showUnsavedModalPopup: false });
      updateSelectedCategory(selectedCategory);
    } else {
      this.actionPlanContainerRef.saveActionPlans();
      this.setState({ samShowUnsavedModalPopup: false });
      updateSamSelectedCategory(samSelectedCategory);
    }
  }

  render() {
    const {
      selectedBobEntry,
      selectedSupplierLeadEntry,
      selectedScorecardCategory,
      samSelectedScorecardCategory,
      supplierScorecardDetails,
      samSupplierScorecardDetails,
      bobScorecardDetails,
      isSamPortalRequest,
      selectedSamEntry,
      loggedInUser
    } = this.props;
    const { showUnsavedModalPopup, samShowUnsavedModalPopup } = this.state;

    if (
      selectedBobEntry == null &&
      selectedSupplierLeadEntry == null &&
      selectedSamEntry == null
    ) {
      return '';
    }
    if (
      (!isSamPortalRequest &&
        bobScorecardDetails == null &&
        supplierScorecardDetails == null) ||
      (isSamPortalRequest && selectedSamEntry?.supplier?.supplierType
         !== SupplierType.Monitor
        && samSupplierScorecardDetails == null)
    ) {
      return '';
    }

    const supplier = this.getSelectedSupplier();
    const bob = this.getSelectedBob();
    const cadence = this.getSelectedCadence();
    const categoryDetails = this.getCategories();
    const owner = this.getOwner();
    const isFunctionalUser = loggedInUser.isFunctionalUser
    && !loggedInUser.isSupplierLead
    && !loggedInUser.isBobOwner
    && !loggedInUser.isAdmin;
    const activeKey = `${supplier?.id} + ${cadence?.id}`;
    return (
      <div className="row mt-3">
        <div className="col-md-12 col-lg-12 col-sm-12">
          <div className="">
            <div className="selectedaction">
                        {' '}
                        <h3 className="selectedaction">

              {isSamPortalRequest
                ? 'Supplier Action Plan Entry'
                : isFunctionalUser
                ? 'Functional User Entry'
                : selectedBobEntry !== null
                ? 'Book of Business Entry '
                                        : 'Supplier Lead Entry'}
                        </h3>
            </div>
            <div className="mt-3">
              <div className="scorecardpanel-heading clearfix">
                <h4 className="supplier-heading">{supplier.name}</h4> |
                <span className="bob-heading">
                  {bob !== null ? <> {bob.name} |</> : <></>}
                </span>{' '}
                <span className="cadence-heading">{cadence.name} </span>
                <span className="user-heading">{owner} </span>
              </div>
            </div>
            <div className="mt-3 controlled-tab-action">
              <div>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={
                    isSamPortalRequest
                      ? selectedSamEntry?.supplier?.supplierType
                        !== SupplierType.Monitor
                      ? samSelectedScorecardCategory
                      : activeKey
                      : selectedScorecardCategory
                  }
                  onSelect={this.handleTabChange}
                  unmountOnExit
                >
                  {categoryDetails ?
                    categoryDetails.map((item, index, categories) => (
                      ((supplierScorecardDetails?.supplier?.supplierTypeId
                          === SupplierType.NonSRMLite
                        && item.category.name === MetricCategory.Performance)
                        || (supplierScorecardDetails?.supplier?.supplierTypeId
                          === SupplierType.NonSRMLite
                          && item.category.name === MetricCategory.Risk)
                        || (supplierScorecardDetails?.supplier?.supplierTypeId
                          && supplierScorecardDetails?.supplier?.supplierTypeId
                            !== SupplierType.NonSRMLite)
                        || (bobScorecardDetails?.supplier?.supplierTypeId
                          && bobScorecardDetails?.supplier?.supplierTypeId
                            !== SupplierType.NonSRMLite)
                        || (bobScorecardDetails?.supplier?.supplierTypeId
                              === SupplierType.NonSRMLite
                            && item.category.name === MetricCategory.Performance)
                        || (bobScorecardDetails?.supplier?.supplierTypeId
                              === SupplierType.NonSRMLite
                            && item.category.name === MetricCategory.Risk)
                        ?
                      <Tab
                        eventKey={item.id}
                        title={
                          <span className="tab-header">
                            {item.category.name}{' '}
                            <span
                              className={
                                this.getCategoryScore(item.id) === 'N/A'
                                  ? 'score-0'
                                  : `score-${Math.floor(
                                      this.getCategoryScore(item.id)
                                    )}`
                              }
                            >
                              {this.getCategoryScore(item.id) !== 'N/A'
                                ? this.getCategoryScore(item.id).toFixed(1)
                                : 'N/A'}
                            </span>
                            {!categories.find(
                              (category) => item.id === category.id
                            ).isCompleted &&
                              !isSamPortalRequest && (
                                <span className="important">!</span>
                              )}
                          </span>
                        }
                      >
                        {(!isSamPortalRequest && showUnsavedModalPopup) ||
                        samShowUnsavedModalPopup ? (
                          // <>confirm?</>
                          <ConfirmDialog
                            onClickYes={this.saveMetrics}
                            onClickNo={this.proceedWithTabChange}
                            showDialog={
                              isSamPortalRequest
                                ? samShowUnsavedModalPopup
                                : showUnsavedModalPopup
                            }
                          />
                        ) : null}

                        {isSamPortalRequest ? (
                          <div>
                            {' '}
                            <ActionPlanContainerPanel
                              ref={(e) => {
                                this.actionPlanContainerRef = e;
                              }}
                            />
                          </div>
                        ) : (
                          <MetricsDetailsPanel
                            isEditable
                            ref={(e) => {
                              this.metricScorecardDetails = e;
                            }}
                          />
                        )}
                      </Tab> : null)
                    )) :
                    (<Tab
                      eventKey={activeKey}
                      title={
                          <span className="tab-header">
                            {'Risk'}{' '}
                          </span>
                        }
                    >
                      {isSamPortalRequest ? (
                        <div>
                          {' '}
                          <ActionPlanContainerPanel
                            ref={(e) => {
                              this.actionPlanContainerRef = e;
                            }}
                          />
                        </div>
                      ) : (
                        <MetricsDetailsPanel
                          isEditable
                          ref={(e) => {
                            this.metricScorecardDetails = e;
                          }}
                        />
                      )}
                     </Tab>)}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.loggedInUser,
    selectedSupplierLeadEntry: state.myActions.selectedSupplierLeadEntry,
    selectedBobEntry: state.myActions.selectedBobEntry,
    myActionsScorecard: state.myActionsScorecard,
    bobScorecardDetails: state.myActionsScorecard.bobScorecardDetails,
    supplierScorecardDetails: state.myActionsScorecard.supplierScorecardDetails,
    samSupplierScorecardDetails:
      state.samActionPlanData.samSupplierScorecardDetails,
    bobScorecardCategoryDetails:
      state.myActionsScorecard.bobScorecardCategoryDetails,
    supplierScorecardCategoryDetails:
      state.myActionsScorecard.supplierScorecardCategoryDetails,
    selectedScorecardCategory:
      state.myActionsScorecard.selectedScorecardCategory,
    samSelectedScorecardCategory:
      state.samActionPlanData.samSelectedScorecardCategory,
    selectedSamEntry: state.samActionPlanData.selectedSamEntry,
    selectedSamSupplier: state.samActionPlanData.selectedSupplier,
    actionPlanDetails: state.samActionPlanData.actionPlanDetails,
  };
}
const mapDispatchToProps = {
  loadBobScorecardDetails: scorecardReduxActions.loadBobScorecardDetails,
  loadSupplierScorecardDetails:
    scorecardReduxActions.loadSupplierScorecardDetails,
  updateSelectedCategory: scorecardReduxActions.updateSelectedCategory,
  loadSamSupplierScorecardDetails:
    samPortalReduxActions.loadSamSupplierScorecardDetails,
  updateSamSelectedCategory: samPortalReduxActions.updateSamSelectedCategory,
  politeAnnouncement: PoliteAnnouncement,
};

export default withErrorHandling(
  connect(mapStateToProps, mapDispatchToProps)(MetricsContainerPanel)
);

MetricsContainerPanel.defaultProps = {
  selectedBobEntry: null,
  selectedSupplierLeadEntry: null,
  bobScorecardDetails: null,
  supplierScorecardDetails: null,
  samSupplierScorecardDetails: null,
  bobScorecardCategoryDetails: null,
  supplierScorecardCategoryDetails: null,
  selectedScorecardCategory: null,
  samSelectedScorecardCategory: null,
  isSamPortalRequest: false,
  selectedSamEntry: null,
  selectedSamSupplier: null,
  actionPlanDetails: null,
  politeAnnouncement: null,
};

MetricsContainerPanel.propTypes = {
  selectedBobEntry: PropTypes.object,
  selectedSupplierLeadEntry: PropTypes.object,
  onError: PropTypes.func.isRequired,
  loadBobScorecardDetails: PropTypes.func.isRequired,
  loadSupplierScorecardDetails: PropTypes.func.isRequired,
  loadSamSupplierScorecardDetails: PropTypes.func.isRequired,
  bobScorecardDetails: PropTypes.object,
  supplierScorecardDetails: PropTypes.object,
  samSupplierScorecardDetails: PropTypes.object,
  bobScorecardCategoryDetails: PropTypes.object,
  supplierScorecardCategoryDetails: PropTypes.object,
  selectedScorecardCategory: PropTypes.object,
  samSelectedScorecardCategory: PropTypes.number,
  updateSelectedCategory: PropTypes.func.isRequired,
  updateSamSelectedCategory: PropTypes.func.isRequired,
  isSamPortalRequest: PropTypes.bool,
  selectedSamEntry: PropTypes.object,
  selectedSamSupplier: PropTypes.object,
  actionPlanDetails: PropTypes.object,
  politeAnnouncement: PropTypes.func,
  loggedInUser: PropTypes.object.isRequired
};
