/* eslint-disable class-methods-use-this */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import * as styles from 'office-ui-fabric-react/lib/components/Modal/examples/Modal.Example.scss';
import { TextField } from '@fluentui/react/lib/TextField';
import { FocusTrapZone } from '@fluentui/react/lib/FocusTrapZone';
import { Label } from '@fluentui/react/lib/Label';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { PrimaryButton, Spinner } from '@fluentui/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ComboBox } from '@fluentui/react/lib/ComboBox';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
  NormalPeoplePicker,
} from 'office-ui-fabric-react/lib/Pickers';
import * as bobactions from '../../../redux/actions/onboardedBOBActions';
import withErrorHandling from '../../hoc/withErrorHandling';
import * as bobOnboardModel from '../../models/model/onboardBOBModel';
import validateUser from '../../../api/GraphService';
import { BOBValidationMessage, Message } from '../../../common/consts/validationMessages';
import * as cadenceActions from '../../../redux/actions/myActionsActions';
import { Logger } from '../../../infra/logging/telemetryLogger';
import * as suppliersActions from '../../../redux/actions/onboardedSuppliersActions';
import { PoliteAnnouncement } from '../../../redux/actions/announcementActions';
import config from '../../../config/appConfig';
import ErrorDiv from '../../hoc/errordiv';

const suggestionProps = {
  suggestionsHeaderText: 'Suggested People',
  mostRecentlyUsedHeaderText: 'Suggested Contacts',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts',
};

export class ManageOnboardedBOB extends Component {
  constructor(props) {
    super(props);
    this.logger = Logger.getInstance();
    this.removeDuplicates = this.removeDuplicates.bind(this);
    this.listContainsPersona = this.listContainsPersona.bind(this);
  }

  state = {
    isValueEdited: false,
    selectedBOBName: null,
    selectedContractId: 0,
    selectedPO: 0,
    selectedAnnualSpend: 0,
    selectedCadence: null,
    selectedStandardCategoryId: 0,
    selectedStandardCategoryName: null,
    selectedStandardCategoryGroupId: 0,
    selectedStandardCategoryGroup: null,
    selectedExecOrgSummary: null,
    selectedExecOrgSummaryId: 0,
    selectedExecOrg: null,
    selectedExecOrgDetailsId: 0,
    selectedExecOrgDetails: null,
    selectedBOBPrimaryOwner: null,
    selectedBOBSecondaryOwner: null,
    primaryOwnerAlias: null,
    secondaryOwnerAlias: null,
    selectedWeight: 0,
    selectedEffectivePeriod: null,
    errorMsgForContractIds: '',
    errorMsgForPurchaseOrders: '',
    errorMsgForAnnualContractValue: '',
    errorMsgForBobPrimaryOwner: '',
    errorMsgForBobSecondaryOwner: '',
    primaryOwnerLoading: false,
    secondaryOwnerLoading: false,
    updatedUnmanagedPerformanceValue: 0,
    selectedPrimaryOwnerDisplayName: null,
    selectedSecondaryOwnerDisplayName: null,
    isInvalidPrimaryOwner: false,
    isInvalidSecondaryOwner: false,
    options: [],
    isSelectedOption: false,
    selectedPrimaryOptions: [],
    selectedSecondaryOptions: [],
    selectedEbcmCritical: false,
    selectedFunctionalUsers: [],
    saveBobLoading: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      selectedBOBName:
        prevState.selectedBOBName !== null
          ? prevState.selectedBOBName
          : nextProps.onboardBOB.bobName,
      selectedContractId:
        prevState.selectedContractId !== 0
          ? prevState.selectedContractId
          : nextProps.onboardBOB.contractIds,
      selectedCadence:
        prevState.selectedCadence !== null
          ? prevState.selectedCadence
          : nextProps.onboardBOB.reportingCadence,
      selectedStandardCategoryGroup:
        prevState.selectedStandardCategoryGroup !== null
          ? prevState.selectedStandardCategoryGroup
          : nextProps.onboardBOB.standardCategoryGroup,
      selectedStandardCategoryId:
        prevState.selectedStandardCategoryId !== 0
          ? prevState.selectedStandardCategoryId
          : nextProps.onboardBOB.standardCategoryId,
      selectedStandardCategoryName:
        prevState.selectedStandardCategoryName !== null
          ? prevState.selectedStandardCategoryName
          : nextProps.onboardBOB.standardCategory,
          selectedExecOrgDetailsId:
        prevState.selectedExecOrgDetailsId !== 0
          ? prevState.selectedExecOrgDetailsId
          : nextProps.onboardBOB.execOrgDetailsId,
      selectedExecOrgDetails:
        prevState.selectedExecOrgDetails !== null
          ? prevState.selectedExecOrgDetails
          : nextProps.onboardBOB.execOrgDetails,
      selectedExecOrg:
        prevState.selectedExecOrg !== null
          ? prevState.selectedExecOrg
          : nextProps.onboardBOB.execOrg,
      selectedExecOrgSummaryId:
        prevState.selectedExecOrgSummaryId !== 0
          ? prevState.selectedExecOrgSummaryId
          : nextProps.onboardBOB.execOrgSummaryId,
      selectedExecOrgSummary:
        prevState.selectedExecOrgSummary !== null
          ? prevState.selectedExecOrgSummary
          : nextProps.onboardBOB.execOrgSummary,
      selectedAnnualSpend:
        prevState.selectedAnnualSpend !== 0
          ? prevState.selectedAnnualSpend
          : nextProps.onboardBOB.annualContractValue,
      selectedPO:
        prevState.selectedPO !== 0
          ? prevState.selectedPO
          : nextProps.onboardBOB.purchaseOrders,
      selectedBOBPrimaryOwner:
        prevState.selectedBOBPrimaryOwner !== null
          ? prevState.selectedBOBPrimaryOwner
          : nextProps.primaryOwner.name,
      selectedBOBSecondaryOwner:
        prevState.selectedBOBSecondaryOwner !== null
          ? prevState.selectedBOBSecondaryOwner
          : nextProps.secondaryOwner.name,
      primaryOwnerAlias:
        prevState.primaryOwnerAlias !== null
          ? prevState.primaryOwnerAlias
          : nextProps.primaryOwner.alias,
      selectedPrimaryOwnerDisplayName: prevState.selectedPrimaryOwnerDisplayName
        ? prevState.selectedPrimaryOwnerDisplayName
        : nextProps.onboardBOB.bobPrimaryOwnerDisplayName,
      secondaryOwnerAlias:
        prevState.secondaryOwnerAlias !== null
          ? prevState.secondaryOwnerAlias
          : nextProps.secondaryOwner.alias,
      selectedSecondaryOwnerDisplayName: prevState.selectedSecondaryOwnerDisplayName
        ? prevState.selectedSecondaryOwnerDisplayName
        : nextProps.onboardBOB.bobSecondaryOwnerDisplayName,
      selectedFunctionalUsers: prevState.selectedFunctionalUsers ?
        prevState.selectedFunctionalUsers
        : nextProps.selectedFunctionalUsers,
      selectedWeight:
        prevState.selectedWeight !== 0
          ? prevState.selectedWeight
          : nextProps.onboardBOB.weight,
      selectedEffectivePeriod:
        prevState.selectedEffectivePeriod !== null
          ? prevState.selectedEffectivePeriod
          : nextProps.onboardBOB.effectivePeriod !== undefined
            ? {
              text: nextProps.onboardBOB?.effectivePeriod,
              key: 1,
            }
            : null,
    };
  }

  componentDidMount() {
    const { selectedCadence } = this.state;
    const {
      loadStdCategoryGroup,
      loadStdCategoryName,
      loadExecOrg,
      loadExecOrgSummary,
      loadExecOrgDetails,
      loadReportingCadenceList,
      loadBOBOwnerList,
      cadence,
      onboardBOB,
      scorecardBOBId,
      supplierProcurementType,
      loadFutureCadence,
      bobFunctionalUsers
    } = this.props;

    // initializing selectedFunctionalUsers with bobFunctionalUsers prop
    this.setState({
      selectedFunctionalUsers: bobFunctionalUsers ? bobFunctionalUsers.map((item) => ({
        bobId: scorecardBOBId,
        userAlias: item.userAlias,
        userDisplayName: item.userDisplayName
      })) : []
    });
    let cadenceTypeId;
    if (onboardBOB && onboardBOB.reportingCadenceId !== undefined) {
      cadenceTypeId = onboardBOB.reportingCadenceId;
    } else {
      cadenceTypeId = cadence.label === 'Quarterly' ? 2 : 1;
    }
    loadStdCategoryGroup(supplierProcurementType);
    loadStdCategoryName(supplierProcurementType);
    loadExecOrgSummary();
    loadExecOrg();
    loadExecOrgDetails();
    loadReportingCadenceList();
    loadBOBOwnerList();
    loadFutureCadence(0, cadenceTypeId, 0, 0, true);
    if (supplierProcurementType === 2) this.calculateBobWeight();
    this.setState({
      selectedCadence:
        selectedCadence !== undefined ? selectedCadence : cadence,
      selectedStandardCategoryGroupId: onboardBOB.standardCategoryGroupId,
      selectedExecOrgSummaryId: onboardBOB.execOrgSummaryId,
      isSelectedOption: scorecardBOBId !== 0,
      selectedEbcmCritical: onboardBOB.ebcmCritical,
    });
    if (document.getElementById('ReportingCadence')) {
      document
        .getElementById('ReportingCadence')
        .getElementsByTagName('input')[0]
        .setAttribute('placeholder', ' Select Reporting Cadence');
    }
    if (document.getElementById('categoryGroup')) {
      document
        .getElementById('categoryGroup')
        .getElementsByTagName('input')[0]
        .setAttribute('placeholder', ' Select Category Group');
    }
    if (document.getElementById('execOrg')) {
      document
        .getElementById('execOrg')
        .getElementsByTagName('input')[0]
        .setAttribute('placeholder', ' Select Exec Org');
    }
    if (document.getElementById('execOrgSummary')) {
      document
        .getElementById('execOrgSummary')
        .getElementsByTagName('input')[0]
        .setAttribute('placeholder', ' Select Exec Org Summary');
    }
    if (document.getElementById('CategoryDetails')) {
      document
        .getElementById('CategoryDetails')
        .getElementsByTagName('input')[0]
        .setAttribute('placeholder', ' Select Category Name');
    }
    if (document.getElementById('execOrgDetails')) {
      document
        .getElementById('execOrgDetails')
        .getElementsByTagName('input')[0]
        .setAttribute('placeholder', ' Select Exec Details');
    }
    if (document.getElementById('EffectivePeriods')) {
      document
        .getElementById('EffectivePeriods')
        .getElementsByTagName('input')[0]
        .setAttribute('placeholder', ' Select Effective Period');
    }
  }


  onChangeBOBName = (event, item) => {
    if (item !== undefined) {
      const name = item;
      this.setState({
        selectedBOBName: name,
        isValueEdited: true,
      });
    }
  };

  onChangeStdCategoryGroup = (event, item) => {
    if (item !== undefined) {
      this.setState({
        selectedStandardCategoryGroup: item,
        selectedStandardCategoryGroupId: item.key,
        isValueEdited: true,
        selectedStandardCategoryName: '',
        selectedStandardCategoryId: undefined,
      });
    }
  };

  onChangeStdCategoryName = (event, item) => {
    if (item !== undefined) {
      this.setState({
        selectedStandardCategoryName: item,
        selectedStandardCategoryId: item.key,
        isValueEdited: true,
      });
    }
  };

  onChangeExecOrg = (event, item) => {
    if (item !== undefined) {
      const { execOrgDetails } = this.props;

      const firstExecOrgDetails = execOrgDetails.filter(
        (menu) => menu.executiveOrgId === item.key
       ).map((eachExecOrgDetails) => ({
            value: eachExecOrgDetails.executiveOrgDetailsId,
            label: eachExecOrgDetails.executiveOrgDetailsValue,
          }))[0];


      this.setState({
        selectedExecOrg: item,
        isValueEdited: true,
        selectedExecOrgDetails: firstExecOrgDetails,
        selectedExecOrgDetailsId: firstExecOrgDetails.value,


      });
    }
  };

  onChangeExecOrgSummary = (event, item) => {
    if (item !== undefined) {
      this.setState({
        selectedExecOrgSummary: item,
        selectedExecOrgSummaryId: item.key,
        isValueEdited: true,
        selectedExecOrg: '',
      });
    }
  };

  onChangeExecOrgDetails = (item) => {
    if (item !== undefined) {
      this.setState({
        selectedExecOrgDetails: item,
        selectedExecOrgDetailsId: item.value,
        isValueEdited: true,
      });
    }
  };

  onChangeContractId = (event, item) => {
    if (item !== undefined && item !== ',') {
      const ContractIds = item;
      const value = this.isValidEntry(event);
      if (value && ContractIds.split(',').length <= 5) {
        this.setState({
          selectedContractId: item,
          errorMsgForContractIds: '',
          isValueEdited: true,
        });
      } else if (value === true && ContractIds.split(',').length >= 5) {
        this.setState({
          errorMsgForContractIds: BOBValidationMessage.Max5ContrctIdAllowed,
          isValueEdited: true,
        });
      } else {
        this.setState({
          errorMsgForContractIds:
            BOBValidationMessage.OnlyNumbersAndCommaAllowed,
          isValueEdited: true,
        });
      }
    } else {
      this.setState({
        errorMsgForContractIds: BOBValidationMessage.NumberFirst,
      });
    }
  };

  onChangePO = (event, item) => {
    if (item !== undefined && item !== ',') {
      const purchaseOrders = item;
      const value = this.isValidEntry(event);
      if (value === true && purchaseOrders.split(',').length <= 5) {
        this.setState({
          selectedPO: item,
          errorMsgForPurchaseOrders: '',
          isValueEdited: true,
        });
      } else if (value === true && purchaseOrders.split(',').length >= 5) {
        this.setState({
          errorMsgForPurchaseOrders:
            BOBValidationMessage.Max5PurchaseOrdersAllowed,
          isValueEdited: true,
        });
      } else {
        this.setState({
          errorMsgForPurchaseOrders:
            BOBValidationMessage.OnlyNumbersAndCommaAllowed,
          isValueEdited: true,
        });
      }
    } else {
      this.setState({
        errorMsgForPurchaseOrders: BOBValidationMessage.NumberFirst,
        isValueEdited: true,
      });
    }
  };

  onSearchBOBPrimaryOwner = (query) => {
    this.setState({
      primaryOwnerLoading: true,
      isInvalidPrimaryOwner: false,
      errorMsgForBobPrimaryOwner: '',
      selectedPrimaryOptions: [],
      isSelectedOption: false,
    });
    const { selectedBOBPrimaryOwner } = this.state;
    if (query !== selectedBOBPrimaryOwner) {
      if (query === '') {
        this.setState({
          selectedBOBPrimaryOwner: undefined,
          isValueEdited: true,
          primaryOwnerLoading: false,
          selectedPrimaryOwnerDisplayName: null,
        });
      }
    }
    validateUser(query).then((json) =>
      this.setState({
        primaryOwnerLoading: false,
        options:
          json &&
          json.map((data) => ({
            id: data.userPrincipalName,
            label: `${data.displayName} (${
              data.userPrincipalName.split('@')[0]
              })`,
            displayName: data.displayName,
            alias: data.userPrincipalName.split('@')[0],
          })),
      }));
  };

  onSearchBOBSecondaryOwner = (query) => {
    this.setState({
      secondaryOwnerLoading: true,
      isInvalidSecondaryOwner: false,
      errorMsgForBobSecondaryOwner: '',
      isSelectedOption: false,
      selectedSecondaryOptions: [],
    });
    const { selectedBOBSecondaryOwner } = this.state;
    if (query !== selectedBOBSecondaryOwner) {
      if (query === '') {
        this.setState({
          selectedBOBSecondaryOwner: undefined,
          isValueEdited: true,
          secondaryOwnerLoading: false,
          selectedSecondaryOwnerDisplayName: null,
        });
      }
    }
    validateUser(query).then((json) =>
      this.setState({
        secondaryOwnerLoading: false,
        options:
          json &&
          json.map((data) => ({
            id: data.userPrincipalName,
            label: `${data.displayName} (${
              data.userPrincipalName.split('@')[0]
              })`,
            displayName: data.displayName,
            alias: data.userPrincipalName.split('@')[0]
          })),
      }));
  };

  onChangeReportingCadence = (event, item) => {
    if (item !== undefined) {
      this.setState({
        selectedCadence: item,
        isValueEdited: true,
        selectedEffectivePeriod: '',
      });
      const { loadFutureCadence } = this.props;
      if (item.text === 'Quarterly') {
        loadFutureCadence(0, 2, 0, 0, true);
      } else {
        loadFutureCadence(0, 1, 0, 0, true);
      }
      this.setState({
        isValueEdited: true
      });
    }
  };

  onChangeAnnualContract = (event, item) => {
    const { onboardSupplier, scorecardBOBId } = this.props;
    const { onboardBOB, BOBList, supplierProcurementType } = this.props;
    let updatedUnmanagedPerformanceSpendValue;
    if (item !== undefined) {
      const annualContractValue =
        item.indexOf('$') === 0 ? item.split('$')[1].replace(/,/g, '') : item;
      const parsedAnnualContractValue = parseInt(annualContractValue, 10);
      const value = this.isPositiveNumberEntry(event);
      let weight = 0;
      let prevAnnualContractValue = 0;
      if (BOBList && BOBList.length === 0) {
        weight = 100;
      } else if (supplierProcurementType !== 2) {
        let allBOBsum = 0;
        allBOBsum = BOBList.reduce((previousValue, currentValue) => ({
          annualContractValue:
            previousValue.annualContractValue +
            currentValue.annualContractValue,
        })).annualContractValue;

        if (scorecardBOBId !== 0) {
          prevAnnualContractValue = BOBList.filter(
            (data) => data.bobId === scorecardBOBId
          )[0].annualContractValue;
        }
        weight = (
          (annualContractValue * 100) /
          (allBOBsum - prevAnnualContractValue + parsedAnnualContractValue)
        ).toFixed(2);
      }

      if (
        onboardBOB.annualContractValue > parsedAnnualContractValue &&
        parsedAnnualContractValue === 0
      ) {
        updatedUnmanagedPerformanceSpendValue = -(
          Math.abs(onboardSupplier.unmanagedPerformanceSpend) -
          Math.abs(onboardBOB.annualContractValue)
        );
      }
      if (onboardBOB.annualContractValue > parsedAnnualContractValue) {
        updatedUnmanagedPerformanceSpendValue = -(
          Math.abs(onboardSupplier.unmanagedPerformanceSpend) -
          Math.abs(onboardBOB.annualContractValue - parsedAnnualContractValue)
        );
      } else {
        updatedUnmanagedPerformanceSpendValue =
          onboardSupplier.unmanagedPerformanceSpend -
          Math.abs(parsedAnnualContractValue - onboardBOB.annualContractValue);
      }

      if (value === true && annualContractValue.split('.').length <= 2) {
        if (scorecardBOBId !== 0) {
          this.setState({
            updatedUnmanagedPerformanceValue: updatedUnmanagedPerformanceSpendValue,
            selectedAnnualSpend: parsedAnnualContractValue,
            selectedWeight: weight,
            errorMsgForAnnualContractValue: '',
            isValueEdited: true,
          });
        } else {
          this.setState({
            updatedUnmanagedPerformanceValue:
              onboardSupplier.unmanagedPerformanceSpend -
              parsedAnnualContractValue,
            selectedAnnualSpend: parsedAnnualContractValue,
            selectedWeight: weight,
            errorMsgForAnnualContractValue: '',
            isValueEdited: true,
          });
        }
      } else if (value === true && annualContractValue.split('.').length > 2) {
        this.setState({
          errorMsgForAnnualContractValue:
            BOBValidationMessage.DecimalNumberAllowed,
          isValueEdited: true,
        });
      } else {
        this.setState({
          errorMsgForAnnualContractValue:
            BOBValidationMessage.DecimalNumberAndDotAllowed,
          isValueEdited: true,
        });
      }
    }
  };

  async onAddBOB(event) {
    const {
      selectedBOBName,
      selectedContractId,
      selectedCadence,
      selectedAnnualSpend,
      selectedBOBPrimaryOwner,
      selectedBOBSecondaryOwner,
      selectedPO,
      selectedWeight,
      selectedStandardCategoryId,
      selectedExecOrgDetailsId,
      primaryOwnerAlias,
      secondaryOwnerAlias,
      selectedPrimaryOwnerDisplayName,
      selectedSecondaryOwnerDisplayName,
      updatedUnmanagedPerformanceValue,
      selectedEffectivePeriod,
      selectedEbcmCritical,
      selectedFunctionalUsers,
    } = this.state;
    event.preventDefault();
    const {
      scorecardSupplierId,
      addOnboardBOB,
      updateSupplierOnboardedBOB,
      loggedInUser,
      cadence,
      scorecardBOBId,
      updateOnboardedBOB,
      onboardSupplier,
      supplierProcurementType,
      politeAnnouncement,
    } = this.props;

    let onboardSupplierToBeUpdate = { ...onboardSupplier };
    if (updatedUnmanagedPerformanceValue !== 0) {
      onboardSupplierToBeUpdate = {
        ...onboardSupplierToBeUpdate,
        unmanagedPerformanceSpend: updatedUnmanagedPerformanceValue,
      };
    }
    let ContractIds = selectedContractId || [0];
    let purchaseOrders = selectedPO || [0];
    let Cadence = cadence;
    if (
      this.isNullOrUndefined(ContractIds) &&
      this.isNullOrUndefined(purchaseOrders)
    ) {
      ContractIds = selectedContractId;
      purchaseOrders = selectedPO;
    } else if (!Array.isArray(ContractIds) && !Array.isArray(purchaseOrders)) {
      ContractIds = selectedContractId ? selectedContractId.split(',') : [0];
      purchaseOrders = selectedPO ? selectedPO.split(',') : [0];
    } else if (Array.isArray(ContractIds) && Array.isArray(purchaseOrders)) {
      ContractIds = selectedContractId;
      purchaseOrders = selectedPO;
    } else if (Array.isArray(purchaseOrders)) {
      ContractIds = selectedContractId ? selectedContractId.split(',') : [0];
    } else {
      purchaseOrders = selectedPO ? selectedPO.split(',') : [0];
    }

    if (selectedCadence !== undefined) {
      Cadence = selectedCadence.text;
    }

    const newOnboardedBOB = new bobOnboardModel.OnboardedBOBModel(
      selectedBOBName,
      supplierProcurementType,
      selectedFunctionalUsers,
      ContractIds || [0],
      Cadence,
      selectedStandardCategoryId,
      selectedExecOrgDetailsId || config.DirectSourcingExecOrg.executiveOrgDetailsId,
      selectedAnnualSpend || 0,
      purchaseOrders || [0],
      primaryOwnerAlias,
      selectedPrimaryOwnerDisplayName,
      secondaryOwnerAlias,
      selectedSecondaryOwnerDisplayName,
      selectedEffectivePeriod?.text,
      loggedInUser.alias.split('@')[0],
      loggedInUser.alias.split('@')[0],
      selectedEbcmCritical,
      1
    );

    if (
      !this.isNullOrUndefined(selectedBOBName) &&
      !this.isNullOrUndefined(selectedEffectivePeriod) &&
      selectedWeight !== undefined &&
      !this.isNullOrUndefined(selectedBOBSecondaryOwner) &&
      !this.isNullOrUndefined(selectedBOBPrimaryOwner) &&
      selectedStandardCategoryId !== undefined
    ) {
      if (scorecardBOBId === 0) {
        try {
          this.setState({
            saveBobLoading: true
          });
          politeAnnouncement('Saving data');
          await addOnboardBOB(newOnboardedBOB, scorecardSupplierId);
          updateSupplierOnboardedBOB({ ...onboardSupplierToBeUpdate });
          this.setState({
            saveBobLoading: false
          });
        } catch (error) {
          this.setState({
            saveBobLoading: false
          });
          this.logger.logException(error);
        }
      } else {
        try {
          this.setState({
            saveBobLoading: true
          });
          politeAnnouncement('Saving data');
          await updateOnboardedBOB(
            newOnboardedBOB,
            scorecardSupplierId,
            scorecardBOBId
          );
          updateSupplierOnboardedBOB({ ...onboardSupplierToBeUpdate });
          this.setState({
            saveBobLoading: false
          });
        } catch (error) {
          this.setState({
            saveBobLoading: false
          });
          this.logger.logException(error);
        }
      }
    }
  }

  onClearData = () => {
    const { scorecardBOBId, cadence, onboardBOB } = this.props;
    if (!scorecardBOBId) {
      this.secondaryTypeahead.getInstance().clear();
      this.primaryTypeahead.getInstance().clear();
    }

    this.setState({
      selectedPrimaryOptions: scorecardBOBId
        ? [onboardBOB.bobPrimaryOwnerDisplayName]
        : [],
      selectedSecondaryOptions: scorecardBOBId
        ? [onboardBOB.bobSecondaryOwnerDisplayName]
        : [],
      isInvalidPrimaryOwner: false,
      isInvalidSecondaryOwner: false,
      isValueEdited: false,
      selectedBOBName: null,
      selectedContractId: 0,
      selectedPO: 0,
      selectedAnnualSpend: 0,
      selectedCadence: scorecardBOBId === 0 ? cadence : null,
      selectedStandardCategoryName: null,
      selectedStandardCategoryGroup: null,
      selectedExecOrg: null,
      selectedExecOrgDetails: null,
      selectedExecOrgSummary: null,
      selectedBOBPrimaryOwner: null,
      selectedBOBSecondaryOwner: null,
      selectedWeight: 0,
      selectedEffectivePeriod: null,
      errorMsgForContractIds: '',
      errorMsgForPurchaseOrders: '',
      errorMsgForAnnualContractValue: '',
      errorMsgForBobPrimaryOwner: '',
      errorMsgForBobSecondaryOwner: '',
      primaryOwnerLoading: false,
      secondaryOwnerLoading: false,
      selectedEbcmCritical: false,
    });
  };

  onChangePrimaryOwner = (selectedUser) => {
    if (!selectedUser.length) {
      this.primaryTypeahead.getInstance().clear();
    }
    const ownerAlias = selectedUser.length ? selectedUser[0] : null;

    this.setState({
      selectedPrimaryOptions:
        ownerAlias && ownerAlias.label ? [ownerAlias.label] : [],
      selectedBOBPrimaryOwner:
        ownerAlias && ownerAlias.label ? ownerAlias.label : null,
      primaryOwnerAlias:
        ownerAlias && ownerAlias.alias ? ownerAlias.alias : null,
      selectedPrimaryOwnerDisplayName:
        ownerAlias && ownerAlias.displayName ? ownerAlias.displayName : null,
      isValueEdited: true,
      isSelectedOption: selectedUser.length > 0,
      errorMsgForBobPrimaryOwner: '',
      isInvalidPrimaryOwner: false,
    });
  };

  onBlurPrimaryOwner = () => {
    const { isSelectedOption, selectedBOBPrimaryOwner } = this.state;
    if (!isSelectedOption) {
      this.primaryTypeahead.getInstance().clear();
    }

    this.setState({
      selectedBOBPrimaryOwner: isSelectedOption ? selectedBOBPrimaryOwner : '',
      isInvalidPrimaryOwner: !isSelectedOption,
      errorMsgForBobPrimaryOwner: isSelectedOption ? '' : 'Invalid user',
      isValueEdited: true
    });
  };

  onChangeBOBSecondaryOwner = (selectedUser) => {
    if (!selectedUser.length) {
      this.secondaryTypeahead.getInstance().clear();
    }
    const ownerAlias = selectedUser.length ? selectedUser[0] : null;

    this.setState({
      selectedSecondaryOptions:
        ownerAlias && ownerAlias.label ? [ownerAlias.label] : [],
      selectedBOBSecondaryOwner:
        ownerAlias && ownerAlias.label ? ownerAlias.label : null,
      secondaryOwnerAlias:
        ownerAlias && ownerAlias.alias ? ownerAlias.alias : null,
      selectedSecondaryOwnerDisplayName:
        ownerAlias && ownerAlias.displayName ? ownerAlias.displayName : null,
      isValueEdited: true,
      isSelectedOption: selectedUser.length > 0,
      errorMsgForBobSecondaryOwner: '',
      isInvalidSecondaryOwner: false,
    });
  };

  onBlurBOBSecondaryOwner = () => {
    const { isSelectedOption, selectedBOBSecondaryOwner } = this.state;
    if (!isSelectedOption) {
      this.secondaryTypeahead.getInstance().clear();
    }

    this.setState({
      selectedBOBSecondaryOwner: isSelectedOption
        ? selectedBOBSecondaryOwner
        : '',
      isInvalidSecondaryOwner: !isSelectedOption,
      errorMsgForBobSecondaryOwner: isSelectedOption ? '' : 'Invalid user',
      isValueEdited: true
    });
  };

  onChangeEffectivePeriod = (item) => {
    if (item !== undefined) {
      this.setState({
        selectedEffectivePeriod: item,
        isValueEdited: true
      });
    }
  };


  onItemSelected = (item) => {
    const { selectedFunctionalUsers } = this.state;
    const { scorecardBOBId } = this.props;

    const newUser = {
      bobId: scorecardBOBId,
      userAlias: item.tertiaryText,
      userDisplayName: item.text
    };

    selectedFunctionalUsers.push(newUser);
    return item;
  }

  async onFilterChanged(filterText, currentPersonas, limitResults) {
    if (filterText) {
      let filteredPersonas = [];
      let userList = [];
      filteredPersonas = await validateUser(filterText);
      filteredPersonas = limitResults
        ? filteredPersonas.splice(0, limitResults)
        : filteredPersonas;

      filteredPersonas = filteredPersonas.map((persona) => ({
        key: persona.id,
        text: persona.displayName,
        secondaryText: persona.jobTitle,
        tertiaryText: persona.userPrincipalName.split('@')[0],
        isValid: true,
      }));
      if (currentPersonas) {
        userList = this.removeDuplicates(filteredPersonas, currentPersonas);
      }
      return userList;
    }
    return [];
  }

  onChangeItemList(itemList) {
    const { scorecardBOBId } = this.props;

    const FunctionalUsers = itemList.map((item) => ({
      bobId: scorecardBOBId,
      userAlias: item.tertiaryText,
      userDisplayName: item.text
    }));

    this.setState({
      selectedFunctionalUsers: FunctionalUsers,
      isValueEdited: true
    });
  }

  getTextFromItem(persona) {
    return persona.text;
  }


  isPositiveNumberEntry = event => {
    const validator = new RegExp('^[0-9.]*$');
    const value =
      event.target.value.indexOf('$') === 0
        ? event.target.value.split('$')[1]
        : event.target.value;
    const runner = validator.test(value.replace(/,/g, ''));
    return runner;
  };


  isValidEntry = (event) => {
    const validator = new RegExp('^[0-9,]*$');
    const runner = validator.test(event.target.value);
    return runner;
  };

  isNullOrUndefined = (variable) => {
    if (variable === null || variable === '' || variable === undefined) {
      return true;
    }
    return false;
  };


  onChangeEffectivePeriod = (event, item) => {
    if (item !== undefined) {
      this.setState({
        selectedEffectivePeriod: item,
        isValueEdited: true,
      });
    }
  };


  onToggleEbcmCritical = (event) => {
    this.setState({
      selectedEbcmCritical: event.target.checked,
      isValueEdited: true,
    });
  };

  doesTextStartWith(text, filterText) {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  }

  removeDuplicates(personas, possibleDupes) {
    return personas.filter(
      (persona) => !this.listContainsPersona(persona, possibleDupes)
    );
  }

  listContainsPersona(persona, personas) {
    return !!personas.find((item) => item.text === persona.text);
  }

  calculateBobWeight() {
    const { BOBList, scorecardBOBId } = this.props;
    this.setState({
      selectedContractId: null,
      selectedPO: null,
      selectedAnnualSpend: null,
    });

    let weight = 0;
    if (BOBList) {
      if (BOBList.length === 0) {
        weight = 100;
      } else if (scorecardBOBId === 0) {
        weight = (100 / (BOBList.length + 1)).toFixed(2);
      } else {
        weight = (100 / BOBList.length).toFixed(2);
      }
    }
    this.setState({
      selectedWeight: weight,
    });
  }

  render() {
    const {
      selectedBOBName,
      selectedContractId,
      selectedCadence,
      selectedStandardCategoryGroup,
      selectedExecOrgDetails,
      selectedStandardCategoryName,
      selectedExecOrg,
      selectedExecOrgSummary,
      selectedAnnualSpend,
      selectedBOBPrimaryOwner,
      selectedBOBSecondaryOwner,
      selectedPO,
      selectedWeight,
      selectedStandardCategoryGroupId,
      selectedExecOrgSummaryId,
      errorMsgForContractIds,
      errorMsgForAnnualContractValue,
      errorMsgForPurchaseOrders,
      selectedStandardCategoryId,
      errorMsgForBobPrimaryOwner,
      errorMsgForBobSecondaryOwner,
      isValueEdited,
      primaryOwnerLoading,
      secondaryOwnerLoading,
      isInvalidPrimaryOwner,
      isInvalidSecondaryOwner,
      options,
      selectedPrimaryOptions,
      selectedSecondaryOptions,
      selectedEffectivePeriod,
      selectedEbcmCritical,
      selectedFunctionalUsers,
      saveBobLoading
    } = this.state;

    const {
      reportingCadenceList,
      stdCategoryGroups,
      execOrgs,
      execOrgSummary,
      stdCategoryNames,
      scorecardBOBId,
      uvpAnnualSpend,
      supplierProcurementType,
      bobFunctionalUsers,
      effectivePeriod,
      isSuccessSavingData,
      isErrorSavingData,
      displayMessage,
      onClosePopup,
      loggedInUser,
      cadence
    } = this.props;

    const comboBoxStyles = {
      container: {
          label: { fontSize: '14px !important', margin: '0 10px' }
        },
      optionsContainerWrapper: { maxHeight: '350px' },
      root: {
        '::after': {
        borderRadius: '4px'
        }
      }
    };

    const currentFunctionalUsers = bobFunctionalUsers
      ? bobFunctionalUsers.map((user) => ({
        key: Math.random(),
        text: user.userDisplayName,
        tertiaryText: user.userAlias,
        isValid: true,
      }))
      : null;

    const isIndirectProcurement = supplierProcurementType === 1;
    const areCommonFieldsUndefined = this.isNullOrUndefined(selectedBOBName) ||
      this.isNullOrUndefined(selectedEffectivePeriod) ||
      selectedCadence === undefined ||
      this.isNullOrUndefined(selectedBOBSecondaryOwner) ||
      this.isNullOrUndefined(selectedBOBPrimaryOwner) ||
      this.isNullOrUndefined(selectedStandardCategoryId) ||
      this.isNullOrUndefined(
        selectedStandardCategoryGroup
      );

    const areIndirectProcurementFieldsUndefined = this.isNullOrUndefined(selectedAnnualSpend) ||
      this.isNullOrUndefined(selectedContractId) ||
      this.isNullOrUndefined(selectedPO) ||
      this.isNullOrUndefined(selectedExecOrgDetails) ||
      this.isNullOrUndefined(selectedExecOrg) ||
      this.isNullOrUndefined(selectedExecOrgSummary);

    const areDirectProcurementFieldsUndefined = !selectedFunctionalUsers ||
      selectedFunctionalUsers.length === 0;
    let isEditDisabled = false;
    if (scorecardBOBId !== undefined && scorecardBOBId !== 0) {
      // Edit bob scenario - only admin is allowed to edit cadence and effective period
      isEditDisabled = !loggedInUser.isAdmin;
    } else if (scorecardBOBId !== undefined && scorecardBOBId === 0) {
      // Add bob scenario - bob owner, supplier lead and admin are
      // allowed to select cadence and effective period
      isEditDisabled = !(loggedInUser.isAdmin || loggedInUser.isSupplierLead
        || loggedInUser.isBobOwner);
    }

    return (
      <div>
        {isSuccessSavingData ? onClosePopup() : null}

        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show
          onHide={onClosePopup}
          animation={false}
          dialogClassName="container"
        >
          <FocusTrapZone>

            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <div className="header ml15 mt25">
                  <div className="row">
                    <div className="col-md-12">
                      <h1 className="mt25 ml48 fs18 modal-title">
                        {scorecardBOBId === 0
                          ? 'Add Book of Business'
                          : 'Edit Book of Business'}
                      </h1>
                    </div>
                    <div className="col-md-12 requiredtext">
                      <span>All fields are required</span>
                    </div>
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={styles.body}>
                <div id="ManageonboardedBOB" className="containerBOB">
                  <form className="manageonboardedBOBForm">
                    <div>
                      <Label htmlFor="bobName">BOOK OF BUSINESS NAME</Label>
                      <TextField
                        autoFocus
                        onChange={this.onChangeBOBName}
                        value={selectedBOBName}
                        placeholder="Enter Book of Business Name"
                        ariaLabel="Book of Business Name"
                        validateOnLoad={false}
                        id="bobName"
                      />
                    </div>
                    {supplierProcurementType !== 2 && (
                      <div>
                        <Label htmlFor="ContractId">CONTRACT ID</Label>
                        <TooltipHost content={errorMsgForContractIds}>
                          <TextField
                            placeholder="Enter Contract ID"
                            onChange={this.onChangeContractId}
                            value={selectedContractId}
                            ariaLabel="Contract ID"
                            errorMessage={errorMsgForContractIds ? ' ' : ''}
                            id="ContractId"
                          />
                        </TooltipHost>
                      </div>
                    )}

                    <div>
                      <ComboBox
                        className="bootstrap-dropdown"
                        placeholder="Select Reporting Cadence"
                        label="REPORTING CADENCE"
                        useAriaLabelAsText
                        allowFreeInput
                        useComboBoxAsMenuWidth
                        autoComplete="on"
                        iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                        options={
                          reportingCadenceList &&
                          reportingCadenceList.filter(eachCadence => (cadence.value === 'Monthly' ? eachCadence : eachCadence.cadenceTypeName === cadence.value)).map((eachCadence) => ({
                            key: eachCadence.cadenceId,
                            text: eachCadence.cadenceTypeName,
                            selected:
                              selectedCadence === eachCadence.cadenceTypeName,
                          }))
                        }
                        aria-label="ReportingCadence"
                        title="ReportingCadence"
                        onChange={this.onChangeReportingCadence}
                        selectedKey={selectedCadence?.key}
                        comboBoxOptionStyles={{ maxHeight: '100px' }}
                        calloutProps={{ doNotLayer: true }}
                        styles={comboBoxStyles}
                        id="ReportingCadence"
                        disabled={isEditDisabled}
                      />
                      {cadence.value === 'Quarterly' &&
                      <Label id="SupplierQuarterlyCadenceHelpText" aria-live="assertive">
                        {
                          Message.SupplierQuarterlyCadenceHelpText
                        }
                      </Label>}
                    </div>

                    <div className="categoryGroupGridCell">
                      <div>
                        <ComboBox
                          selectedKey={
                            this.isNullOrUndefined(selectedStandardCategoryGroup)
                              ? null
                              : selectedStandardCategoryGroup?.key
                          }
                          label="CATEGORY GROUP"
                          placeholder="Select Category Group"
                          useAriaLabelAsText
                          allowFreeInput
                          useComboBoxAsMenuWidth
                          autoComplete="on"
                          iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                          options={
                            stdCategoryGroups &&
                            stdCategoryGroups.map((eachStdCategoryGroup) => ({
                              key: eachStdCategoryGroup.standardCategoryGroupId,
                              text:
                                eachStdCategoryGroup.standardCategoryGroupName,
                            }))
                          }
                          comboBoxOptionStyles={{ maxHeight: '280px' }}
                          calloutProps={{ doNotLayer: true }}
                          onChange={this.onChangeStdCategoryGroup}
                          aria-label="categoryGroup"
                          title="categoryGroup"
                          styles={comboBoxStyles}
                          id="categoryGroup"
                        />
                      </div>
                      <div>
                        <ComboBox
                          selectedKey={
                            this.isNullOrUndefined(selectedStandardCategoryName)
                              ? null
                              : selectedStandardCategoryName?.key
                          }
                          label="CATEGORY DETAILS"
                          placeholder="Select Category Name"
                          useAriaLabelAsText
                          allowFreeInput
                          useComboBoxAsMenuWidth
                          autoComplete="on"
                          iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                          options={stdCategoryNames
                            .filter(
                              (menu) =>
                                menu.standardCategoryGroupId ===
                                selectedStandardCategoryGroupId
                            )
                            .map((stdCategories) => ({
                              key: stdCategories.standardCategoryId,
                              text: stdCategories.standardCategoryName,
                            }))}
                          onChange={this.onChangeStdCategoryName}
                          aria-label="CategoryDetails"
                          title="CategoryDetails"
                          styles={comboBoxStyles}
                          comboBoxOptionStyles={{ maxHeight: '280px' }}
                          calloutProps={{ doNotLayer: true }}
                          id="CategoryDetails"
                        />
                      </div>
                    </div>
                    {supplierProcurementType !== 2 && (
                      <div className="execOrgGridCell">
                        <div>
                          <ComboBox
                            selectedKey={
                              this.isNullOrUndefined(selectedExecOrgSummary)
                                ? null
                                : selectedExecOrgSummary?.key
                            }
                            label="EXEC ORG SUMMARY"
                            placeholder="Select Exec Org Summary"
                            useAriaLabelAsText
                            allowFreeInput
                            useComboBoxAsMenuWidth
                            autoComplete="on"
                            iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                            options={
                              execOrgSummary &&
                              execOrgSummary.map((eachExecOrgSummary) => ({
                                key: eachExecOrgSummary.executiveOrgSummaryId,
                                text: eachExecOrgSummary.executiveOrgSummaryName,
                              }))
                            }
                            onChange={this.onChangeExecOrgSummary}
                            aria-label="execOrgSummary"
                            title="execOrgSummary"
                            styles={comboBoxStyles}
                            comboBoxOptionStyles={{ maxHeight: '280px' }}
                            calloutProps={{ doNotLayer: true }}
                            id="execOrgSummary"
                          />
                        </div>
                        <div>
                          <ComboBox
                            selectedKey={
                              this.isNullOrUndefined(selectedExecOrg)
                                ? null
                                : selectedExecOrg?.key
                            }
                            label="EXEC ORG"
                            placeholder="Select Exec Org"
                            useAriaLabelAsText
                            allowFreeInput
                            useComboBoxAsMenuWidth
                            autoComplete="on"
                            iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                            options={execOrgs
                              .filter(
                                (menu) => menu.executiveOrgParentId === selectedExecOrgSummaryId
                              )
                              .map((eachExecOrg) => ({
                                key: eachExecOrg.executiveOrgId,
                                text: eachExecOrg.executiveOrgName,
                              }))}
                            onChange={this.onChangeExecOrg}
                            aria-label="execOrg"
                            title="execOrg"
                            styles={comboBoxStyles}
                            comboBoxOptionStyles={{ maxHeight: '280px' }}
                            calloutProps={{ doNotLayer: true }}
                            id="execOrg"
                          />
                        </div>

                      </div>
                    )}
                    {supplierProcurementType !== 2 && (
                      <div>
                        <Label htmlFor="annualContractValue">
                          ANNUAL CONTRACT VALUE(USD)
                        </Label>
                        <TooltipHost content={errorMsgForAnnualContractValue}>
                          <TextField
                            placeholder="Enter Annual Contract Value"
                            onChange={this.onChangeAnnualContract}
                            value={
                              selectedAnnualSpend
                                ? '$'.concat(
                                  selectedAnnualSpend
                                    .toLocaleString(navigator.language, {
                                      minimumFractionDigits: 0,
                                    })
                                    .toString()
                                )
                                : selectedAnnualSpend
                            }
                            ariaLabel="Annual Contract Value"
                            errorMessage={
                              errorMsgForAnnualContractValue ? ' ' : ''
                            }
                            id="annualContractValue"
                          />
                        </TooltipHost>
                      </div>
                    )}
                    {supplierProcurementType !== 2 && (
                      <div>
                        <Label htmlFor="po">PO#</Label>
                        <TooltipHost content={errorMsgForPurchaseOrders}>
                          <TextField
                            placeholder="Enter PO#"
                            onChange={this.onChangePO}
                            value={selectedPO}
                            ariaLabel="PO Number"
                            errorMessage={errorMsgForPurchaseOrders ? ' ' : ''}
                            id="po"
                          />
                        </TooltipHost>
                      </div>
                    )}
                    <div>
                      <Label htmlFor="bobPrimaryOwner">BOB PRIMARY OWNER</Label>
                      <TooltipHost content={errorMsgForBobPrimaryOwner}>
                        <AsyncTypeahead
                          isLoading={primaryOwnerLoading}
                          placeholder="Select BOB Owner"
                          emptyLabel="No Records found"
                          delay={300}
                          id="bobPrimaryOwner"
                          minLength={2}
                          onSearch={this.onSearchBOBPrimaryOwner}
                          filterBy={['id']}
                          options={options}
                          onBlur={this.onBlurPrimaryOwner}
                          onChange={this.onChangePrimaryOwner}
                          defaultInputValue={selectedBOBPrimaryOwner}
                          isInvalid={isInvalidPrimaryOwner}
                          maxHeight="150px"
                          searchText="Searching..."
                          selected={selectedPrimaryOptions}
                          // eslint-disable-next-line no-return-assign
                          ref={(primaryTypeahead) =>
                            (this.primaryTypeahead = primaryTypeahead)
                          }
                          inputProps={{
                            className: 'typeahead-css',
                            autoComplete: 'name',
                            id: 'bobPrimaryOwner'
                          }}
                        />
                      </TooltipHost>
                      {isInvalidPrimaryOwner && <span style={{ color: '#d13438', fontSize: '12px' }}>Required, please select a valid BOB Primary owner</span>}
                    </div>
                    <div>
                      <Label htmlFor="bobSecondaryOwner">
                        BOB SECONDARY OWNER
                      </Label>
                      <TooltipHost content={errorMsgForBobSecondaryOwner}>
                        <AsyncTypeahead
                          isLoading={secondaryOwnerLoading}
                          placeholder="Select BOB Owner"
                          emptyLabel="No Records found"
                          id="bobSecondaryOwner"
                          delay={300}
                          minLength={2}
                          onSearch={this.onSearchBOBSecondaryOwner}
                          filterBy={['id']}
                          options={options}
                          onBlur={this.onBlurBOBSecondaryOwner}
                          onChange={this.onChangeBOBSecondaryOwner}
                          defaultInputValue={selectedBOBSecondaryOwner}
                          selected={selectedSecondaryOptions}
                          isInvalid={isInvalidSecondaryOwner}
                          maxHeight="150px"
                          searchText="Searching..."
                          // eslint-disable-next-line no-return-assign
                          ref={(secondaryTypeahead) =>
                            (this.secondaryTypeahead = secondaryTypeahead)
                          }
                          inputProps={{
                            className: 'typeahead-css',
                            autoComplete: 'name',
                            id: 'bobSecondaryOwner'
                          }}
                        />
                      </TooltipHost>
                      {isInvalidSecondaryOwner && <span style={{ color: '#d13438', fontSize: '12px' }}>Required, please select a valid BOB Secondary owner</span>}
                    </div>

                    <div>
                        <Label htmlFor="weight">WEIGHT</Label>
                        <TextField
                          name="weight"
                          defaultValue=""
                          value={selectedWeight}
                          ariaLabel={selectedWeight}
                          disabled
                          id="weight"
                        />
                    </div>
                    <div className="effectivePeriodGridCell">
                      <ComboBox
                        text={
                          this.isNullOrUndefined(selectedEffectivePeriod)
                            ? null
                            : selectedEffectivePeriod?.text
                        }
                        label="EFFECTIVE PERIOD"
                        placeholder="Select Effective Period"
                        options={
                          effectivePeriod
                            ? effectivePeriod.map((data) => ({
                              key: data.cadenceId,
                              text: data.cadenceName,
                            }))
                            : []
                        }
                        title="EffectivePeriods"
                        aria-label="EffectivePeriods"
                        onChange={this.onChangeEffectivePeriod}
                        id="EffectivePeriods"
                        styles={comboBoxStyles}
                        comboBoxOptionStyles={{ maxHeight: '280px' }}
                        useComboBoxAsMenuWidth
                        allowFreeInput
                        autoComplete="on"
                        calloutProps={{ doNotLayer: true }}
                        iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                        disabled={isEditDisabled}
                      />
                    </div>
                    <div className="ebcm">
                      <Label htmlFor="Ebcm Critical" />
                      <Checkbox
                        label="EBCM CRITICAL BOOK OF BUSINESS"
                        name="Ebcm Critical"
                        checked={selectedEbcmCritical}
                        onChange={this.onToggleEbcmCritical}
                        id="EbcmCritical"
                        title="EBCM CRITICAL BOOK OF BUSINESS"
                        ariaLabel="Ebcm Critical"
                      />
                    </div>
                    {supplierProcurementType === 2 && (
                      <div className="functionalUsers">
                        <Label htmlFor="functionalUsers">FUNCTIONAL USERS</Label>
                        <TooltipHost content={errorMsgForPurchaseOrders}>
                          <NormalPeoplePicker
                            onResolveSuggestions={this.onFilterChanged.bind(this)}
                            getTextFromItem={this.getTextFromItem.bind(this)}
                            defaultSelectedItems={currentFunctionalUsers}
                            pickerSuggestionsProps={suggestionProps}
                            className="ms-PeoplePicker"
                            onChange={this.onChangeItemList.bind(this)}
                            key="normal"
                            id="functionalUsers"
                            removeButtonAriaLabel="Remove"
                            resolveDelay={300}
                          />
                        </TooltipHost>
                      </div>
                    )}
                    <div className="" />
                    <div className="" />
                    <div className="manageonboardedBobButtons">
                      <PrimaryButton
                        text="Clear"
                        value="Clear"
                        type="Clear"
                        disabled={
                          (this.isNullOrUndefined(selectedBOBName) &&
                            this.isNullOrUndefined(selectedEffectivePeriod) &&
                            selectedCadence === undefined &&
                            this.isNullOrUndefined(selectedExecOrgDetails) &&
                            this.isNullOrUndefined(selectedWeight) &&
                            this.isNullOrUndefined(selectedBOBSecondaryOwner) &&
                            this.isNullOrUndefined(selectedBOBPrimaryOwner) &&
                            this.isNullOrUndefined(
                              selectedStandardCategoryName
                            ) &&
                            this.isNullOrUndefined(
                              selectedStandardCategoryGroup
                            ) &&
                            this.isNullOrUndefined(selectedExecOrg)) ||
                          (isValueEdited === false && uvpAnnualSpend !== null)
                        }
                        onClick={this.onClearData.bind(this)}
                        className={
                          (this.isNullOrUndefined(selectedBOBName) &&
                            this.isNullOrUndefined(selectedEffectivePeriod) &&
                            selectedCadence === undefined &&
                            this.isNullOrUndefined(selectedExecOrgDetails) &&
                            this.isNullOrUndefined(selectedWeight) &&
                            this.isNullOrUndefined(selectedBOBSecondaryOwner) &&
                            this.isNullOrUndefined(selectedBOBPrimaryOwner) &&
                            this.isNullOrUndefined(
                              selectedStandardCategoryName
                            ) &&
                            this.isNullOrUndefined(
                              selectedStandardCategoryGroup
                            ) &&
                            this.isNullOrUndefined(selectedExecOrg)) ||
                            (isValueEdited === false && uvpAnnualSpend !== null)
                            ? 'btn-clear disabled'
                            : 'btn-clear'
                        }
                      />{' '}

                      <PrimaryButton
                        type="Submit"
                        text="Save"
                        value="Save"
                        disabled={
                          areCommonFieldsUndefined ||
                          !isValueEdited ||
                          (isIndirectProcurement
                            ? areIndirectProcurementFieldsUndefined
                            : areDirectProcurementFieldsUndefined)
                        }
                        className="btn-save"
                        onClick={this.onAddBOB.bind(this)}
                      />
                    </div>
                    {saveBobLoading ? <Spinner ariaLive="assertive" ariaLabel="Saving Data..." className="spinner" /> : null}
                  </form>


                </div>
              </div>
              {isErrorSavingData
                ?
                <ErrorDiv
                  error={displayMessage}
                  hideRetry
                />
                : null}
            </Modal.Body>

          </FocusTrapZone>
        </Modal>

      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    isSuccessSavingData: state.message.isSuccessSavingData,
    isErrorSavingData: state.message.isErrorSavingData,
    displayMessage: state.message.displayMessage,
    stdCategoryGroups:
      state.stdcategorygroups === null || state.stdcategorygroups.length === 0
        ? []
        : state.stdcategorygroups,
    stdCategoryNames:
      state.stdcategorynames === null || state.stdcategorynames.length === 0
        ? []
        : state.stdcategorynames,
    execOrgs:
      state.execorgs === null || state.execorgs.length === 0
        ? []
        : state.execorgs,
    execOrgDetails:
      state.execorgdetails === null || state.execorgdetails.length === 0
        ? []
        : state.execorgdetails,
    execOrgSummary:
      state.execorgSummary === null || state.execorgSummary.length === 0
        ? []
        : state.execorgSummary,
    reportingCadenceList:
      state.reportingCadenceList === null ||
        state.reportingCadenceList.length === 0
        ? []
        : state.reportingCadenceList,
    bobPrimaryOwner:
      state.owners === null || state.owners.length === 0 ? [] : state.owners,
    bobSecondaryOwner:
      state.owners === null || state.owners.length === 0 ? [] : state.owners,
    effectivePeriod: state.myActions.futureCadence,
  };
}
const mapDispatchToProps = {
  loadStdCategoryGroup: bobactions.getStdCategoryGroup,
  loadStdCategoryName: bobactions.getStdCategoryName,
  loadExecOrg: bobactions.getExecOrg,
  loadExecOrgSummary: bobactions.getExecOrgSummary,
  loadExecOrgDetails: bobactions.getExecOrgDetails,
  addOnboardBOB: bobactions.addOnboardBOB,
  updateOnboardedBOB: bobactions.updateOnboardedBOB,
  loadReportingCadenceList: bobactions.getReportingCadence,
  loadBOBOwnerList: bobactions.getOwnerList,
  updateSupplierOnboardedBOB: suppliersActions.updateSupplierOnboardedBOB,
  politeAnnouncement: PoliteAnnouncement,
  loadFutureCadence: cadenceActions.loadFutureCadence,
};

export default withErrorHandling(
  connect(mapStateToProps, mapDispatchToProps)(ManageOnboardedBOB)
);
ManageOnboardedBOB.defaultProps = {
  politeAnnouncement: null,
  bobFunctionalUsers: [],
  loadFutureCadence: PropTypes.func,
  isSuccessSavingData: false,
  isErrorSavingData: false,
  displayMessage: ''
};
ManageOnboardedBOB.propTypes = {
  loadStdCategoryGroup: PropTypes.func.isRequired,
  loadStdCategoryName: PropTypes.func.isRequired,
  loadExecOrg: PropTypes.func.isRequired,
  loadExecOrgSummary: PropTypes.func.isRequired,
  loadExecOrgDetails: PropTypes.func.isRequired,
  loadReportingCadenceList: PropTypes.func.isRequired,
  loadBOBOwnerList: PropTypes.func.isRequired,
  addOnboardBOB: PropTypes.any.isRequired,
  onboardBOB: PropTypes.object.isRequired,
  updateOnboardedBOB: PropTypes.func.isRequired,
  reportingCadenceList: PropTypes.array.isRequired,
  stdCategoryGroups: PropTypes.array.isRequired,
  execOrgs: PropTypes.array.isRequired,
  execOrgSummary: PropTypes.array.isRequired,
  stdCategoryNames: PropTypes.array.isRequired,
  execOrgDetails: PropTypes.array.isRequired,
  scorecardSupplierId: PropTypes.number.isRequired,
  cadence: PropTypes.string.isRequired,
  uvpAnnualSpend: PropTypes.number.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  scorecardBOBId: PropTypes.number.isRequired,
  primaryOwner: PropTypes.object.isRequired,
  secondaryOwner: PropTypes.object.isRequired,
  onboardSupplier: PropTypes.object.isRequired,
  bobFunctionalUsers: PropTypes.object,
  updateSupplierOnboardedBOB: PropTypes.func.isRequired,
  BOBList: PropTypes.array.isRequired,
  supplierProcurementType: PropTypes.number.isRequired,
  politeAnnouncement: PropTypes.func,
  loadFutureCadence: PropTypes.func,
  effectivePeriod: PropTypes.object.isRequired,
  isSuccessSavingData: PropTypes.bool,
  isErrorSavingData: PropTypes.bool,
  displayMessage: PropTypes.string
};
