/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ComboBox } from '@fluentui/react/lib/ComboBox';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import './ScorecardFilterPanel.css';
import {
  ScoreCardFilterModel,
  SupplierModel,
  BoBModel,
  ScoreCardingPeriodModel
} from '../../models/model/ScorecardFilterPanelModel';
import * as myActionsReduxActions from '../../../redux/actions/myActionsActions';
import * as scorecardFilterPanelReduxActions from '../../../redux/actions/scorecardFilterPanelActions';
import withErrorHandling from '../../hoc/withErrorHandling';
import { PoliteAnnouncement } from '../../../redux/actions/announcementActions';
import SupplierType from '../../../common/consts/SupplierType';

export class ScorecardFilterPanel extends Component {
  state = {
    isSubmitDisable: true,
    selectedSupplier: null,
    selectedPeriod: null,
    selectedBOB: null,
    scorecardFilterModel: new ScoreCardFilterModel(
      new SupplierModel(),
      new BoBModel(),
      new ScoreCardingPeriodModel()
    )
  };

  comboBoxStyles = {
    container: {
        display: 'flex',
        label: { fontSize: '14px !important', margin: '0 10px' }
      },
    optionsContainerWrapper: { maxHeight: '350px' },
    root: {
      '::after': {
      borderRadius: '4px'
      }
    }
  }

  constructor(props) {
    super(props);
    this.onScoreCardFilterSubmit = this.onScoreCardFilterSubmit.bind(this);
    this.onChangeSupplier = this.onChangeSupplier.bind(this);
    this.onChangeBoB = this.onChangeBoB.bind(this);
    this.onChangePeriod = this.onChangePeriod.bind(this);
  }

  componentDidMount() {
    const {
      selectedBobEntryAction,
      selectedSupplierLeadEntryAction,
      loadScorecardingSuppliers
    } = this.props;
    loadScorecardingSuppliers();
    if (selectedBobEntryAction !== null) {
      this.setState({
        scorecardFilterModel: selectedBobEntryAction,
        selectedSupplier: {
          text: selectedBobEntryAction?.supplier?.name,
          key: selectedBobEntryAction?.supplier?.id
        },
        selectedBOB: {
          text: selectedBobEntryAction?.bob?.name,
          key: selectedBobEntryAction?.bob?.id
        },
        selectedPeriod: {
          text: selectedBobEntryAction?.cadence?.name,
          key: selectedBobEntryAction?.cadence?.id
        }
      });
    } else if (selectedSupplierLeadEntryAction !== null) {
      this.setState({
        scorecardFilterModel: selectedSupplierLeadEntryAction,
        selectedSupplier: {
          text: selectedSupplierLeadEntryAction?.supplier?.name,
          key: selectedSupplierLeadEntryAction?.supplier?.id
        },
        selectedBOB: {
          text: 'All',
          key: 0
        },
        selectedPeriod: {
          text: selectedSupplierLeadEntryAction?.cadence?.name,
          key: selectedSupplierLeadEntryAction?.cadence?.id
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const prevBoBEntry = prevProps.selectedBobEntryAction;
    const prevSupplierLeadEntry = prevProps.selectedSupplierLeadEntryAction;
    const { scorecardFilterModel } = this.state;
    const {
      selectedBobEntryAction,
      selectedSupplierLeadEntryAction,
      loadScorecardingBOBs,
      loadScorecardingPeriods,
      politeAnnouncement
    } = this.props;

    if (selectedBobEntryAction != null) {
      if (
        prevBoBEntry === null ||
        ((prevBoBEntry.supplier.id !== selectedBobEntryAction.supplier.id ||
          prevBoBEntry.bob.id !== selectedBobEntryAction.bob.id) &&
          (scorecardFilterModel.supplier.id !==
            selectedBobEntryAction.supplier.id ||
            scorecardFilterModel.bob.id !== selectedBobEntryAction.bob.id))
      ) {
        politeAnnouncement('Loading Bobs');
        loadScorecardingBOBs(selectedBobEntryAction.supplier.id);
        loadScorecardingPeriods(
          selectedBobEntryAction.supplier.id,
          selectedBobEntryAction.bob.id
        );
        this.setState({
          scorecardFilterModel: selectedBobEntryAction,
          selectedSupplier: {
            text: selectedBobEntryAction?.supplier?.name,
            key: selectedBobEntryAction?.supplier?.id
          },
          selectedBOB: {
            text: selectedBobEntryAction?.bob?.name,
            key: selectedBobEntryAction?.bob?.id
          },
          selectedPeriod: {
            text: selectedBobEntryAction?.cadence?.name,
            key: selectedBobEntryAction?.cadence?.id
          },
          isSubmitDisable: true
        });
      }
    }
    if (selectedSupplierLeadEntryAction !== null) {
      if (
        prevSupplierLeadEntry === null ||
        (prevSupplierLeadEntry.supplier.id !==
          selectedSupplierLeadEntryAction.supplier.id &&
          scorecardFilterModel.supplier.id !==
            selectedSupplierLeadEntryAction.supplier.id)
      ) {
        politeAnnouncement('Loading Bobs');
        loadScorecardingBOBs(selectedSupplierLeadEntryAction.supplier.id);
        loadScorecardingPeriods(selectedSupplierLeadEntryAction.supplier.id, 0);
        this.setState({
          scorecardFilterModel: selectedSupplierLeadEntryAction,
          selectedSupplier: {
            text: selectedSupplierLeadEntryAction?.supplier?.name,
            key: selectedSupplierLeadEntryAction?.supplier?.id
          },

          selectedBOB: {
            text: selectedSupplierLeadEntryAction?.bob?.name,
            key: selectedSupplierLeadEntryAction?.bob?.id
          },
          selectedPeriod: {
            text: selectedSupplierLeadEntryAction?.cadence?.name,
            key: selectedSupplierLeadEntryAction?.cadence?.id
          },
          isSubmitDisable: true
        });
      }
    }
  }

  onChangeSupplier(event, selectedOption) {
    if (selectedOption !== undefined || selectedOption !== null) {
      const { loadScorecardingBOBs, politeAnnouncement } = this.props;
      politeAnnouncement('Loading Bobs');
      loadScorecardingBOBs(selectedOption.key);
      this.setState({
        scorecardFilterModel: new ScoreCardFilterModel(
          new SupplierModel(selectedOption.key, selectedOption.text,
            selectedOption.leadId, selectedOption.lead),
          new BoBModel(null, null, null, null),
          new ScoreCardingPeriodModel(null, null)
        ),
        selectedSupplier: selectedOption,
        selectedBOB: {
          text: '',
          key: ''
        },
        selectedPeriod: {
          text: '',
          key: 0
        },
        isSubmitDisable: false
      });
    }
  }

  onChangeBoB(event, selectedOption) {
    if (selectedOption !== undefined || selectedOption !== null) {
      const { scorecardFilterModel } = this.state;
      const { loadScorecardingPeriods, politeAnnouncement } = this.props;
      politeAnnouncement('Loading Cadence Periods');
      loadScorecardingPeriods(
        scorecardFilterModel.supplier.id,
        selectedOption.key
      );
      this.setState(prevState => ({
        scorecardFilterModel: new ScoreCardFilterModel(
          new SupplierModel(
            prevState.scorecardFilterModel.supplier.id,
            prevState.scorecardFilterModel.supplier.name,
            prevState.scorecardFilterModel.supplier.supplierLeads,
            prevState.scorecardFilterModel.supplier.supplierLeadNames
          ),
          new BoBModel(selectedOption.key, selectedOption.text,
            selectedOption.bobOwner, selectedOption.bobOwnerName),
          new ScoreCardingPeriodModel(null, null)
        ),
        selectedBOB: selectedOption,
        selectedPeriod: {
          text: '',
          key: 0
        },
        isSubmitDisable: false
      }));
    }
  }

  onChangePeriod(event, selectedOption) {
    if (selectedOption !== undefined || selectedOption !== null) {
      const { scorecardFilterModel, selectedBOB } = this.state;
      if (scorecardFilterModel.bob !== undefined) {
        this.setState(prevState => ({
          scorecardFilterModel: new ScoreCardFilterModel(
            new SupplierModel(
              prevState?.scorecardFilterModel?.supplier?.id,
              prevState?.scorecardFilterModel?.supplier?.name,
              prevState?.scorecardFilterModel?.supplier?.supplierLeads,
              prevState?.scorecardFilterModel?.supplier?.supplierLeadNames
            ),
            new BoBModel(
              prevState?.scorecardFilterModel?.bob?.id,
              prevState?.scorecardFilterModel?.bob?.name,
              prevState?.scorecardFilterModel?.bob?.bobOwnerIds,
              prevState?.scorecardFilterModel?.bob?.bobOwners
            ),
            new ScoreCardingPeriodModel(
              selectedOption.key,
              selectedOption.text
            )
          ),
          selectedPeriod: selectedOption,
          isSubmitDisable: true
        }));
      } else {
        this.setState(prevState => ({
          scorecardFilterModel: new ScoreCardFilterModel(
            new SupplierModel(
              prevState?.scorecardFilterModel?.supplier?.id,
              prevState?.scorecardFilterModel?.supplier?.name,
              prevState?.scorecardFilterModel?.supplier?.supplierLeads,
              prevState?.scorecardFilterModel?.supplier?.supplierLeadNames
            ),
            new BoBModel(0, selectedBOB.label,
              prevState?.scorecardFilterModel?.bob?.bobOwnerIds,
              prevState?.scorecardFilterModel?.bob?.bobOwners),
            new ScoreCardingPeriodModel(
              selectedOption?.key,
              selectedOption?.text
            )
          ),
          selectedPeriod: selectedOption,
          isSubmitDisable: true
        }));
      }
    }
  }

  onScoreCardFilterSubmit = event => {
    event.preventDefault();
    const {
      selectedSupplier,
      selectedBOB,
      selectedPeriod,
      scorecardFilterModel
    } = this.state;
    const {
      setSelectedSupplierLeadEntryAction,
      setSelectedBobEntryAction
    } = this.props;
    if (
      (selectedSupplier?.text && selectedBOB?.text && selectedPeriod?.text) !==
      null
    ) {
      if (selectedBOB.text !== 'All') {
        setSelectedBobEntryAction(scorecardFilterModel);
      } else setSelectedSupplierLeadEntryAction(scorecardFilterModel);
    }
  };

  isNullOrUndefined = variable => {
    if (variable === null || variable === '' || variable === undefined) {
      return true;
    }
    return false;
  };

  render() {
    const {
      selectedSupplier,
      selectedBOB,
      selectedPeriod,
      isSubmitDisable
    } = this.state;
    const { scorecardBOBs, scorecardSuppliers, scorecardPeriods } = this.props;
    const suppliers = scorecardSuppliers
    ? scorecardSuppliers.filter(x => x.supplierType !== SupplierType.Monitor)
    : [];

    return (
      <div className="col-md-12 col-lg-12 col-sm-12">
        <div className="row hdspec scorecardFilter-selections">
          <div className="scorecardFilter-selector supplierComboBox">
              <ComboBox
                label="Supplier"
                selectedKey={selectedSupplier?.key}
                onChange={this.onChangeSupplier}
                placeholder="Select"
                useAriaLabelAsText
                allowFreeInput
                useComboBoxAsMenuWidth
                autoComplete="on"
                styles={this.comboBoxStyles}
                iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                options={
                  !this.isNullOrUndefined(suppliers)
                    ? suppliers.map(supplier => ({
                        key: supplier.supplierId,
                        label: supplier.supplierName,
                        text: supplier.supplierName,
                        leadId: supplier.supplierLeadIds,
                        lead: supplier.supplierLeads
                      }))
                    : null
                }
                comboBoxOptionStyles={{ maxHeight: '100px' }}
                calloutProps={{ doNotLayer: true }}
                disabled={this.isNullOrUndefined(scorecardSuppliers)}
                id="supplierFilter"
                // title="Supplier edit combo box select has autocomplete"
                ariaDescribedBy="Supplier edit combo box select has autocomplete"
              />
          </div>
          <div className="scorecardFilter-selector BOBComboBox">
              <ComboBox
                label="Book of Business"
                selectedKey={selectedBOB?.key}
                onChange={this.onChangeBoB}
                iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                options={
                  !this.isNullOrUndefined(scorecardBOBs.bobs)
                    ? scorecardBOBs.bobs
                        .map(info => info)
                        .map(bob => ({
                          key: bob.bobId,
                          text: bob.bobName,
                          bobOwner: bob.bobOwner,
                          bobOwnerName: bob.bobOwnerNames
                        }))
                    : null
                }
                allowFreeInput
                autoComplete="on"
                styles={this.comboBoxStyles}
                placeholder="Select"
                disabled={this.isNullOrUndefined(scorecardBOBs?.bobs)
                          || scorecardBOBs?.bobs.length === 0}
                id="bobFilter"
                useComboBoxAsMenuWidth
                ariaLabel="Bob edit combo box select has autocomplete"
              />
          </div>
          <div className="scorecardFilter-selector periodComboBox">
              <ComboBox
                label="Period"
                selectedKey={selectedPeriod?.key}
                onChange={this.onChangePeriod}
                iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                options={
                  !this.isNullOrUndefined(scorecardPeriods.cadences)
                    ? scorecardPeriods.cadences
                        .map(info => info)
                        .map(per => ({
                          key: per.cadenceId,
                          text: per.cadenceName
                        }))
                    : null
                }
                useComboBoxAsMenuWidth
                allowFreeInput
                autoComplete="on"
                styles={this.comboBoxStyles}
                placeholder="Select"
                disabled={
                  scorecardPeriods.cadences === 'undefined' ||
                  scorecardPeriods.cadences === null ||
                  scorecardPeriods.cadences.length === 0
                }
                id="cadenceFilter"
                ariaLabel="Cadence edit combo box select has autocomplete"
              />
          </div>
          <div className="textsize14 submitContainer" style={{ marginBottom: '8px' }}>
            {isSubmitDisable && selectedBOB ? (
              <PrimaryButton
                allowDisabledFocus={false}
                aria-label="Search scorecard"
                onClick={this.onScoreCardFilterSubmit}
                className="submit "
                type="Submit"
                text="Search"
              />
            ) : (
              <PrimaryButton
                allowDisabledFocus={false}
                aria-label="Search scorecard"
                onClick={this.onScoreCardFilterSubmit}
                className="submit disabledSubmitButton"
                type="Submit"
                text="Search"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    scorecardSuppliers:
      state.scorecardsuppliers === null || state.scorecardsuppliers.length === 0
        ? []
        : state.scorecardsuppliers,
    scorecardBOBs:
      state.scorecardbobs === null || state.scorecardbobs.length === 0
        ? []
        : state.scorecardbobs,
    scorecardPeriods: state.scorecardperiods
      ? state.scorecardperiods
      : { cadences: [] },
    selectedSupplierLeadEntryAction: state.myActions.selectedSupplierLeadEntry,
    selectedBobEntryAction: state.myActions.selectedBobEntry
  };
}

const mapDispatchToProps = {
  setSelectedSupplierLeadEntryAction: myActionsReduxActions.setSelectedSupplierLeadEntryAction,
  setSelectedBobEntryAction: myActionsReduxActions.setSelectedBobEntryAction,
  loadScorecardingSuppliers: scorecardFilterPanelReduxActions.getScorecardSuppliers,
  loadScorecardingBOBs: scorecardFilterPanelReduxActions.getScorecardBOBs,
  loadScorecardingPeriods: scorecardFilterPanelReduxActions.getScorecardPeriods,
  politeAnnouncement: PoliteAnnouncement
};

export default withErrorHandling(
  connect(mapStateToProps, mapDispatchToProps)(ScorecardFilterPanel)
);

ScorecardFilterPanel.defaultProps = {
  selectedBobEntryAction: null,
  selectedSupplierLeadEntryAction: null,
  politeAnnouncement: null
};

ScorecardFilterPanel.propTypes = {
  loadScorecardingSuppliers: PropTypes.func.isRequired,
  loadScorecardingBOBs: PropTypes.func.isRequired,
  loadScorecardingPeriods: PropTypes.func.isRequired,
  scorecardSuppliers: PropTypes.array.isRequired,
  scorecardBOBs: PropTypes.object.isRequired,
  scorecardPeriods: PropTypes.object.isRequired,
  selectedBobEntryAction: PropTypes.object,
  selectedSupplierLeadEntryAction: PropTypes.object,
  setSelectedSupplierLeadEntryAction: PropTypes.func.isRequired,
  setSelectedBobEntryAction: PropTypes.func.isRequired,
  politeAnnouncement: PropTypes.func
};
