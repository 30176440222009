import React, { Component } from 'react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import PropTypes from 'prop-types';
import './DeleteDialog.css';

class DeleteDialog extends Component {
render() {
        const {
          hideDialog, onCancel, clickInProgress = false,
          dialogButtonHandler, itemName = '', reactivatePopUp
        } = this.props;
        return (
          <Dialog
            hidden={hideDialog}
            onDismiss={onCancel}
            dialogContentProps={{
              type: DialogType.normal
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 450 } },
              dragOptions: false
            }}
          >
          {
            reactivatePopUp ? <Icon iconName="WorkFlow" className="dialogIcon" /> : <Icon iconName="Delete" className="dialogIcon" />
          }
            <div>
              <h6 className="dialogtitle"> Are you sure? </h6>
            </div>
            <div>
              {
                reactivatePopUp ?
                  <span className="dialogdescription">
                  {' '}
                  Do you really want to activate these record <strong>{ itemName ? `- ${itemName}` : ''}</strong> ?.
                  </span> :
                  <span className="dialogdescription">
                  {' '}
                  Do you really want to delete these record <strong>{ itemName ? `- ${itemName}` : ''}</strong> ?.
                  </span>
              }
            </div>

            <div className="dialogfooter">
              <div className="row">
                <div className="col-md-6">
                <DefaultButton className="canceldialog" onClick={onCancel} text="Cancel" />
                </div>
                {reactivatePopUp ?
                  <div className="col-md-6">
                  <PrimaryButton className="deletedialog" disabled={clickInProgress} onClick={dialogButtonHandler} text="Reactivate" />
                  </div> :
                  <div className="col-md-6">
                  <PrimaryButton className="deletedialog" disabled={clickInProgress} onClick={dialogButtonHandler} text="Delete" />
                  </div>
                }
              </div>
            </div>
          </Dialog>
          );
    }
}

export default DeleteDialog;

DeleteDialog.defaultProps = {
  itemName: undefined
};

DeleteDialog.propTypes = {
    hideDialog: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    dialogButtonHandler: PropTypes.func.isRequired,
    itemName: PropTypes.string,
    reactivatePopUp: PropTypes.bool.isRequired,
    clickInProgress: PropTypes.bool.isRequired
};
