/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import {
  Spinner, SpinnerSize, ActionButton
} from 'office-ui-fabric-react';
import { Nav } from 'react-bootstrap';
import MetricsRatingControl from '../../../common/MetricsRatingControl/metricsRatingControl';
import './metricScorecardDetailsPanel.css';
import * as scorecardReduxActions from '../../../../redux/actions/scorecardActions';
import CorrectiveActionPlan from '../../../common/correctiveActionPlan/CorrectiveActionPlan';
import MetricsScore from '../../../common/MetricsScore/metricsScore';
import LineGraph from '../../../common/LineGraphControl/LineGraphControl';
import CategoryCommentsSection from '../categoryComment/categoryComment';
import ErrorDiv from '../../../hoc/errordiv';
import SupplierScorecardHeatmapModal from '../../supplierScorecardHeatmap/SupplierScorecardHeatmap';
import { CAPValidationMessage } from '../../../../common/consts/validationMessages';
import { Logger } from '../../../../infra/logging/telemetryLogger';
import { PoliteAnnouncement } from '../../../../redux/actions/announcementActions';
import SupplierType from '../../../../common/consts/SupplierType';

export class MetricsScorecardDetailsPanel extends Component {
  state = {
    bobMetricHistoryToggleStates: [],
    supplierMetricHistoryToggleStates: [],
    supplierScorecardAvgBOBScoreHistoryToggleStates: [],
    AreNoErrorsInSave: false,
    FailedToSave: false,
    hasErrorInDescription: false,
    hasErrorInDueDate: false,
    hasErrorInCloseDate: false,
    savecategoryDetailsLoading: false,
    metricCategoryDetailsbackup: []
  };

  constructor(props) {
    super(props);
    this.onChangeNotApplicable = this.onChangeNotApplicable.bind(this);
    this.OnChangeSelectAllNA = this.OnChangeSelectAllNA.bind(this);
    this.saveScoreCardDetails = this.saveScoreCardDetails.bind(this);
    this.saveBobScorecardDetails = this.saveBobScorecardDetails.bind(this);
    this.logger = Logger.getInstance();
  }

  componentDidMount() {
    const {
      loadBobScorecardCategoryDetails,
      loadSupplierScorecardCategoryDetails,
      bobScorecardDetails,
      supplierScorecardDetails,
      selectedScorecardCategory,
      bobScorecardCategoryDetails,
      supplierScorecardCategoryDetails
    } = this.props;
    if (
      bobScorecardDetails &&
      (!bobScorecardCategoryDetails ||
        bobScorecardCategoryDetails.category.id !== selectedScorecardCategory)
    ) {
      loadBobScorecardCategoryDetails(selectedScorecardCategory);
    }
    if (
      supplierScorecardDetails &&
      (!supplierScorecardCategoryDetails ||
        supplierScorecardCategoryDetails.category.id !==
        selectedScorecardCategory)
    ) {
      loadSupplierScorecardCategoryDetails(selectedScorecardCategory);
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      loadBobScorecardCategoryDetails,
      loadSupplierScorecardCategoryDetails,
      bobScorecardDetails,
      supplierScorecardDetails,
      selectedScorecardCategory,
    } = this.props;
    if (bobScorecardDetails) {
      if (
        prevProps.selectedScorecardCategory !== selectedScorecardCategory ||
        prevProps.bobScorecardDetails.id !== bobScorecardDetails.id
      ) {
        loadBobScorecardCategoryDetails(selectedScorecardCategory);
      }
    }
    if (supplierScorecardDetails) {
      if (
        prevProps.selectedScorecardCategory !== selectedScorecardCategory ||
        prevProps.supplierScorecardDetails.id !== supplierScorecardDetails.id
      ) {
        loadSupplierScorecardCategoryDetails(selectedScorecardCategory);
      }
    }
  }

  async onChangeNotApplicable(itemId, subItemId, event) {
    const {
      updateNotApplicableDispatch,
    } = this.props;
    const isChecked = event.currentTarget.checked;
    const buttonId = event.currentTarget.id;
    await updateNotApplicableDispatch(itemId, subItemId, isChecked);
    document.getElementById(buttonId).focus();
  }

  async onBOBScoreHistoryToggle(ev, checked) {
    const {
      selectedScorecardCategory,
      supplierScorecardDetails,
      loadSupplierScorecardAvgBOBScoreHistory,
      supplierScorecardCategoryAvgBobScoreHistory,
      politeAnnouncement,
    } = this.props;

    const { supplierScorecardAvgBOBScoreHistoryToggleStates } = this.state;

    let BOBAvgScoreHistoryToggleExists = false;
    let BOBAvgScoreHistoryToggleState = [];

    if (supplierScorecardDetails) {
      let historyLoadedSuccessfully = true;
      BOBAvgScoreHistoryToggleState =
        supplierScorecardAvgBOBScoreHistoryToggleStates &&
        supplierScorecardAvgBOBScoreHistoryToggleStates.map((c) => {
          if (c.id === selectedScorecardCategory) {
            BOBAvgScoreHistoryToggleExists = true;
            return { ...c, checked, BOBAvgScoreHistoryToggleState };
          }
          return c;
        });
      if (!BOBAvgScoreHistoryToggleExists) {
        BOBAvgScoreHistoryToggleState.push({
          id: selectedScorecardCategory,
          checked,
          historyLoadedSuccessfully,
        });
        BOBAvgScoreHistoryToggleExists = true;
      }
      this.setState({
        supplierScorecardAvgBOBScoreHistoryToggleStates: BOBAvgScoreHistoryToggleState,
      });

      if (checked) {
        const history = supplierScorecardCategoryAvgBobScoreHistory.find(
          (m) => m.id === selectedScorecardCategory
        );

        if (!history) {
          try {
            politeAnnouncement('Loading data');
            await loadSupplierScorecardAvgBOBScoreHistory(
              selectedScorecardCategory
            );
            historyLoadedSuccessfully = true;
            document.getElementById('BOBScoreHistoryID').focus();
          } catch (err) {
            this.logger.logException(err);
            historyLoadedSuccessfully = false;
          }

          BOBAvgScoreHistoryToggleState =
            BOBAvgScoreHistoryToggleState &&
            BOBAvgScoreHistoryToggleState.map((c) => {
              if (c.id === selectedScorecardCategory) {
                BOBAvgScoreHistoryToggleExists = true;
                return { ...c, checked, historyLoadedSuccessfully };
              }
              return c;
            });

          if (!BOBAvgScoreHistoryToggleExists) {
            BOBAvgScoreHistoryToggleState.push({
              id: selectedScorecardCategory,
              checked,
              historyLoadedSuccessfully,
            });
          }

          this.setState({
            supplierScorecardAvgBOBScoreHistoryToggleStates: BOBAvgScoreHistoryToggleState,
          });
        }
      }
    }
  }

  async onHistoryToggle(categoryId, metricId, ev, checked) {
    const {
      bobScorecardDetails,
      supplierScorecardDetails,
      bobScorecardMetricHistory,
      supplierScorecardMetricHistory,
      loadBobScorecardMetricHistory,
      loadSupplierScorecardMetricHistory,
      politeAnnouncement,
    } = this.props;

    const {
      bobMetricHistoryToggleStates,
      supplierMetricHistoryToggleStates,
    } = this.state;

    let metricHistoryToggleExists = false;
    let metricHistoryToggleState = [];
    if (bobScorecardDetails) {
      let historyLoadedSuccessfully = true;

      metricHistoryToggleState =
        bobMetricHistoryToggleStates &&
        bobMetricHistoryToggleStates.map((m) => {
          if (m.id === metricId) {
            metricHistoryToggleExists = true;
            return { ...m, checked, historyLoadedSuccessfully };
          }
          return m;
        });

      if (!metricHistoryToggleExists) {
        metricHistoryToggleState.push({
          id: metricId,
          checked,
          historyLoadedSuccessfully,
        });
        metricHistoryToggleExists = true;
      }

      this.setState({ bobMetricHistoryToggleStates: metricHistoryToggleState });
      if (checked) {
        const history = bobScorecardMetricHistory.find(
          (m) => m.metricId === metricId
        );

        if (!history) {
          try {
            politeAnnouncement('Loading data');
            await loadBobScorecardMetricHistory(categoryId, metricId);
            historyLoadedSuccessfully = true;
            document.getElementById(metricId).focus();
          } catch (err) {
            historyLoadedSuccessfully = false;
          }

          metricHistoryToggleState =
            metricHistoryToggleState &&
            metricHistoryToggleState.map((m) => {
              if (m.id === metricId) {
                metricHistoryToggleExists = true;
                return { ...m, checked, historyLoadedSuccessfully };
              }
              return m;
            });

          if (!metricHistoryToggleExists) {
            metricHistoryToggleState.push({
              id: metricId,
              checked,
              historyLoadedSuccessfully,
            });
          }
          this.setState({
            bobMetricHistoryToggleStates: metricHistoryToggleState,
          });
        }
      }
    }

    if (supplierScorecardDetails) {
      let historyLoadedSuccessfully = true;
      metricHistoryToggleState =
        supplierMetricHistoryToggleStates &&
        supplierMetricHistoryToggleStates.map((m) => {
          if (m.id === metricId) {
            metricHistoryToggleExists = true;
            return { ...m, checked, historyLoadedSuccessfully };
          }
          return m;
        });

      if (!metricHistoryToggleExists) {
        metricHistoryToggleState.push({
          id: metricId,
          checked,
          historyLoadedSuccessfully,
        });
        metricHistoryToggleExists = true;
      }
      this.setState({
        supplierMetricHistoryToggleStates: metricHistoryToggleState,
      });

      if (checked) {
        const history = supplierScorecardMetricHistory.find(
          (m) => m.metricId === metricId
        );
        if (!history) {
          try {
            politeAnnouncement('Loading data');
            await loadSupplierScorecardMetricHistory(categoryId, metricId);
            document.getElementById(metricId).focus();
            historyLoadedSuccessfully = true;
          } catch (err) {
            historyLoadedSuccessfully = false;
          }

          metricHistoryToggleState =
            metricHistoryToggleState &&
            metricHistoryToggleState.map((m) => {
              if (m.id === metricId) {
                metricHistoryToggleExists = true;
                return { ...m, checked, historyLoadedSuccessfully };
              }
              return m;
            });

          if (!metricHistoryToggleExists) {
            metricHistoryToggleState.push({
              id: metricId,
              checked,
              historyLoadedSuccessfully,
            });
          }

          this.setState({
            supplierMetricHistoryToggleStates: metricHistoryToggleState,
          });
        }
      }
    }
  }

  setCategory(category) {
    const { updateSelectedCategory } = this.props;
    updateSelectedCategory(category.id);
  }

  // eslint-disable-next-line class-methods-use-this
  getHistoryPlotPoints(metricHistory) {
    const plotPoints =
      metricHistory && metricHistory.length
        ? metricHistory.map(history => ({
          x: history.cadence.name,
          y: history.score !== 'N/A' ? Math.trunc(history.score * 10) / 10 : 'N/A'
        }))
        : [];

    return plotPoints;
  }

  getCategories() {
    const { bobScorecardDetails, supplierScorecardDetails } = this.props;
    if (bobScorecardDetails) {
      return bobScorecardDetails.categories;
    }
    if (supplierScorecardDetails) {
      return supplierScorecardDetails.categories;
    }
    return null;
  }

  async OnChangeSelectAllNA(event) {
    const {
      updateCategoryNotApplicable,
      bobScorecardCategoryDetails,
      supplierScorecardCategoryDetails
    } = this.props;
    const isChecked = event.currentTarget.checked;
    const buttonId = event.currentTarget.id;
    const { metricCategoryDetailsbackup } = this.state;
    const metricCategoryDetails = (bobScorecardCategoryDetails || supplierScorecardCategoryDetails);
    const metricCategorybackup = metricCategoryDetailsbackup.length === 0 ? metricCategoryDetails : metricCategoryDetailsbackup;
    this.setState({
      metricCategoryDetailsbackup: metricCategorybackup
    });
    await updateCategoryNotApplicable(isChecked, metricCategorybackup);
    document.getElementById(buttonId).focus();
  }

  updateMetricRating(itemId, subItemId, selectedScorecardCategory, rating) {
    const { updateMetricRatingDispatch } = this.props;
    updateMetricRatingDispatch(
      itemId,
      subItemId,
      rating,
      selectedScorecardCategory
    );
  }

  validateCap() {
    const {
      bobScorecardCategoryDetails,
      supplierScorecardCategoryDetails,
    } = this.props;
    let descriptionError = false;
    let dueDateError = false;
    let closeDateError = false;
    if (
      bobScorecardCategoryDetails &&
      bobScorecardCategoryDetails.cap &&
      (bobScorecardCategoryDetails.cap.description ||
        bobScorecardCategoryDetails.cap.dueDate)
    ) {
      if (!bobScorecardCategoryDetails.cap.description) {
        descriptionError = true;
      }
      if (!bobScorecardCategoryDetails.cap.dueDate) {
        dueDateError = true;
      }
      if (
        bobScorecardCategoryDetails.cap.isCompleted &&
        bobScorecardCategoryDetails.cap.closeDate
      ) {
        closeDateError = true;
      }
    }

    if (
      supplierScorecardCategoryDetails &&
      supplierScorecardCategoryDetails.cap &&
      (supplierScorecardCategoryDetails.cap.description ||
        supplierScorecardCategoryDetails.cap.openDate ||
        supplierScorecardCategoryDetails.cap.dueDate)
    ) {
      if (!supplierScorecardCategoryDetails.cap.description) {
        descriptionError = true;
      }
      if (!supplierScorecardCategoryDetails.cap.dueDate) {
        dueDateError = true;
      }
      if (
        supplierScorecardCategoryDetails.cap.isCompleted &&
        supplierScorecardCategoryDetails.cap.closeDate
      ) {
        closeDateError = true;
      }
    }
    this.setState({
      hasErrorInDescription: descriptionError,
      hasErrorInDueDate: dueDateError,
      hasErrorInCloseDate: closeDateError,
    });
    return !(descriptionError || dueDateError || closeDateError);
  }

  openScoreDetails() {
    const { isTeachingBubbleVisible } = this.state;
    this.setState({
      isTeachingBubbleVisible: !isTeachingBubbleVisible,
    });
  }

  async saveScoreCardDetails() {
    this.setState({
      savecategoryDetailsLoading: true,
    });

    const {
      bobScorecardCategoryDetails,
      supplierScorecardCategoryDetails,
      bobScorecardDetails,
      supplierScorecardDetails,
    } = this.props;

    if (this.validateCap()) {
      if (bobScorecardDetails && !supplierScorecardDetails) {
        try {
          await this.saveBobScorecardDetails(
            bobScorecardCategoryDetails,
            bobScorecardDetails
          );
          this.setState({ AreNoErrorsInSave: true });
          this.setState({
            FailedToSave: false,
            savecategoryDetailsLoading: false,
          });
        } catch (err) {
          this.logger.logException(err);
          this.setState({ AreNoErrorsInSave: false });
          this.setState({ FailedToSave: true });
          this.setState({ hasErrorInDescription: false });
          this.setState({ hasErrorInDueDate: false });
          this.setState({
            hasErrorInCloseDate: false,
            savecategoryDetailsLoading: false,
          });
        }
      }

      if (supplierScorecardDetails && !bobScorecardDetails) {
        try {
          await this.saveSupplierScorecardDetails(
            supplierScorecardCategoryDetails,
            supplierScorecardDetails
          );
          this.setState({ AreNoErrorsInSave: true });
          this.setState({
            FailedToSave: false,
            savecategoryDetailsLoading: false,
          });
        } catch (err) {
          this.logger.logException(err);
          this.setState({ AreNoErrorsInSave: false });
          this.setState({ FailedToSave: true });
          this.setState({ hasErrorInDescription: false });
          this.setState({ hasErrorInDueDate: false });
          this.setState({
            hasErrorInCloseDate: false,
            savecategoryDetailsLoading: false,
          });
        }
      }
    } else {
      this.setState({
        savecategoryDetailsLoading: false,
      });
    }
  }

  async saveBobScorecardDetails(metricScorecardDetails, bobScorecardDetails) {
    const {
      updateBobScorecardMetricDetails,
      selectedScorecardCategory,
      politeAnnouncement,
    } = this.props;
    const bobScorecardId = bobScorecardDetails.id;
    const categoryScorecardId = selectedScorecardCategory;
    politeAnnouncement('Saving data');

    await updateBobScorecardMetricDetails(
      categoryScorecardId,
      bobScorecardId,
      categoryScorecardId,
      metricScorecardDetails
    );
  }

  async saveSupplierScorecardDetails(
    metricScorecardDetails,
    supplierScorecardDetails
  ) {
    const {
      updateSupplierScorecardMetricDetails,
      selectedScorecardCategory,
      politeAnnouncement,
    } = this.props;
    const supplierScorecardId = supplierScorecardDetails.id;
    const categoryScorecardId = selectedScorecardCategory;
    politeAnnouncement('Saving data');
    await updateSupplierScorecardMetricDetails(
      categoryScorecardId,
      supplierScorecardId,
      categoryScorecardId,
      metricScorecardDetails
    );
  }

  renderBOBScoreHistoryPanel() {
    const {
      selectedScorecardCategory,
      supplierScorecardDetails,
      supplierScorecardCategoryAvgBobScoreHistory,
    } = this.props;
    const { supplierScorecardAvgBOBScoreHistoryToggleStates } = this.state;
    let isHistoryChecked = false;
    let isHistoryLoaded = false;
    let isLoadedSuccessful = true;
    let history;

    if (supplierScorecardDetails) {
      const historyToggleState = supplierScorecardAvgBOBScoreHistoryToggleStates.find(
        (m) => m.id === selectedScorecardCategory
      );
      isHistoryChecked = historyToggleState && historyToggleState.checked;
      isLoadedSuccessful =
        historyToggleState && historyToggleState.historyLoadedSuccessfully;
        history = supplierScorecardCategoryAvgBobScoreHistory.find(
          (c) => c.categoryId === selectedScorecardCategory
        );
      isHistoryLoaded = history !== undefined;
    }

    if (isHistoryChecked) {
      if (!isLoadedSuccessful) {
        return (
          <ErrorDiv
            error="Error Loading History. Please toggle the history again to reload."
            hideRetry
          />
        );
      }
      if (isHistoryLoaded) {
        return (
          <LineGraph
            plotPoints={this.getHistoryPlotPoints(history.BOBAvgScoreHistory)}
            header="Book of Business Weighted Average Score"
            label="Score"
          />
        );
      }
      return (
        <Spinner
          className="mb-2"
          size={SpinnerSize.large}
          label="Loading History..."
        />
      );
    }
    return <></>;
  }

  renderHistoryPanel(metric) {
    const {
      bobScorecardDetails,
      supplierScorecardDetails,
      bobScorecardMetricHistory,
      supplierScorecardMetricHistory,
    } = this.props;

    const {
      bobMetricHistoryToggleStates,
      supplierMetricHistoryToggleStates,
    } = this.state;
    let isHistoryChecked = false;
    let isHistoryLoaded = false;
    let isLoadedSuccessful = true;
    let history;

    if (bobScorecardDetails) {
      const historyToggleState = bobMetricHistoryToggleStates.find(
        (m) => m.id === metric.id
      );
      isHistoryChecked = historyToggleState && historyToggleState.checked;
      isLoadedSuccessful =
        historyToggleState && historyToggleState.historyLoadedSuccessfully;
      history = bobScorecardMetricHistory.find((m) => m.metricId === metric.id);
      isHistoryLoaded = history !== undefined;
    }
    if (supplierScorecardDetails) {
      const historyToggleState = supplierMetricHistoryToggleStates.find(
        (m) => m.id === metric.id
      );

      isHistoryChecked = historyToggleState && historyToggleState.checked;
      isLoadedSuccessful =
        historyToggleState && historyToggleState.historyLoadedSuccessfully;
      history = supplierScorecardMetricHistory.find(
        (m) => m.metricId === metric.id
      );
      isHistoryLoaded = history !== undefined;
    }

    if (isHistoryChecked) {
      if (!isLoadedSuccessful) {
        return (
          <ErrorDiv
            error="Error Loading History. Please toggle the history again to reload."
            hideRetry
          />
        );
      }
      if (isHistoryLoaded) {
        return (
          <LineGraph
            plotPoints={this.getHistoryPlotPoints(history.metricHistory)}
            header={metric.name}
            label="Score"
          />
        );
      }
      return <Spinner size={SpinnerSize.large} label="Loading History..." />;
    }
    return <></>;
  }

  render() {
    const {
      bobScorecardDetails,
      supplierScorecardDetails,
      bobScorecardCategoryDetails,
      supplierScorecardCategoryDetails,
      categoryDetailsLoading,
      selectedScorecardCategory,
      isEditable,
      loggedInUser
    } = this.props;
    const {
      AreNoErrorsInSave,
      FailedToSave,
      hasErrorInDescription,
      hasErrorInDueDate,
      hasErrorInCloseDate,
      savecategoryDetailsLoading,
    } = this.state;
    const metricScorecardDetails =
      bobScorecardCategoryDetails || supplierScorecardCategoryDetails;
    if (categoryDetailsLoading) return <Spinner className="spinner" />;

    if (!metricScorecardDetails) {
      return (
        <ErrorDiv
          error="Error Loading Scorecard Details. Please try again."
          hideRetry
        />
      );
    }

    const categories = bobScorecardDetails
      ? bobScorecardDetails.categories
      : supplierScorecardDetails.categories;
    const supplierTypeId = bobScorecardDetails
    ? bobScorecardDetails?.supplier?.supplierTypeId
    : supplierScorecardDetails?.supplier?.supplierTypeId;
  const nextCategory = categories.find(
      (c) => c.id === selectedScorecardCategory + 1
    ) && supplierTypeId !== SupplierType.NonSRMLite
      ? categories.find((c) => c.id === selectedScorecardCategory + 1)
      : categories.find(
        (c) => c.id === selectedScorecardCategory + 3
      );
    const prevCategory = categories.find(
      (c) => c.id === selectedScorecardCategory - 1
    ) && supplierTypeId !== SupplierType.NonSRMLite
      ? categories.find((c) => c.id === selectedScorecardCategory - 1)
      : categories.find((c) => c.id === selectedScorecardCategory - 3);

    let supplierLeadScore = 0;
    if (metricScorecardDetails.metrics && metricScorecardDetails.metrics.length > 0) {
      supplierLeadScore = metricScorecardDetails.metrics
        .reduce((prev, curr) => prev + parseFloat(curr.point), 0);
    }
    const shouldShowHistory = bobScorecardDetails && bobScorecardDetails.supplier ? bobScorecardDetails.supplier.procurementTypeId !== 2 : true;
    const selectedScorecardCategoryName = supplierScorecardDetails && supplierScorecardDetails.categories.map((categoryid, i) =>
    (categoryid.id === selectedScorecardCategory ? supplierScorecardDetails.categories[i].category.name : null)).filter((name) => name !== null);
    const ValidateCategory = String(selectedScorecardCategoryName) === 'Risk';
    return (
      <div
        id={metricScorecardDetails.category.name}
        className="tab-container tab-pane"
      >
          {
        supplierScorecardDetails
        && supplierScorecardDetails.supplier
        && (supplierScorecardDetails.supplier.procurementTypeId === 2 || ValidateCategory === true || (supplierScorecardDetails.supplier.procurementTypeId === 1 && supplierScorecardDetails.supplier.supplierTypeId !== 2))
        && metricScorecardDetails.bobCategorySummary && (
          <>
            <div className="category-score mt-3">
              <div>
                  <span className="summaryHeader" id={`metricHeader-msd-${metricScorecardDetails.category.id}`}>

                  <span>
                    {metricScorecardDetails.metrics && metricScorecardDetails.metrics.length > 0
                      ? <span> {metricScorecardDetails.bobCategorySummary.weight}% of {metricScorecardDetails.bobCategorySummary.name} </span>
                      : metricScorecardDetails.bobCategorySummary.name
                    }
                  </span>
                  <span
                    className={`weighted-score score-${Math.floor(
                      metricScorecardDetails.bobCategorySummary.score
                    )}`}
                  >
                    {(
                      (metricScorecardDetails.bobCategorySummary.score * 10) /
                      10
                    ).toFixed(1) !== 'NaN'
                      ? (
                        Math.trunc(
                          metricScorecardDetails.bobCategorySummary.score * 10
                        ) / 10
                      ).toFixed(1)
                      : 'N/A'}
                  </span>
                  {metricScorecardDetails.metrics && metricScorecardDetails.metrics.length > 0 ?
                    <span>
                      <span className="supplierLeadText">
                        +
                      </span>
                      {100 - metricScorecardDetails.bobCategorySummary.weight}% of Supplier Lead Score
                  <span
                    className={`weighted-score score-${Math.floor(
                          supplierLeadScore
                        )}`}
                  >
                        {(
                          (supplierLeadScore * 10) /
                          10
                        ).toFixed(1) !== 'NaN'
                          ? (
                            Math.trunc(
                              supplierLeadScore * 10
                            ) / 10
                          ).toFixed(1)
                          : 'N/A'}
                  </span>
                    </span>
                    : null}
                  <span className="supplierLeadText">
                    =
                  </span>
                  Supplier {metricScorecardDetails.category.name} Score
                  <span
                    className={`weighted-score score-${Math.floor(
                      metricScorecardDetails.score
                    )}`}
                  >
                    {((metricScorecardDetails.score * 10) / 10).toFixed(1) !==
                      'NaN'
                      ? (
                        Math.trunc(metricScorecardDetails.score * 10) / 10
                      ).toFixed(1)
                      : 'N/A'}
                  </span>
                  {/* {shouldShowHistory ?
                    <Toggle
                      className="historyToggle bobScoreAvgToggle"
                      defaultChecked={false}
                      inlineLabel
                      id="BOBScoreHistoryID"
                      offAriaLabel="History Off"
                      onAriaLabel="History On"
                      onText="History"
                      offText="History"
                      tabIndex="0"
                      onChange={this.onBOBScoreHistoryToggle.bind(this)}
                    />
                    : null} */}
                  </span>
              </div>
              <div className="border-start mb-3" />
            </div>

            <div className="category-score mt-3">
              {this.renderBOBScoreHistoryPanel()}
              <div className="border-start mb-3" />
              <div className={metricScorecardDetails.metrics ? 'disabled' : ''}>
                {metricScorecardDetails.bobCategorySummary
                  .displayWeightInfo && (
                    <MetricsScore
                      metricDetails={metricScorecardDetails.bobCategorySummary}
                      disabled={
                        !isEditable ||
                        metricScorecardDetails.metrics ||
                        metricScorecardDetails.isReadonly
                      }
                    />
                  )}
              </div>
            </div>
          </>
        )}
{((bobScorecardDetails !== null
      && bobScorecardDetails.supplier.procurementTypeId === 2))
      ? (
       <Checkbox
         label="Section Not Applicable"
         className="notApplicableCheckbox"
         checked={metricScorecardDetails.metrics.every(item => !item.isApplicable)}
         onChange={this.OnChangeSelectAllNA.bind(this)}
         title="Section Not Applicable"
         ariaLabel="Section Not Applicable"
       />
       ) : (
          <></>
        )}
        <div className="metricscontainer">
          {metricScorecardDetails.metrics
            ? metricScorecardDetails.metrics.map((item, index) => (
              <>
                <div className="metrics">
                  <div className="parent-metrics">
                        <section className="metric-header" id={`metricHeader-mh-${item.id}`}>
                        <h5 className="metrics-title" id={`metricHeader-mt-${item.id}`}>

                        {(item.score === null ||
                           (item.isApplicable && parseInt(item.score, 10) === 0)) && (
                            <span className="important">!</span>
                          )}{' '}
                         {item.name && (
                      <span>
                        {' '}
                        {String(item.name)
                          .split('\\n')
                          .map((line, i) => (i === 0 ?
                            line
                           : <p>{line}</p>))
                        }
                      </span>
                    )}
                        </h5>
                      <section>
                        <div className="historyDiv">
                          <Checkbox
                            label="Not Applicable"
                            onChange={this.onChangeNotApplicable.bind(
                              this,
                              item.id,
                              null
                            )}
                            title="Not Applicable"
                            ariaLabel="Not Applicable"
                            checked={!item.isApplicable}
                            disabled={item.subMetrics != null || !isEditable}
                            className="notApplicableCheckbox"
                            id={`${index}-${item.id}`}
                          />
                          {shouldShowHistory ?
                            <Toggle
                              className="historyToggle"
                              defaultChecked={false}
                              inlineLabel
                              AriaLabel="History"
                              label="History"
                              id={item.id}
                              tabIndex="0"
                              onChange={this.onHistoryToggle.bind(
                                this,
                                metricScorecardDetails.id,
                                item.id
                              )}
                            /> : null}
                        </div>
                      </section>
                        </section>
                    {this.renderHistoryPanel(item)}

                    {item.description && (
                      <span className="metric-description">
                        {' '}
                        {String(item.description)
                          .split('\\n ')
                          .map(line => (
                            <p>{line}</p>
                          ))}{' '}
                      </span>
                    )}
                    {item.displayWeightInfo && (
                      <MetricsScore
                        metricDetails={item}
                        disabled={
                          item.subMetrics != null ||
                          !item.isApplicable ||
                          !isEditable ||
                          metricScorecardDetails.isReadonly
                        }
                      />
                    )}

                    <MetricsRatingControl
                      isSelectable
                      controlid={item.id}
                      selected={Math.floor(item.score)}
                      name={item.name}
                      disabled={
                        item.subMetrics != null ||
                        !item.isApplicable ||
                        !isEditable ||
                        metricScorecardDetails.isReadonly
                      }
                      onChange={this.updateMetricRating.bind(
                        this,
                        item.id,
                        null,
                        metricScorecardDetails.id
                      )}
                    />
                  </div>
                  {item.subMetrics && (
                    <div className="subMetricContainer">
                      {item.subMetrics.map((subitem, subindex) => (
                        <>
                          <div className="subMetricsItem">
                          <section className="metric-header">
                              <span
                                className="subMetrics-title"
                                id={`metricHeader-${subitem.id}`}
                              >
                             {(subitem.score === null ||
                                  (subitem.isApplicable &&
                                    parseInt(subitem.score, 10) === 0)) && (
                                    <span className="important">!</span>
                                  )}{' '}
                               {subitem.name && (
                                <span>
                                  {' '}
                                  {String(subitem.name)
                                    .split('\\n')
                                    .map((line, i) => (i === 0 ?
                                      line
                                    : <p>{line}</p>))
                                  }
                                </span>
                              )}
                              </span>
                              <div className="historyDiv">
                                <Checkbox
                                  label="Not Applicable"
                                  onChange={this.onChangeNotApplicable.bind(
                                    this,
                                    item.id,
                                    subitem.id
                                  )}
                                  title="Not Applicable"
                                  ariaLabel="Not Applicable"
                                  checked={!subitem.isApplicable}
                                  className="notApplicableCheckbox"
                                  disabled={!isEditable || metricScorecardDetails.isReadonly}
                                  id={`${subindex}-${subitem.id}`}
                                />
                                {shouldShowHistory ?
                                  <Toggle
                                    className="historyToggle"
                                    defaultChecked={false}
                                    inlineLabel
                                    label="History"
                                    id={subitem.id}
                                    tabIndex="0"
                                    onChange={this.onHistoryToggle.bind(
                                      this,
                                      metricScorecardDetails.id,
                                      subitem.id
                                    )}
                                  /> : null}
                              </div>
                          </section>
                            {this.renderHistoryPanel(subitem)}
                            {subitem.displayWeightInfo && (
                              <MetricsScore
                                metricDetails={subitem}
                                disabled={
                                  !subitem.isApplicable ||
                                  !isEditable ||
                                  metricScorecardDetails.isReadonly
                                }
                              />
                            )}

                            <MetricsRatingControl
                              isSelectable
                              controlid={item.id}
                              selected={Math.floor(subitem.score)}
                              name={subitem.name}
                              disabled={
                                !subitem.isApplicable ||
                                !isEditable ||
                                metricScorecardDetails.isReadonly
                              }
                              onChange={this.updateMetricRating.bind(
                                this,
                                item.id,
                                subitem.id,
                                metricScorecardDetails.id
                              )}
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </div>
              </>
            ))
            : []}
        </div>
        {metricScorecardDetails.metrics.length > 0 && (
          <div className="metrics-border" />
        )}
        {metricScorecardDetails.heatMapData && (
          <div className="heatmapContainer">
            <SupplierScorecardHeatmapModal />
          </div>
        )}
        <CategoryCommentsSection
          categoryComments={metricScorecardDetails.comments}
          isEditable={!metricScorecardDetails.isReadonly}
          categoryId={metricScorecardDetails.id}
          categoryName={metricScorecardDetails.category.name}
          isBobScorecardCategory={!!bobScorecardCategoryDetails}
          scorecardDetails={metricScorecardDetails}
          showHistoryDetails={shouldShowHistory}
          loggedInUser={loggedInUser}
        />
        {metricScorecardDetails.isCapApplicable && (
          <CorrectiveActionPlan
            capDetails={metricScorecardDetails.cap}
            bobScorecardDetails={bobScorecardDetails}
            supplierScorecardDetails={supplierScorecardDetails}
          />
        )}
        {hasErrorInDescription ? (
          <ActionButton
            iconProps={{ iconName: 'Error' }}
            allowDisabledFocus={false}
            text={CAPValidationMessage.descriptionRequired}
            ariaLabel={CAPValidationMessage.descriptionRequired}
            className="errorMessage"
            disabled
          />
        ) : (
            ''
          )}
        {hasErrorInDueDate ? (
          <ActionButton
            iconProps={{ iconName: 'Error' }}
            allowDisabledFocus={false}
            text={CAPValidationMessage.dueDateRequired}
            ariaLabel={CAPValidationMessage.dueDateRequired}
            className="errorMessage"
            disabled
          />
        ) : (
            ''
          )}
        {hasErrorInCloseDate ? (
          <ActionButton
            iconProps={{ iconName: 'Error' }}
            allowDisabledFocus={false}
            text={CAPValidationMessage.closeDateRequired}
            ariaLabel={CAPValidationMessage.closeDateRequired}
            className="errorMessage"
            disabled
          />
        ) : (
            ''
          )}

        <div className="savescorecarddiv">
          {prevCategory ? (
            <div
              alt="categoryname"
              role="presentation"
              className="prevTab2"
              onClick={this.setCategory.bind(this, prevCategory)}
              disabled={!isEditable}
            >
              <Nav.Link href="#" className="prevTab2">
                {`< ${prevCategory.category.name} `}
              </Nav.Link>
            </div>
          ) : (
              <div />
            )}

          <div>
            <PrimaryButton
              aria-label={`Save ${metricScorecardDetails.category.name} scorecard details`}
              onClick={this.saveScoreCardDetails}
              text={`Save ${metricScorecardDetails.category.name}`}
              disabled={
                !isEditable ||
                !metricScorecardDetails.isDirty ||
                metricScorecardDetails.isReadonly
              }
              className="save-metrics"
              tabIndex="0"
            />
          </div>

          {nextCategory ? (
            <div
              alt="categoryname"
              role="presentation"
              className="nexttab1"
              onClick={this.setCategory.bind(this, nextCategory)}
              disabled={!isEditable}
            >
              <Nav.Link href="#" className="nexttab1">
                {`${nextCategory.category.name} >`}
              </Nav.Link>
            </div>
          ) : (
              <div />
            )}
        </div>
        <div className="submit-response">
          {AreNoErrorsInSave && (
            <ActionButton
              iconProps={{ iconName: 'Completed' }}
              allowDisabledFocus={false}
              text="Scorecard was saved successfully"
              ariaLabel="Scorecard was saved successfully"
              className="successMsg"
              disabled
            />
          )}
          {FailedToSave && (
            <ActionButton
              iconProps={{ iconName: 'Error' }}
              allowDisabledFocus={false}
              className="ErrorMsg"
              text="Failed to save scorecard"
              ariaLabel="Failed to save scorecard"
              disabled
            />
          )}
        </div>
        {savecategoryDetailsLoading ? <Spinner className="spinner" /> : null}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  selectedScorecardCategory: state.myActionsScorecard.selectedScorecardCategory,
  bobScorecardDetails: state.myActionsScorecard.bobScorecardDetails,
  supplierScorecardDetails: state.myActionsScorecard.supplierScorecardDetails,
  bobScorecardCategoryDetails:
    state.myActionsScorecard.bobScorecardCategoryDetails,
  supplierScorecardCategoryDetails:
    state.myActionsScorecard.supplierScorecardCategoryDetails,
  bobScorecardMetricHistory:
    state.myActionsScorecard.bobScorecardMetricHistory === null ||
      state.myActionsScorecard.bobScorecardMetricHistory.length === 0
      ? []
      : state.myActionsScorecard.bobScorecardMetricHistory,
  supplierScorecardCategoryAvgBobScoreHistory:
    state.myActionsScorecard.supplierScorecardCategoryAvgBobScoreHistory ===
      null ||
      state.myActionsScorecard.supplierScorecardCategoryAvgBobScoreHistory
        .length === 0
      ? []
      : state.myActionsScorecard.supplierScorecardCategoryAvgBobScoreHistory,
  supplierScorecardMetricHistory:
    state.myActionsScorecard.supplierScorecardMetricHistory === null ||
      state.myActionsScorecard.supplierScorecardMetricHistory.length === 0
      ? []
      : state.myActionsScorecard.supplierScorecardMetricHistory,
  categoryDetailsLoading: state.myActionsScorecard.categoryDetailsLoading,
  loggedInUser: state.loggedInUser,
});

const mapDispatchToProps = {
  loadBobScorecardCategoryDetails:
    scorecardReduxActions.loadBobScorecardCategoryDetails,
  loadSupplierScorecardCategoryDetails:
    scorecardReduxActions.loadSupplierScorecardCategoryDetails,
  loadBobScorecardMetricHistory:
    scorecardReduxActions.loadBobScorecardMetricHistory,
  loadSupplierScorecardMetricHistory:
    scorecardReduxActions.loadSupplierScorecardMetricHistory,
  loadSupplierScorecardAvgBOBScoreHistory:
    scorecardReduxActions.loadSupplierScorecardAvgBOBScoreHistory,
  updateNotApplicableDispatch: scorecardReduxActions.updateNotApplicable,
  updateCategoryNotApplicable: scorecardReduxActions.updateCategoryNotApplicable,
  updateMetricRatingDispatch: scorecardReduxActions.updateMetricRating,
  updateBobScorecardMetricDetails:
    scorecardReduxActions.updateBobScorecardMetricDetails,
  updateSupplierScorecardMetricDetails:
    scorecardReduxActions.updateSupplierScorecardMetricDetails,
  updateSelectedCategory: scorecardReduxActions.updateSelectedCategory,
  politeAnnouncement: PoliteAnnouncement
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(MetricsScorecardDetailsPanel);

MetricsScorecardDetailsPanel.defaultProps = {
  bobScorecardDetails: null,
  supplierScorecardDetails: null,
  loadBobScorecardCategoryDetails: null,
  loadSupplierScorecardCategoryDetails: null,
  bobScorecardCategoryDetails: null,
  supplierScorecardCategoryDetails: null,
  bobScorecardMetricHistory: null,
  updateCategoryNotApplicable: null,
  supplierScorecardCategoryAvgBobScoreHistory: null,
  supplierScorecardMetricHistory: null,
  updateNotApplicableDispatch: null,
  updateMetricRatingDispatch: null,
  politeAnnouncement: null
};

MetricsScorecardDetailsPanel.propTypes = {
  loadBobScorecardCategoryDetails: PropTypes.func,
  loadSupplierScorecardCategoryDetails: PropTypes.func,
  bobScorecardDetails: PropTypes.object,
  supplierScorecardDetails: PropTypes.object,
  selectedScorecardCategory: PropTypes.number.isRequired,
  bobScorecardCategoryDetails: PropTypes.object,
  supplierScorecardCategoryDetails: PropTypes.object,
  bobScorecardMetricHistory: PropTypes.object,
  supplierScorecardCategoryAvgBobScoreHistory: PropTypes.object,
  supplierScorecardMetricHistory: PropTypes.object,
  loadBobScorecardMetricHistory: PropTypes.func.isRequired,
  loadSupplierScorecardAvgBOBScoreHistory: PropTypes.func.isRequired,
  updateCategoryNotApplicable: PropTypes.func,
  loadSupplierScorecardMetricHistory: PropTypes.func.isRequired,
  updateNotApplicableDispatch: PropTypes.func,
  updateMetricRatingDispatch: PropTypes.func,
  categoryDetailsLoading: PropTypes.bool.isRequired,
  updateBobScorecardMetricDetails: PropTypes.func.isRequired,
  updateSupplierScorecardMetricDetails: PropTypes.func.isRequired,
  updateSelectedCategory: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  politeAnnouncement: PropTypes.func,
  loggedInUser: PropTypes.object.isRequired,
};
